import {Button, Col, Form, Input, message, Row} from 'antd';
import {
    MinusCircleOutlined,
    PlusOutlined,
    RobotOutlined,
    SaveOutlined
} from "@ant-design/icons";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";
import {newObjectID} from "../../utils";
import {exampleByIntentTitle} from "../../services/gpt";
import IntentSuggestions from "../../components/intent-suggestions";
import MessageApiContext from "../../context/message-api";

export default function IntentCreate() {
    const [form] = Form.useForm();
    const [data, setData] = useContext(ChatBotDataContext);
    const navigate = useNavigate();

    const messageApi = useContext(MessageApiContext);
    const [loadingGPT, setLoadingGPT] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const title = Form.useWatch('title', form);
    const examples = Form.useWatch('examples', form);

    const loadGpt = useCallback(() => {
        if (loadingGPT)
            return;

        if (!title || title?.length < 4)
            return messageApi.error("Titulo deve conter no minimo 3 letras");

        setLoadingGPT(true);
        exampleByIntentTitle(title, 8).then(({data}) => {
            setSuggestions(data?.suggestions);
        }).catch(() => messageApi.error("Falha ao carregar sugestões, tente novamente")
        ).finally(() => setLoadingGPT(false))
    }, [setLoadingGPT, exampleByIntentTitle, setSuggestions, messageApi, title, loadingGPT]);

    const removeSuggestion = useCallback((i) => {
        setSuggestions(old => {
            old.splice(i, 1)
            return [...old];
        })
    }, [setSuggestions]);

    const addSuggestion = useCallback((i) => {
        const exs = examples ?? [];
        const text = suggestions[i];

        exs.push({text});
        form.setFieldValue('examples', exs);

        removeSuggestion(i)
    }, [suggestions, form, examples, removeSuggestion]);

    const removeAll = useCallback(() => {
        setSuggestions([]);
    }, [setSuggestions]);

    const addAll = useCallback(() => {
        const exs = examples ?? [];
        for (let text of suggestions) {
            exs.push({text});
        }
        form.setFieldValue('examples', exs);
        removeAll();
    }, [suggestions, form, removeAll, examples]);

    useEffect(() => {
        if (!title || title?.length < 4)
            return;

        let timeout = setTimeout(loadGpt, 1500)

        return () => clearTimeout(timeout);
    }, [title])


    const save = useCallback((values) => {
        setData(old => {
            const id = newObjectID();
            values.id = id;
            old.intents[id] = values;
            return {...old};
        });

        navigate('..');
    }, [navigate, setData])

    return (
        <Row>
            <Col span={11}>
                <Form
                    name="basic"
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    onFinish={save}
                >
                    <Form.Item
                        label="Título"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'O título é obrigatório',
                            },
                        ]}
                    >
                        <Input placeholder={'Digite um titulo'}
                               suffix={<Button loading={loadingGPT} onClick={loadGpt} type={'primary'}
                                               icon={<RobotOutlined/>}/>}/>
                    </Form.Item>

                    <Form.List name="examples"
                               rules={[
                                   {
                                       validator: async (_, messages) => {
                                           if (!messages || messages.length < 2) {
                                               return Promise.reject(new Error('É necessário 2 mensagens de exemplo no mínimo'));
                                           }
                                       },
                                   },
                               ]}
                    >
                        {(fields, {add, remove}, {errors}) => {
                            return (
                                <>
                                    {fields?.map((field, index) => (<Form.Item
                                        key={field.key}
                                        label={index === 0 ? 'Exemplos' : ''}
                                        required={true}
                                        tooltip={'Digite um exemplo de mensagem para essa intenção'}
                                    >
                                        <Row justify={'space-between'} align={'center'}>
                                            <Col span={22}>
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    name={[field.name, 'text']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Adicione uma mensagem ou delete esse campo",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input.TextArea autoSize
                                                           placeholder={'Digite um exemplo'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                {fields.length > 1 ? (<MinusCircleOutlined
                                                    style={{marginTop: '.5rem'}}
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />) : null}
                                            </Col>
                                        </Row>
                                    </Form.Item>))}
                                    <Form.Item>
                                        <Button
                                            icon={<PlusOutlined/>}
                                            type="dashed"
                                            onClick={() => add({text: ""})}>Adicionar exemplo</Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.ErrorList errors={errors}/>
                                    </Form.Item>
                                </>)
                        }}
                    </Form.List>
                    <Form.Item>
                        <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                            Criar
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={1}/>
            <Col span={12}>
                {(suggestions?.length > 0 || loadingGPT) && <IntentSuggestions
                    addAll={addAll} removeAll={removeAll} addSuggestion={addSuggestion}
                    removeSuggestion={removeSuggestion} suggestions={suggestions} loading={loadingGPT}/>}
            </Col>
        </Row>
    )
}
