import styled from "styled-components";
import {Button} from "antd";

export const Position = styled.div`
  display: flex;
  gap: .6rem;
  position: inherit;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: clip;
  background-color: ${props => props.colorBgContainer};
  border-color: ${props => props.borderColor};
  border-style: solid;
  box-shadow: ${props => props.boxShadow};
  border-radius: ${props => props.borderRadius}px;
`;

export const ButtonClose = styled(Button)`
  box-shadow: ${props => props.boxShadow};
  background: ${props => props.background};
  border-color: ${props => props.borderColor};
  border-style: solid;
`;

