import {useCallback, useContext} from "react";
import ChatBotDataContext from "../context/chatbot-data";
import ActionsContext from "../context/actions";
import {useNodesContext} from "../context/nodesProvider";
import {useEdgesContext} from "../context/edgesProvider";
import useNode from "./useNode";
import useEdge from "./useEdge";

const useData = () => {
    const [data, setData] = useContext(ChatBotDataContext);
    const [, setGroups] = useContext(ActionsContext)['groups']
    const {setNodes} = useNodesContext([]);
    const {setEdges} = useEdgesContext();
    const {dataNodeToNode} = useNode();
    const {dataEdgeToEdge} = useEdge();

    const convertData = useCallback((data, flow, isIframe) => {
        const nodes = Object.values(data?.nodes ?? {});
        const edges = [];
        let minimizedChildren = []
        const newGroups = Object.values(data?.nodeGroups ?? {})
        for (let edgesTargets of Object.values(data?.edges ?? [])) {
            for (let edge of Object.values(edgesTargets ?? [])) {
                edges.push(edge);
            }
        }

        const nodeList = [{
            id: "000000000000000000000000",
            position: {
                y: 0,
                x: 0
            },
            dragHandle: '.custom-drag-handle',
            type: 'initial',
        }];
        if (newGroups.length > 0) {
            for (let group of newGroups) {
                if (!group.minimized) {
                    nodeList.push({
                        id: group.id,
                        position: {
                            x: group.position && group.position.x != 0 ? group.position.x : 200,
                            y: group.position && group.position.y != 0 ? group.position.y : 200
                        },
                        style: {
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            height: group.size.height,
                            width: group.size.width,
                            borderRadius: '10px',
                            zIndex: '-1'
                        },
                        data: {
                            ...group,
                            label: `${group.title}`,
                            position: group.position,
                        },
                        type: 'group',
                    })
                } else {
                    minimizedChildren = [...minimizedChildren, ...group.nodes]
                    nodeList.push(
                        {
                            id: group.id,
                            position: {
                                y: group.position.y,
                                x: group.position.x
                            },
                            dragHandle: '.custom-drag-handle',
                            data: {
                                ...group,
                            },
                            type: 'minimized',
                        }
                    )
                }

            }
        }
        if (nodes.length > 0) {
            for (let node of nodes) {

                if (!minimizedChildren.includes(node.id)) {
                    const group = newGroups.find(group => {
                        return group.nodes.includes(node.id)
                    })
                    nodeList.push(dataNodeToNode(node, flow, group))
                }
            }
        }

        const edgeList = [];
        if (edges.length > 0) {
            for (let edge of edges) {

                if (minimizedChildren.includes(edge.target) && minimizedChildren.includes(edge.source)) {
                    continue;
                }
                if (minimizedChildren.includes(edge.target)) {
                    edge.originalTarget = edge.target; // Store the original target
                    edge.target = newGroups.find(group => group.nodes.includes(edge.target)).id;
                } else {
                    if (edge.originalTarget) {
                        edge.target = edge.originalTarget;
                        delete edge.originalTarget;
                    }
                }
                if (minimizedChildren.includes(edge.source)) {
                    edge.originalSource = edge.source; // Store the original source
                    edge.source = newGroups.find(group => group.nodes.includes(edge.source)).id;
                } else {
                    if (edge.originalSource) {
                        edge.source = edge.originalSource;
                        delete edge.originalSource;
                    }
                }

                edgeList.push(dataEdgeToEdge(edge, data.nodes[edge.source] ? data.nodes[edge.source].skipUserInput || edge.type.substring(0, 4) === 'jump' : false))
            }
        }

        setGroups(newGroups);
        setNodes(nodeList);
        setEdges(edgeList);
    }, [data])

    return {convertData};
}

export default useData;
