import {Card} from "antd";
import styled, {css, keyframes} from "styled-components";
import {slideInLeft, slideInRight} from "react-animations";


const slideRight = keyframes`${slideInRight}`;
const slideLeft = keyframes`${slideInLeft}`;
export const Container = styled.div`
  padding: .4rem .8rem;
  height: 100%;
  overflow-y: auto;
  ${props => props.left && css`
    animation: .3s ${slideLeft};
  `}
  
  ${props => props.right && css`
    animation: .3s ${slideRight};
  `}
`;

export const CustomCard = styled(Card)`
  border-color: ${props => props.selected && props.hoverColor || 'none'};
  
  &:hover {
    transition: 0.3s ease border-color;
    border-color: ${props => props.hoverColor};
    cursor: pointer;
  }

`
