import {Button, Form, Input, InputNumber} from 'antd';
import {Switch} from "antd/lib";
import {SaveOutlined} from "@ant-design/icons";
import {useCallback, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createCompany} from "../../services/companies";
import {InputSearch} from "../../components/input-search";
import {getChatbotPlans} from "../../services/chatbot-plan";
import MessageApiContext from "../../context/message-api";
import {getAssistantPlans} from "../../services/assistant-plan";

export default function CompanyCreate() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const messageApi = useContext(MessageApiContext);

    const save = useCallback((values) => {
        setSaving(true)
        createCompany(values).then(() => {
            setSaving(false)
            navigate('/companies');
            messageApi.success("Empresa salvo com sucesso!")
        }).catch(e => {
            console.error(e)
            setSaving(false);
            messageApi.error("Falha ao salvar informações do empresa")
        })
    }, [])

    return (
        <Form
            name="basic"
            style={{
                maxWidth: 600,
            }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            onFinish={save}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Digite um nome para a empresa',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="CNPJ"
                name="identity"
                rules={[
                    {
                        message: 'CNPJ não pode estar vazio',
                        len: 14
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Dia de pagamento"
                name="payDay"
                rules={[
                    {
                        required: true,
                        message: 'Dia de pagamento não pode estar vazio',
                    }
                ]}
            >
                <InputNumber min={1} max={28} step={1}/>
            </Form.Item>

            <Form.Item
                label="Plano de Chatbot"
                name="chatbotPlanID"
                rules={[
                    {
                        required: true,
                        message: 'Selecione um plano de chatbot',
                    },
                ]}
            >
                <InputSearch defaultValue={1} getCallback={getChatbotPlans}/>
            </Form.Item>
            <Form.Item
                label="Plano de Assistente"
                name="assistantPlanID"
                rules={[
                    {
                        required: true,
                        message: 'Selecione um plano de assistente',
                    },
                ]}
            >
                <InputSearch defaultValue={1} getCallback={getAssistantPlans}/>
            </Form.Item>
            <Form.Item
                label="Bloqueado"
                name="isBlocked"
                valuePropName="checked"
            >
                <Switch/>
            </Form.Item>


            <Form.Item>
                <Button loading={saving} icon={<SaveOutlined/>} type="primary" htmlType="submit">
                    Criar
                </Button>
            </Form.Item>
        </Form>
    )
}
