import Fetch from "./index";

export const getGenerativeSessions = async ({
                                                perPage = 10,
                                                page = 1,
                                                order = "DESC",
                                                field = "createdAt",
                                                ...rest
                                            }) => {
    const filters = {perPage, page, order, field, ...rest};
    for (let key in filters) {
        if (!filters[key])
            delete filters[key];
    }

    const filter = new URLSearchParams(filters).toString();
    return Fetch(`/generative-message-log/sessions?${filter}`);
}

export const getGenerativeSessionHistory = async ({
                                                      page = 1,
                                                      perPage = 10,
                                                      order = 'ASC',
                                                      field = 'createdAt',
                                                      ...filters
                                                  }) => {
    const modifiedFilters = {
        page,
        perPage,
        order,
        field,
        ...filters
    }

    const filter = new URLSearchParams(modifiedFilters).toString();

    return Fetch(`/generative-message-log/?${filter}`);
}
