import {useContext, useEffect, useState} from "react";
import ActionsContext from "../../context/actions";
import ChatBotDataContext from "../../context/chatbot-data";
import {useParams} from "react-router-dom";
import {isSimilar, updatedSince} from "../../utils";
import {Button, FloatButton, Form, Input, message, Modal, Space, theme} from "antd";
import {
    ApartmentOutlined, BulbTwoTone, CameraOutlined, CameraTwoTone, CloseOutlined,
    DatabaseOutlined,
    GroupOutlined,
    Html5Outlined,
    LockOutlined,
    MessageOutlined
} from "@ant-design/icons";
import {getApiKeys} from "../../services/apiKeys";
import {getMy} from "../../services/companies";
import useNode from "../../hooks/useNode";
import MessageApiContext from "../../context/message-api";
import {getSessionHistory} from "../../services/conversation-logs";
import FlowContext from "../../context/flow";

const {REACT_APP_SELF} = process.env;
export default function Toolbar({organize, wrapper, setCenter}) {
    const {snapshot} = useParams();
    const [form] = Form.useForm();
    const [iframeLockEditor, setIframeLockEditor] = useContext(ActionsContext)['iframeLockEditor'];
    const [featureOpened, setFeatureOpened] = useContext(ActionsContext)['featureOpened'];
    const [data, setData] = useContext(ChatBotDataContext);
    const [savedAt, setSaveAt] = useState(undefined);
    const [, setSavedTime] = useState('');
    const [isIframe] = useContext(ActionsContext)['iframe'];
    const messageApi = useContext(MessageApiContext);
    const [isSearchEnabled, setIsSearchEnabled] = useState(false);
    const [, setFocusedNode] = useContext(ActionsContext)['focusedNode'];
    const [searchInput, setSearchInput] = useState('');
    const [company, setCompany] = useState('');
    const [recordFlowOpen, setRecordFlowOpen] = useState(false);
    const [recordFlow, setRecordFlow] = useState('');
    const {setFlow} = useContext(FlowContext);

    // const groupButtonRef = useContext(ActionsContext)['groupButtonRef'];
    // const {createNode} = useNode();

    useEffect(() => {
        document.body.onkeydown = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'f') {
                setIsSearchEnabled(old => !old);
                e.preventDefault();
            }
        }
    }, [])

    useEffect(() => {
        setSaveAt(new Date(data?.updatedAt))
    }, [data])

    useEffect(() => {
        if (!savedAt)
            return;

        setSavedTime(updatedSince(savedAt));
        let interval = setInterval(() => {
            setSavedTime(updatedSince(savedAt));
        }, 1000);

        return () => clearInterval(interval)
    }, [savedAt])

    function copy() {
        getMy().then((response) => {
            setCompany(response.data)
        }).then(() => {
            getApiKeys(1, 1, company.id).then(({data, total}) => {
                if (data?.length < 1) {
                    messageApi.error("Crie alguma ApiKey para gerar um IFrame")
                    return

                }
                let apiKey = data[0]?.data
                const iframeStr = `<iframe name="verbeux-editor-iframe" src='${REACT_APP_SELF}/iframe/editor/${snapshot}?apiKey=${apiKey}'></iframe>`;
                navigator.clipboard.writeText(iframeStr)
                    .then(() => messageApi.success("Iframe copiado com sucesso"))
                    .catch(() => messageApi.error("Falha ao copiar código"))
            }).catch(() => messageApi.error("Falha ao criar código"))
        })
            .catch(() => messageApi.error("Falha ao criar código"))
    }

    const handleSearch = (value) => {
        if (!value || !wrapper.current) return;

        const nodes = Object.values(data?.nodes ?? {});
        for (const node of nodes) {
            if (isSimilar(node.title, value, .8)) {
                setFocusedNode(node.id);
                const nodeWidth = 250;
                const nodeHeight = 100;
                const zoom = 1.5;

                const centerX = node.position.x + nodeWidth / 2;
                const centerY = node.position.y + nodeHeight / 2;

                setCenter(centerX, centerY, {zoom, duration: 500});
                break;
            }
        }
    };

    useEffect(() => {
        if (!searchInput || !isSearchEnabled || !wrapper.current)
            return;

        const tm = setTimeout(() => {
            handleSearch(searchInput);
        }, 250);

        return () => clearTimeout(tm);
    }, [searchInput, data?.nodes, isSearchEnabled, wrapper]);

    const {
        token: {colorPrimary, colorPrimaryBg, colorTextPlaceholder, colorSuccess},
    } = theme.useToken();

    useEffect(() => {
        if (!recordFlow)
            return;

        const interval = setInterval(() => {
            getSessionHistory(recordFlow, 1, 100).then(({data}) => {
                const flow = []
                data?.forEach(({state}) => state?.processed_nodes?.forEach(node => flow.push(node)));
                setFlow(flow);
            }).catch(e => {
                clearInterval(interval);
                console.error(e)
            });
        }, 2000)

        return () => clearInterval(interval);
    }, [recordFlow])

    return (
        <>
            <Modal open={recordFlowOpen}
                   okText={"Concluido"}
                   cancelText={"Fechar"}
                   onCancel={() => setRecordFlowOpen(false)}
                   onOk={form.submit}
                   title={'Acompanhar sessão ao vivo'}
            >
                <Form layout={'vertical'}
                      form={form}
                      onFinish={({session}) => {
                          getSessionHistory(session, 1, 100).then(({data}) => {
                              messageApi.success("Sessão carregada com sucesso!");
                              const flow = []
                              data?.forEach(({state}) => state?.processed_nodes?.forEach(node => flow.push(node)));
                              setFlow(flow);
                              setRecordFlow(session);
                              setRecordFlowOpen(false);
                          }).catch(e => {
                              console.error(e);
                              messageApi.error("Falha ao carregar sessão");
                          });
                      }}>
                    <Form.Item name={'session'}
                               rules={[
                                   {required: true, len: 36, message: "A sessao deve ter 36 caractes"}
                               ]}
                               label={'ID da sessão'}>
                        <Input placeholder={'ID da sessão'}/>
                    </Form.Item>
                </Form>
            </Modal>
            {isSearchEnabled &&
                <Space style={{top: 72, right: 8, position: 'absolute', width: 200}} direction={'horizontal'}>
                    <Input.Search placeholder={'Pesquisar'}
                                  autoFocus
                                  onSearch={handleSearch}
                                  onChange={({target: {value}}) => setSearchInput(value)}
                                  value={searchInput}
                                  suffix={<Button onClick={() => setIsSearchEnabled(false)} style={{marginRight: 0}}
                                                  icon={<CloseOutlined style={{color: colorTextPlaceholder}}/>}
                                                  size={'small'} type={'text'}/>}/>
                </Space>
            }
            <FloatButton.Group shape="square" style={{right: 24, bottom: 24}}>
                <FloatButton
                    onClick={() => setFeatureOpened(old => old === 'triggers' ? '' : 'triggers')}
                    icon={<BulbTwoTone twoToneColor={featureOpened === 'triggers' ? colorPrimaryBg : colorPrimary}/>}/>
                {/*<FloatButton ref={groupButtonRef} icon={<GroupOutlined/>}*/}
                {/*             onClick={() => groupNodes(focusedNode)}*/}
                {/*             tooltip={`Agrupar nós selecionados`}*/}
                {/*/>*/}
                {/*<FloatButton icon={<GroupOutlined/>}*/}
                {/*             onClick={() => createNode()}*/}
                {/*             tooltip={`Criar nó`}*/}
                {/*/>*/}
                {/*<FloatButton icon={<DatabaseOutlined />}*/}
                {/*             onClick={() => setIsComponentOpen(!isComponentOpen)}*/}
                {/*             tooltip={`Componentes`}*/}
                {/*/>*/}
                <FloatButton icon={<CameraTwoTone twoToneColor={recordFlow ? colorSuccess : colorPrimary}/>}
                             tooltip={`Acompanhar sessão`}
                             onClick={() => {
                                 if (recordFlow) {
                                     setFlow([]);
                                     return setRecordFlow('');
                                 }

                                 setRecordFlowOpen(old => !old);
                             }}
                />
                <FloatButton
                    icon={<ApartmentOutlined/>}
                    onClick={() => organize()}
                />
                {!isIframe && <FloatButton
                    onClick={copy}
                    tooltip={'Copiar código iframe'}
                    icon={<Html5Outlined/>}/>}
                {!isIframe && <FloatButton icon={<LockOutlined/>}
                                           onClick={() => setIframeLockEditor(old => !old)}
                                           tooltip={`Editar bloqueio`}
                                           type={iframeLockEditor ? 'primary' : 'default'}/>}
                {data?.internalVersion > 1 && <FloatButton
                    tooltip={'Testar chatbot'}
                    onClick={() => setFeatureOpened(old => old === 'chatTest' ? '' : 'chatTest')}
                    icon={<MessageOutlined/>}/>}
            </FloatButton.Group>
        </>
    )
}
