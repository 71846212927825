import {Button, Col, Form, Input, message, Row} from 'antd';
import {DeleteOutlined, MinusCircleOutlined, PlusOutlined, RobotOutlined, SaveOutlined} from "@ant-design/icons";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";
import {exampleByIntentTitle} from "../../services/gpt";
import IntentSuggestions from "../../components/intent-suggestions";
import MessageApiContext from "../../context/message-api";

export default function IntentEdit() {
    const {intent} = useParams();
    const messageApi = useContext(MessageApiContext);
    const [form] = Form.useForm();
    const [data, setData] = useContext(ChatBotDataContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!intent || !data.intents[intent])
            return;

        form.setFieldsValue(data.intents[intent]);
    }, [intent, data])

    const [loadingGPT, setLoadingGPT] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const title = Form.useWatch('title', form);
    const examples = Form.useWatch('examples', form);

    const loadGpt = useCallback(() => {
        if (loadingGPT)
            return;

        if (!title || title?.length < 4)
            return messageApi.error("Titulo deve conter no minimo 3 letras");

        setLoadingGPT(true);
        exampleByIntentTitle(title, 8).then(({data}) => {
            setSuggestions(data?.suggestions);
        }).catch(() => messageApi.error("Falha ao carregar sugestões, tente novamente")
        ).finally(() => setLoadingGPT(false))
    }, [setLoadingGPT, exampleByIntentTitle, setSuggestions, messageApi, title, loadingGPT]);

    const removeSuggestion = useCallback((i) => {
        setSuggestions(old => {
            old.splice(i, 1)
            return [...old];
        })
    }, [setSuggestions]);

    const addSuggestion = useCallback((i) => {
        const exs = examples ?? [];
        const text = suggestions[i];

        exs.push({text});
        form.setFieldValue('examples', exs);

        removeSuggestion(i)
    }, [suggestions, form, examples, removeSuggestion]);

    const removeAll = useCallback(() => {
        setSuggestions([]);
    }, [setSuggestions]);

    const addAll = useCallback(() => {
        const exs = examples ?? [];
        for (let text of suggestions) {
            exs.push({text});
        }
        form.setFieldValue('examples', exs);
        removeAll();
    }, [suggestions, form, removeAll, examples]);

    const entities = useMemo(() => Object.values(data.entities ?? {}), [data?.entities])

    const save = useCallback((values) => {
        values.examples = values.examples.map(item => {
            const entityList = []
            entities?.forEach(entity => entity?.synonyms?.forEach(synonym => {
                const entityToSearch = "@" + synonym;
                const entityPos = item.text.indexOf(entityToSearch);
                if (entityPos !== -1) {
                    entityList.push({
                        position: [entityPos, entityPos + entityToSearch.length],
                        entity: entity?.id
                    })
                }
            }));


            return {
                text: item.text,
                entities: entityList
            };
        })

        setData(old => {
            old.intents[values.id] = values;
            return {...old};
        });

        navigate('..');
    }, [entities, navigate, setData])

    return (
        <Row>
            <Col span={11}>
                <Form
                    name="basic"
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    onFinish={save}
                >
                    <Form.Item
                        style={{display: 'none'}}
                        name="id"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Título"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'O título é obrigatório',
                            },
                        ]}
                    >
                        <Input placeholder={'Digite um titulo'}
                               suffix={<Button loading={loadingGPT} onClick={loadGpt} type={'primary'}
                                               icon={<RobotOutlined/>}/>}/>
                    </Form.Item>

                    <Form.List name="examples"
                               rules={[
                                   {
                                       validator: async (_, messages) => {
                                           if (!messages || messages.length < 2) {
                                               return Promise.reject(new Error('É necessário 2 mensagens de exemplo no mínimo'));
                                           }
                                       },
                                   },
                               ]}
                    >
                        {(fields, {add, remove}, {errors}) => (<>
                            {fields?.map((field, index) => (<Form.Item
                                key={field.key}
                                label={index === 0 ? 'Exemplos' : ''}
                                required={true}
                                tooltip={'Digite um exemplo de mensagem para essa intenção'}
                            >
                                <Row justify={'space-between'} align={'center'}>
                                    <Col span={22}>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            name={[field.name, 'text']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Adicione uma mensagem ou delete esse campo",
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input.TextArea autoSize
                                                      placeholder={'Digite um exemplo'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        {fields.length > 1 ? (<MinusCircleOutlined
                                            style={{marginTop: '.5rem'}}
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />) : null}
                                    </Col>
                                </Row>
                            </Form.Item>))}
                            <Form.Item>
                                <Button
                                    icon={<PlusOutlined/>}
                                    type="dashed"
                                    onClick={() => add({text: ""})}>Adicionar exemplo</Button>
                            </Form.Item>
                            <Form.Item>
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </>)}
                    </Form.List>
                    <Form.Item>
                        <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                            Salvar
                        </Button>
                        <Button icon={<DeleteOutlined/>} danger onClick={() => {
                            setData(old => {
                                delete old.intents[intent];
                                return {...old};
                            });
                            navigate('..')
                        }} style={{marginLeft: '1rem'}} htmlType="button">
                            Deletar
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={1}/>
            <Col span={12}>
                {(suggestions?.length > 0 || loadingGPT) && <IntentSuggestions
                    addAll={addAll} removeAll={removeAll} addSuggestion={addSuggestion}
                    removeSuggestion={removeSuggestion} suggestions={suggestions} loading={loadingGPT}/>}
            </Col>
        </Row>
    )
}
