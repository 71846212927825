import Fetch from "./index";

export const getDashboard = async (filters) => {
    for (let key in filters) {
        if (!filters[key])
            delete filters[key];
    }

    const filter = new URLSearchParams(filters).toString();

    return await Fetch("/dashboard?"+filter)
}

export const globalSearch = async (text) => {
    if (!text)
        return Promise.reject("failed to global search");

    return await Fetch("/dashboard/search?text="+text)
}
