import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.height ?? '100vh'};
  background-color: ${props => props.color};
  z-index: 100;

  align-items: center;
  justify-content: center;
`;

