import {
    ActionContainer,
    ActiveLabel, Header,
    VersionTag,
} from "./styles";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Link, Route, Routes, useParams} from "react-router-dom";
import {
    createSnapshotVersion,
    deleteSnapshotVersion,
    getSnapshotVersion,
    getSnapshotVersions, translateSnapshotVersion
} from "../../services/snapshot_version";
import {filterData, updatedSince} from "../../utils";
import {getChatBot, updateChatBot} from "../../services/chatbot";
import {Button, List, message, Popconfirm, Tooltip, Upload} from "antd";
import {Title} from "../chatbots/styles";
import VersionCreate from "../version-create";
import VersionEdit from "../version-edit";
import {DeleteOutlined, ExportOutlined, UploadOutlined} from "@ant-design/icons";
import ExportJson from "../../components/export-json";
import MessageApiContext from "../../context/message-api";


export default function Versions() {
    return (
        <Routes>
            <Route index path={'/'} element={<VersionList/>}/>
            <Route path={'/create'} element={<VersionCreate/>}/>
            <Route path={'/:snapshot'} element={<VersionEdit/>}/>
        </Routes>
    );
}

function VersionList() {
    const {id} = useParams();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [snapshots, setSnapshots] = useState([]);
    const [chatbot, setChatBot] = useState({});
    const [duplicating, setDuplicating] = useState('');
    const [uploading, setUploading] = useState(false);
    const messageApi = useContext(MessageApiContext);

    const reload = useCallback(() => {
        setLoading(true);

        getChatBot(id).then(({data}) => {
            setChatBot(data)
        }).catch(console.error);

        getSnapshotVersions(id, page, perPage).then(({data, total}) => {
            setTotal(total)
            setSnapshots(data)
            setLoading(false)
        }).catch(console.error);
    }, [id, page, perPage, setTotal])

    const changeProdModel = useCallback((id) => {
        chatbot.active = id
        delete chatbot.model
        updateChatBot(chatbot.id, chatbot).then(() => {
            reload()
        }).catch(e => {
            console.error(e)
            messageApi.error("Falha ao ativar modelo, tente novamente...")
        })
    }, [chatbot])

    useEffect(reload, [id, page, perPage])

    const duplicate = useCallback(async (id) => {
        setDuplicating(id);
        let {data} = await getSnapshotVersion(id).catch(console.error);
        data.version = "Copia de " + data.version;
        data.status = "EDITING";
        delete data.id;
        await createSnapshotVersion(filterData(data));
        setDuplicating('');
        reload()
    }, [getSnapshotVersion, createSnapshotVersion, filterData])

    const importVersionFile = (file) => {
        if (file && file.type === "application/json") {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const content = JSON.parse(e.target.result);
                    if (content.hasOwnProperty("name")) {
                        translateSnapshotVersion(content, chatbot.id).then(() => {
                            setUploading(false);
                            reload();
                        }).catch((e) => {
                            setUploading(false);
                            console.error(e)
                            message.error("Ocorreu uma falha interna na importação da sua versão.")
                        });
                    } else {
                        importNativeVersionFile(content);
                    }

                } catch (error) {
                    message.error("Ocorreu um erro na leitura do arquivo.");
                    console.log("Ocorreu um erro na leitura do arquivo.", error)
                }
            };
            reader.readAsText(file);
        } else {
            console.log("Arquivo inserido não corresponde a um json.")
            message.error("Por favor, selecione um arquivo JSON válido.");
        }
        return false;
    }

    const importNativeVersionFile = (jsonData) => {
        delete jsonData.id;
        jsonData.chatbot = parseInt(id);
        jsonData.version = "Exportação de " + jsonData.version;
        setUploading(true);
        createSnapshotVersion(filterData(jsonData)).then(() => {
            setUploading(false);
            reload();
        }).catch((e) => {
            setUploading(false);
            console.error(e)
            message.error("Ocorreu uma falha interna na importação da sua versão.")
        });
    }

    return (
        <List
            header={
                <Header>
                    <Title>Versões</Title>
                    <ActionContainer>
                        <Upload
                            showUploadList={false}
                            beforeUpload={importVersionFile}
                            accept=".json"
                        >
                            <Button loading={uploading} type={'dashed'} icon={<UploadOutlined/>}>Importar</Button>
                        </Upload>
                        <Link to={'create'}>
                            <Button type={'primary'}>Criar</Button>
                        </Link>
                    </ActionContainer>

                </Header>
            }
            loading={loading}
            pagination={{
                position: "bottom",
                align: "end",
                onChange: (page, perPage) => {
                    setPage(page);
                    setPerPage(perPage);
                },
                total,
                pageSize: perPage,
                current: page
            }}
            itemLayout="horizontal"
            dataSource={snapshots}
            renderItem={(item) => (
                <List.Item actions={[
                    ...(chatbot.active !== item.id ? [
                        <Popconfirm
                            title="Você tem certeza?"
                            description="Essa ação vai mudar a versão atual de produção para a versão selecionada."
                            onConfirm={async () => await changeProdModel(item.id)}
                            cancelText={'Cancelar'}
                        >
                            <Button>Ativar</Button>
                        </Popconfirm>
                    ] : []),
                    <Link to={item.id}>
                        <Button type={'text'}>Editar</Button>
                    </Link>,
                    <Button loading={duplicating === item?.id} onClick={() => duplicate(item?.id)}
                            type={'text'}>Duplicar</Button>,
                    <ExportJson filename={item.version} data={item}>
                        <Tooltip mouseEnterDelay={0.7} title={"Exportar Versão"} trigger="hover">

                            <Button type={'dashed'} icon={<ExportOutlined/>}/>
                        </Tooltip>
                    </ExportJson>,
                    ...(total > 1 ? [<Popconfirm
                            title="Você tem certeza?"
                            description="Essa ação vai deletar todas as informações dessa versão"
                            cancelText={'Cancelar'}
                            onConfirm={async () => {
                                await deleteSnapshotVersion(item.id)
                                reload()
                            }}
                        >
                            <Button type={'text'} danger icon={<DeleteOutlined/>}/>
                        </Popconfirm>] : []
                    ),
                ]}>
                    <List.Item.Meta
                        avatar={<VersionTag><p>v{item.internalVersion}</p></VersionTag>}
                        title={
                            <Link to={`editor/${item.id}`}>
                                {item.version} {chatbot.active === item.id &&
                                <ActiveLabel>• Ativo</ActiveLabel>}
                            </Link>}
                        description={"Última edição à " + updatedSince(new Date(item.updatedAt))}
                    />
                </List.Item>
            )}
        />
    );
}
