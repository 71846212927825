import {Alert, Button, Col, Form, Input, Row, Space, theme, Typography} from 'antd';
import {Divider, Switch} from "antd/lib";
import {ArrowUpOutlined, SaveOutlined, WarningOutlined} from "@ant-design/icons";
import React, {useCallback, useContext, useState} from "react";
import {createChatbot} from "../../services/chatbot";
import ChatbotCreate from '../../assets/chatbot-create.png';
import {useNavigate} from "react-router-dom";
import MessageApiContext from "../../context/message-api";
import UpgradeWarning from "../../components/upgrade-warning";

export default function ChatBotCreateFlow() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const [limitExceeded, setLimitExceeded] = useState(false);

    const messageApi = useContext(MessageApiContext);

    const save = useCallback(async (data) => {
        setSaving(true)
        data.aiModel = "text-embedding-3-small"
        createChatbot({...data, isActive: true, infra: "EMBED", type: "FLOW"}).then(({data}) => {
            setSaving(false);
            navigate(data?.active.length > 0 ? `/chatbots/${data?.id}/versions/editor/${data?.active}` : `/chatbots/${data?.id}/versions`);
            messageApi.success("Chatbot criado com sucesso!");
        }).catch(({data: {errorMessage}}) => {
            if (errorMessage === 'max chatbots reached') {
                setLimitExceeded(true)
                return messageApi.error("Limite de chatbots excedido.");
            }
            messageApi.error("Falha ao salvar informações do chatbot");
        }).finally(() => {
            setSaving(false);
        })
    }, []);

    return (
        <Space style={{width: '100%'}} direction={'vertical'}>
            {limitExceeded && <UpgradeWarning
                message={"Limite de bots excedido"}
                description={"Você já ultrapassou o limite de chatbots do seu plano."}
            />}
            <Row justify={'space-between'}>
                <Col span={11}>
                    <Form
                        onFinish={save}
                        name="basic"
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                    >
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Digite um nome para o chatbot',
                                },
                            ]}
                        >
                            <Input placeholder={'Digite um nome'}/>
                        </Form.Item>
                        <Form.Item
                            label="Descrição"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Digite uma descrição curta para o chatbot',
                                },
                            ]}
                        >
                            <Input.TextArea placeholder={'Digite uma descrição'} showCount maxLength={150}/>
                        </Form.Item>
                        <br/>
                        <Form.Item style={{width: '100%'}}>
                            <Button disabled={saving || limitExceeded} style={{width: '100%'}} loading={saving} icon={<SaveOutlined/>} type="primary"
                                    htmlType="submit">
                                Criar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col>
                    <Divider style={{height: 500, width: 4}} type={'vertical'}/>
                </Col>
                <Col span={11}>
                    <img width={'100%'} src={ChatbotCreate}/>
                    <Typography.Title level={4}>Fluxo</Typography.Title>
                    <Typography.Paragraph>- Construa um fluxo de conversa totalmente customizado.</Typography.Paragraph>
                    <Typography.Paragraph>- Integre seu sistema utilizando webhook. </Typography.Paragraph>
                    <Typography.Paragraph>- Painel de analytics detalhado. </Typography.Paragraph>
                </Col>
            </Row>
        </Space>
    )
}
