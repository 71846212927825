import React from "react";
import {Routes, Route} from "react-router-dom";
import AdminLayout from "../../components/admin-layout";
import ChatbotPlans from "./ChatbotPlans";
import AssistantPlans from "./AssistantPlans";

export default function Plans() {
    return (
        <AdminLayout>
            <Routes>
                <Route index path={"/chatbot"} element={<ChatbotPlans/>}/>
                <Route index path={"/assistant"} element={<AssistantPlans/>}/>
            </Routes>
        </AdminLayout>
    );
}
