import React, { useRef, useState } from 'react';
import { AudioMessageContainer, AudioBtn } from './styles';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

const AudioMessage = ({ src, duration, bgColor, isMachine }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleAudioPlayPause = () => {
        if (audioRef.current) {
            if (audioRef.current.paused) {
                audioRef.current.play();
                setIsPlaying(true);
            } else {
                audioRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <AudioMessageContainer isMachine={isMachine} bgColor={bgColor}>
            <audio ref={audioRef} src={src} style={{ display: 'none' }} onEnded={() => setIsPlaying(false)} />
            <AudioBtn onClick={handleAudioPlayPause}>
                {isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
            </AudioBtn>
            {Math.round(duration)}s
        </AudioMessageContainer>
    );
};

export default AudioMessage;
