import {Link, useParams} from "react-router-dom";
import {
    Button,
    Card,
    Divider,
    Popover, Row,
    Skeleton,
    Space,
    Tag,
    theme, Tooltip,
    Typography
} from "antd";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {getGenerativeSessionHistory} from "../../../../../services/generative-message-logs";
import MessageApiContext from "../../../../../context/message-api";
import {updatedSince} from "../../../../../utils";
import {
    ArrowLeftOutlined,
    CheckOutlined,
    CopyOutlined,
    InfoCircleOutlined,
    InfoOutlined,
    ThunderboltOutlined, WarningOutlined
} from "@ant-design/icons";

export default function View() {
    const {historic_id} = useParams();
    const messageApi = useContext(MessageApiContext);
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getGenerativeSessionHistory({
            sessionID: historic_id,
            perPage,
            page,
        })
            .then(({data, total}) => {
                setData(data);
                setTotal(total);
            }).catch((error) => {
            console.error(error);
            messageApi.error("Falha ao carregar historico.");
        }).finally(() => {
            setLoading(false);
        });
    }, [perPage, page, historic_id]);


    if (loading)
        return <Skeleton active/>;


    return (
        <Space direction={'vertical'}
               style={{width: '100%'}}>
            <Link to={'..'}>
                <Button icon={<ArrowLeftOutlined/>} style={{padding: 2}} type={'text'}>Histórico</Button>
            </Link>
            <Typography.Title level={4}>{historic_id}</Typography.Title>
            <Typography.Text>{updatedSince(new Date(data[0]?.createdAt))} atrás</Typography.Text>
            <Space direction={'vertical'} style={{
                maxHeight: '600px',
                overflowY: 'scroll',
                width: '100%'
            }}>
                {data.map((item, i) => <Message key={i} {...item}/>)}
            </Space>

        </Space>
    );
}

function Message({userMessage, response, isAnythingElse, createdAt, clientData}) {
    const {
        token: {colorPrimary},
    } = theme.useToken();

    return (
        <div style={{
            display: 'grid',
            flexDirection: 'column',
            width: "100%",
            gap: '16px',
        }}>
            <Divider>{updatedSince(new Date(createdAt)) + ' atrás'}</Divider>
            <Space>
            {clientData && Object.keys(clientData).map((item) => <Tag color={'primary'}>{item}: {clientData[item]}</Tag>)}
            </Space>
            <div style={{width: 'fit-content', maxWidth: '80%'}}>
                <Card size={'small'}>
                    <Typography.Text ellipsis={{
                        tooltip: userMessage,
                        rows: 2,
                    }}>
                        {userMessage}
                    </Typography.Text>
                </Card>
            </div>
            {response?.map((item, i) => <div style={
                {
                    width: 'fit-content',
                    justifySelf: 'flex-end',
                    maxWidth: '80%'
                }
            }>
                <Card size={'small'}
                      style={{borderColor: colorPrimary}}
                      key={i}>
                    {item.type === 'text' && <TextMessage text={item.data}/>}
                    {item.type === 'trigger' && <TriggerMessage data={item.data}/>}
                    {item.type === 'reference' && <ReferenceMessage data={item.data}/>}
                </Card>
            </div>)}
            <div style={
                {
                    width: 'fit-content',
                    justifySelf: 'flex-end',
                    maxWidth: '80%'
                }
            }>
                {isAnythingElse && <Tag icon={<WarningOutlined/>} color={'warning'}>Fora do contexto</Tag>}
            </div>
        </div>
    )
}

function TextMessage({text}) {
    const {
        token: {colorPrimary},
    } = theme.useToken();

    return (
        <Typography.Text copyable={{
            icon: [<CopyOutlined style={{color: colorPrimary}} key="copy-icon"/>,
                <CheckOutlined style={{color: colorPrimary}} key="copied-icon"/>],
            tooltips: ['Copiar', 'Copiado!'],
        }} ellipsis={{
            tooltip: text,
            rows: 2,
        }}>
            {text}
        </Typography.Text>
    );
}

function TriggerMessage({data}) {
    const parsedData = useMemo(() => JSON.parse(data ?? "{}"), [data])

    const functionPretty = useMemo(() =>
            `${parsedData?.function_name}(${Object.values(parsedData?.args ?? {})?.join(", ")})`
        , [parsedData]);

    return (
        <Popover content={<code>{JSON.stringify(parsedData, null, "\t")}</code>}>
            <Tag icon={<ThunderboltOutlined/>}
                 color={'orange'}>
                {functionPretty}
            </Tag>
        </Popover>
    );
}

function ReferenceMessage({data}) {
    const parsedData = useMemo(() => JSON.parse(data ?? "{}"), [data])

    return (
        <Space>
            {parsedData.map((item, i) =>
                <Popover key={i} title={item?.title} content={<p>
                    <b>Indentificador:</b> {item?.identifier} <br/>
                    <b>Score</b> {item?.score?.toFixed(2)}
                </p>}>
                    <Link to={item?.link ?? '#'}>
                        <Button icon={<InfoCircleOutlined/>}/>
                    </Link>
                </Popover>
            )}
        </Space>
    );
}
