import {
    Button,
    Card, Col,
    Form,
    Input, message,
    Modal, Result, Row, Space, Switch, Tooltip,
    Typography,
} from "antd";
import {
    CloseOutlined, DownloadOutlined,
    LinkOutlined, LoadingOutlined,
    SettingOutlined,
} from "@ant-design/icons";

import React, {useCallback, useEffect, useMemo, useState} from "react";
import FacebookLogin from "../fb-login";
import {
    configureWebhook,
    createWhatsappInstance,
    deleteWhatsappInstance,
    getWhatsappInstance, registerNumber,
    updateWhatsappInstance, verifyPhoneNumber
} from "../../services/whatsappInstances";


export function WhatsappIntegration({icon, label, description, assistantID, messageApi}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);
    const [partialData, setPartialData] = useState({});
    const showDescription = useMemo(() => !data || data?.step > 2, [data]);

    useEffect(() => {
        setLoading(true)
        getWhatsappInstance({assistantID}).then(({data}) => {
            if (data.length === 0)
                return
            setData(data[0])
        }).catch((error) => {
            console.log(error)
            messageApi.error("Erro ao buscar integração com whatsapp.");
        }).finally(() => {
            setLoading(false)
        });
    }, [assistantID, messageApi]);

    const install = useCallback((code, phone_number_id, waba_id) => {
        setButtonLoading(true)
        const createRequest = {
            assistantID: parseInt(assistantID),
            active: true,
            metaCode: code,
            phoneNumberID: phone_number_id,
            metaBusinessID: waba_id
        };

        createWhatsappInstance(createRequest).then(({data}) => {
            setData(data);
        }).catch(({data}) => {
            // if (error?.errorMessage?.includes("This authorization code has been used")) {
            if (data?.errorMessage?.includes("Two step verification PIN Mismatch")) {
                setPartialData(createRequest);
                return;
            }
            messageApi.error("Erro ao instalar integração com site.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [assistantID, messageApi]);

    const uninstall = useCallback(() => {
        setButtonLoading(true);
        deleteWhatsappInstance(data.id).then(() => {
            setData(undefined);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao remover integração com site.");
        }).finally(() => {
            setButtonLoading(false)
        });
    }, [data, messageApi]);

    const update = useCallback(async () => {
        setButtonLoading(true);
        updateWhatsappInstance(data.id, {...data}).then(({data: updatedData}) => {
            setData({...updatedData});
            setConfigOpen(false);
            messageApi.success("Atualizado com sucesso!");
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao atualizar.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [data, messageApi]);


    return (
        <>
            <Modal title="Configurar Chat Externo"
                   open={configOpen}
                   cancelText={'Cancelar'}
                   confirmLoading={buttonLoading}
                   okText={'Salvar'}
                   onOk={update}
                   onCancel={() => setConfigOpen(false)}>
                <br/>

                <Form layout={'vertical'}>
                    <Form.Item label={'Nome'}>
                        <Input value={data?.name}
                               onChange={({target: {value}}) => setData(old => ({...old, name: value}))}/>
                    </Form.Item>

                    <Form.Item label={'Mensagem Inicial'}>
                        <Input.TextArea value={data?.initialMessage}
                                        placeholder="Olá, sou um assistente virtual e estou pronto para lhe auxiliar"
                                        onChange={({target: {value}}) => setData(old => ({
                                            ...old,
                                            initialMessage: value
                                        }))}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Card
                loading={loading}
                title={<Typography.Title level={5} ellipsis={{tooltip: label}}>{icon} {label}</Typography.Title>}
                extra={
                    <FacebookLogin data={data}
                                   loading={loading}
                                   buttonLoading={buttonLoading}
                                   messageApi={messageApi}
                                   install={install}
                                   uninstall={uninstall}/>
                }
                actions={data ? [
                    <Tooltip title={'Em breve será possível configurar'}>
                        <SettingOutlined style={{cursor: 'not-allowed'}}/>
                    </Tooltip>,
                    <a href={"https://wa.me/" + data?.phoneNumber?.replaceAll(" ", "").replace("-", "")}
                       target={'_blank'}>
                        <LinkOutlined/>
                    </a>
                ] : undefined}>
                {showDescription ? description : <Configuration setData={setData} messageApi={messageApi} data={data}/>}
            </Card>
        </>
    )
}

const steps = [
    {
        title: "Verificando",
        description: "Validando número configurado na meta"
    },
    {
        title: "Registrando",
        description: "Registrando seu número no whatsapp"
    },
    {
        title: "Configurando",
        description: "Enviando configuração para meta."
    }
]

function Configuration({data, messageApi, setData}) {
    const [pin, setPin] = useState("");
    const [error, setError] = useState("");
    const [step, setStep] = useState(data?.step || 0);

    useEffect(() => {
        if (error)
            return;

        if (step === 0) {
            verifyPhoneNumber(data.id)
                .then(() => setStep(1))
                .catch((error) => {
                    setError(error?.data?.errorMessage || error?.data?.message)
                    console.error("Falhou (0)!", error)
                })
            return
        }
        if (step === 1 && pin) {
            registerNumber(data.id, pin)
                .then(() => setStep(2))
                .catch((error) => {
                    setPin("");
                    setError(error?.data?.errorMessage || error?.data?.message)
                    console.error("Falhou (1)!", error)
                })
        }
        if (step === 2) {
            configureWebhook(data.id)
                .then(() => {
                    setStep(3);
                    setData(old => ({...old, step: 3}))
                    messageApi.success("Configuração realizada com sucesso!");
                })
                .catch((error) => {
                    let message = error?.data?.errorMessage || error?.data?.message;
                    if (message === 'step is already')
                        return setStep(3);

                    setError(message);
                    console.error("Falhou (2)!", error);
                })
        }
    }, [data?.id, step, pin, error]);

    if (error) {
        return <Space direction={'vertical'}>
            <Row justify={'space-between'}>
                <Typography.Title style={{fontSize: 24}}>
                    {steps[Math.abs(data.step)]?.title}
                </Typography.Title>
                <Button onClick={() => {
                    setError("");
                }}>
                    Tentar novamente
                </Button>
            </Row>
            <Typography.Paragraph type={'danger'}
                                  ellipsis={{
                                      rows: 2,
                                      expandable: true,
                                      symbol: <>Ler mais</>
                                  }}
                                  style={{width: '100%'}}>
                {error}
            </Typography.Paragraph>
        </Space>
    }

    if (step === 1 && !pin)
        return <Form layout={'vertical'}>
            <Row justify={'center'}>
                <Form.Item label={'Inserir PIN'}
                           name={'pin'}
                           help={"Crie um pin de 6 digitos"}
                >
                    <Input.OTP
                        onChange={value => setPin(value.replace(/\D/g, ''))}
                        formatter={(value) => value.replace(/\D/g, '')}
                        length={6}/>
                </Form.Item>
            </Row>
        </Form>


    return (
        <Row justify={'center'} wrap>
            <Col>
                <Space size={16} style={{fontSize: 24}}>
                    <LoadingOutlined/>
                    <Typography.Text style={{fontSize: 24}}>
                        {steps[step]?.title}
                    </Typography.Text>
                </Space>
            </Col>
            <Col span={24}>
                <Typography.Paragraph type={'secondary'} style={{textAlign: 'center'}}>
                    {steps[step]?.description} <br/>(não feche a aba)
                </Typography.Paragraph>
            </Col>
        </Row>
    )
}
