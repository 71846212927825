import Fetch from "./index";

export const exampleByIntentTitle = async (title, quantity) => {
    return await Fetch(
        '/gpt/example-by-intent-title',
        {
            method: 'POST',
            body: JSON.stringify({title, quantity}),
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
}

export const summarizationFromBotData = async (name, scope, subject) => {
    return await Fetch(
        '/gpt/summarize-from-bot-data',
        {
            method: 'POST',
            body: JSON.stringify({name, scope, subject}),
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
}

export const insightFromDashboardData = async (data) => {
    return await Fetch(
        '/gpt/dashboard-insight',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
}

