import {Button, Form, Input, Row, Space} from 'antd';
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    MinusCircleOutlined,
    PlusOutlined, RobotOutlined,
    SaveOutlined, UserOutlined
} from "@ant-design/icons";
import {useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";

export default function ConversationCreate() {
    const [form] = Form.useForm();
    const [data, setData] = useContext(ChatBotDataContext);
    const navigate = useNavigate();

    const save = useCallback((values) => {
        setData(old => {
            return {...old, conversation: [...(old?.conversation ?? []), values]}
        });
        navigate('..');
    }, [setData])

    const messages = Form.useWatch(`messages`, form);

    return (
        <Form
            name="basic"
            style={{
                maxWidth: 600,
            }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            onFinish={save}
        >
            <Form.Item
                label="Titulo"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Digite um nome para a empresa',
                        min: 3,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.List
                name="messages"
                rules={[
                    {
                        validator: async (_, messages) => {
                            if (!messages || messages.length < 2) {
                                return Promise.reject(new Error('É necessário 2 mensagens de exemplo no mínimo'));
                            }
                        },
                    },
                ]}
            >
                {(fields, {add, remove}, {errors}) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? 'Mensagens' : ''}
                                required={true}
                                key={field.key}
                                tooltip={'A esquerda estao as mensagens do usuário e a direita as mensagens do robô'}
                            >
                                <Row justify={messages?.[field?.name]?.isAssistant ? 'end' : 'start'}>
                                    <Space direction={'horizontal'}>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Adicione uma mensagem ou delete esse campo",
                                                },
                                            ]}
                                            name={[field.name, 'data']}
                                            noStyle
                                        >
                                            <Input placeholder="Mensagem"
                                                   style={{minWidth: '300px'}}
                                                   suffix={
                                                       <>
                                                           {!messages[field.name]?.isAssistant ?
                                                               <Button type={'primary'}
                                                                       icon={
                                                                           <ArrowRightOutlined/>}
                                                                       onClick={() => {
                                                                           const messages = form.getFieldValue("messages");
                                                                           messages[field.name].isAssistant = true;
                                                                           form.setFieldsValue({messages});
                                                                       }}
                                                               /> :
                                                               <RobotOutlined/>
                                                           }
                                                       </>
                                                   }
                                                   prefix={<>
                                                       {messages[field.name]?.isAssistant ?
                                                           <Button type={'primary'}
                                                                   icon={
                                                                       <ArrowLeftOutlined/>}
                                                                   onClick={() => {
                                                                       const messages = form.getFieldValue("messages");
                                                                       messages[field.name].isAssistant = false;
                                                                       form.setFieldsValue({messages});
                                                                   }}
                                                           /> :
                                                           <UserOutlined/>
                                                       }
                                                   </>}
                                            />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Space>
                                </Row>
                            </Form.Item>
                        ))}
                        <Form.Item style={{marginBottom: '2rem'}}>
                            <Row justify={'space-between'}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        add({data: '', isAssistant: false, type: 'text'});
                                    }}
                                    icon={<PlusOutlined/>}
                                >
                                    Adicionar mensagem usuário
                                </Button>
                                <Button
                                    type="dashed"
                                    onClick={() => add({data: '', isAssistant: true, type: 'text'})}
                                    icon={<PlusOutlined/>}
                                >
                                    Adicionar mensagem BOT
                                </Button>
                                <Form.ErrorList errors={errors}/>
                            </Row>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item>
                <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                    Salvar
                </Button>
            </Form.Item>
        </Form>
    )
}
