import {useCallback, useContext} from "react";
import ChatBotDataContext from "../../context/chatbot-data";
import {Button, Col, Form, Input, Row, Select} from "antd";
import { MinusCircleOutlined} from "@ant-design/icons";

export const SetText = "set_text";
export const KeyIFrameLocalStorage = "IFrameChatbotApiKey";
export const SetEntity = "set_entity";
export const SetSystem = "set_system";
export const SetRegex = "set_regex";
export const SetBoolean = "set_boolean";

const booleanOptions = [
    {
        label: "true",
        value: "true"
    },
    {
        label: "false",
        value: "false"
    }
]

export default function ContextActionInput({remove, data, nodeId, index, disabled, setNodeData}) {
    const [fullData, setFullData] = useContext(ChatBotDataContext);

    const setType = useCallback((type) => {
        setNodeData(old => {
            if (!old.contextActions) old.contextActions = [];
            if (!old.contextActions[index]) old.contextActions[index] = {};
            old.contextActions[index].type = type;
            return {...old};
        })
        setFullData(old => {
            if (!old.nodes) old.nodes = {};
            if (!old.nodes[nodeId]) old.nodes[nodeId] = {};
            if (!old.nodes[nodeId].contextActions) old.nodes[nodeId].contextActions = [];
            if (!old.nodes[nodeId].contextActions[index]) old.nodes[nodeId].contextActions[index] = {};
            old.nodes[nodeId].contextActions[index].type = type;
            return {...old};
        })
    }, [nodeId, setFullData, index])

    const setKey = useCallback((key) => {
        setNodeData(old => {
            if (!old.contextActions) old.contextActions = [];
            if (!old.contextActions[index]) old.contextActions[index] = {};
            old.contextActions[index].key = key;
            return {...old};
        })
        setFullData(old => {
            if (!old.nodes) old.nodes = {};
            if (!old.nodes[nodeId]) old.nodes[nodeId] = {};
            if (!old.nodes[nodeId].contextActions) old.nodes[nodeId].contextActions = [];
            if (!old.nodes[nodeId].contextActions[index]) old.nodes[nodeId].contextActions[index] = {};
            old.nodes[nodeId].contextActions[index].key = key;
            return {...old};
        })
    }, [nodeId, setFullData, index])

    const setValue = useCallback((value) => {
        setNodeData(old => {
            if (!old.contextActions) old.contextActions = [];
            if (!old.contextActions[index]) old.contextActions[index] = {};
            old.contextActions[index].value = value;
            return {...old};
        });
        setFullData(old => {
            if (!old.nodes) old.nodes = {};
            if (!old.nodes[nodeId]) old.nodes[nodeId] = {};
            if (!old.nodes[nodeId].contextActions) old.nodes[nodeId].contextActions = [];
            if (!old.nodes[nodeId].contextActions[index]) old.nodes[nodeId].contextActions[index] = {};
            old.nodes[nodeId].contextActions[index].value = value;
            return {...old};
        })
    }, [nodeId, setFullData, index])

    const options = <>
        <Select.Option selected value={SetText}>Texto</Select.Option>
        <Select.Option value={SetEntity}>Entidade</Select.Option>
        <Select.Option value={SetRegex}>Regex</Select.Option>
        <Select.Option value={SetBoolean}>Boleano</Select.Option>
        {/*<Select.Option value={SetSystem}>Sistema</Select.Option>*/}
    </>;

    const entities = <>
        {Object.values(fullData?.entitiesValued ?? {})?.map((item, i) =>
            <Select.Option key={i} title={item?.title} value={item?.id}>{"@" + item?.title}</Select.Option>
        )}
    </>

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={22}>
                    <Form.Item style={{marginBottom: 8, marginTop: 8}}>
                        <Select
                            className="nodrag nopan"
                            disabled={disabled}
                            onChange={(value) => setType(value)}
                            value={data?.type ?? ''}
                        >{options}</Select>
                        {/*<Remove disabled={disabled} onClick={remove}>Remover <MdOutlineClose size={20}/></Remove>*/}
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Button onClick={remove} type={'text'} danger icon={<MinusCircleOutlined/>}></Button>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={10}>
                    <Form.Item>
                        <Input disabled={disabled} onChange={({target}) => setKey(target.value)}
                               value={data?.key ?? ''}
                               prefix={<p>$</p>}
                               placeholder={'Chave'}/>
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item>
                        {data?.type === SetText &&
                            <Input disabled={disabled} onChange={({target}) => setValue(target.value)}
                                   value={data?.value ?? ''}
                                   placeholder={'Valor'}/>}
                        {data?.type === SetEntity && <Select
                            disabled={disabled}
                            onChange={(value) => setValue(value)}
                            value={data?.value ?? ''}
                            placeholder={'@entidade'}
                            showSearch
                            filterOption={(input, option) =>
                                (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        >{entities}</Select>}
                        {data?.type === SetRegex &&
                            <Input disabled={disabled} onChange={({target}) => setValue(target.value)}
                                   value={data?.value ?? ''}
                                   placeholder={'Valor'}/>}
                        {data?.type === SetBoolean &&
                            <Select
                                className="nodrag nopan"
                                disabled={disabled}
                                onChange={(value) => setValue(value)}
                                value={data?.value ?? ''}
                                placeholder={'boleano'}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={booleanOptions}
                            />}
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
