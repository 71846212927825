import {
    Avatar,
    Button,
    Card,
    Col,
    Divider,
    Empty,
    List,
    Modal,
    Radio,
    Row,
    Skeleton,
    Space,
    Statistic,
    Table,
    Tag,
    theme,
    Tooltip,
    Typography,
} from "antd";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import Loading from "../loading";
import {Overview} from "../chart";
import {
    BarChartOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    CheckOutlined,
    CloseOutlined,
    CrownFilled,
    DollarOutlined, DownloadOutlined,
    FilePdfOutlined,
    LineChartOutlined,
    LineOutlined,
    LinkOutlined,
    ReloadOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {getInvoice, updateInvoice} from "../../services/invoice";
import MessageApiContext from "../../context/message-api";
import {CSVLink} from "react-csv";

const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

const treatValue = {
    "Mensagens p/ sessão": (value) => value.toFixed(2).toLocaleString(),
    "Tempo Med. Resposta": (value) => value.toFixed(2).toLocaleString() + 's',
    "Taxa de Compreensão": (value) => (value * 100).toFixed(2).toLocaleString() + "%"
};

export function DashboardGraphs({
                                    loading,
                                    data,
                                    withList = true,
                                    noBackground = false,
                                }) {
    const [chart, setChart] = useState("Mensagens");
    const [graphType, setGraphType] = useState("area");

    const handleChartChange = (e) => {
        setChart(e.target.value);
    };

    const sourceAssistants = useMemo(() => {
        if (!data.lists || data.lists?.length <= 0 || !data.lists[0] || data.lists[0].elements?.length <= 0)
            return [];

        return data.lists[0]?.elements ?? [];
    }, [data]);

    const sourceInvoices = useMemo(() => {
        if (!data.lists || data.lists?.length <= 0 || !data.lists[1] || data.lists[1].elements?.length <= 0)
            return [];

        return data.lists[1]?.elements ?? [];
    }, [data]);

    return (
        <Space direction={"vertical"} style={{width: "100%"}}>
            <Card style={{background: noBackground && "transparent"}} bordered={!noBackground}>
                <Row justify={"space-around"}>
                    {loading
                        ? new Array(5).fill("").map((_, i) => (
                            <React.Fragment key={i}>
                                <Statistic loading={loading} title="Carregando..."/>
                                {i !== 4 && <Divider style={{height: 50}} type={"vertical"}/>}
                            </React.Fragment>
                        ))
                        : data?.values?.map(({label, value, changePeriod}, i) => (
                            <React.Fragment key={i}>
                                <Statistic
                                    title={label}
                                    suffix={<ChangeRate changePeriod={changePeriod}/>}
                                    value={
                                        treatValue[label] ? treatValue[label](value) : value.toLocaleString()
                                    }
                                />
                                {i !== data?.values?.length - 1 && (
                                    <Divider style={{height: 70}} type={"vertical"}/>
                                )}
                            </React.Fragment>
                        ))}
                </Row>
            </Card>
            <Row gutter={[8, 8]}>
                <Col span={withList ? 16 : 24}>
                    <Card
                        style={{height: 420, background: noBackground && "transparent"}}
                        bordered={!noBackground}
                    >
                        {loading ? (
                            <Loading height={"300px"} title={"Carregando gráfico"}/>
                        ) : (
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <Radio.Group value={chart} onChange={handleChartChange} style={{marginBottom: 16}}>
                                    {data?.graphs
                                        ?.filter((item) => item?.points)
                                        .map(({name}, i) => (
                                            <Radio.Button defaultChecked={i === 0} key={i} value={name}>
                                                {name}
                                            </Radio.Button>
                                        ))}
                                </Radio.Group>

                                <Radio.Group
                                    style={{display: "flex", marginBottom: 16}}
                                    value={graphType}
                                    onChange={(e) => setGraphType(e.target.value)}
                                >
                                    <Radio.Button value="bar" style={{display: "flex", alignItems: "center"}}>
                                        <BarChartOutlined
                                            style={{fontSize: "large", display: "flex", justifyContent: "center"}}/>
                                    </Radio.Button>
                                    <Radio.Button value="area" style={{
                                        fontSize: "x-large",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                        <LineChartOutlined
                                            style={{fontSize: "large", display: "flex", justifyContent: "center"}}/>
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        )}
                        {loading ? (
                            <Loading height={"300px"} title={"Carregando gráfico"}/>
                        ) : (
                            <Overview graphType={graphType}
                                      data={data?.graphs?.find((item) => item.name === chart)?.points}/>
                        )}
                    </Card>
                </Col>
                {withList && (<>
                        <Col span={8}>
                            <Card style={{height: 420}}>
                                <List
                                    loading={loading}
                                    size="small"
                                    header={<Row justify={"space-between"}>
                                        <Typography maxLength={10}>
                                            {data?.lists?.length > 0 && data?.lists[0]?.title}
                                        </Typography>
                                    </Row>}
                                    locale={{emptyText: <Empty description={"Nenhum dado encontrado"}/>}}
                                    dataSource={sourceAssistants}
                                    renderItem={(item) => (
                                        <List.Item actions={[<p>{item?.total?.toLocaleString()} mensagens</p>]}>
                                            <Space>
                                                {item.isChecked ? (
                                                    <Tag icon={<CrownFilled/>} color={"gold"}/>
                                                ) : (
                                                    <Tooltip title={"Grátis"}>
                                                        <Tag icon={<DollarOutlined/>} color={"green"}/>
                                                    </Tooltip>
                                                )}

                                                <Link
                                                    to={`/${item.type}s/${item?.id}/${item.type === 'chatbot' ? 'versions' : ''}`}>
                                                    {item.name?.length > 10
                                                        ? item.name?.substring(0, 20) + "..."
                                                        : item.name}
                                                </Link>
                                            </Space>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        </Col>
                        {data?.lists?.length > 1 && <Col span={24}>
                            <Invoices sourceInvoices={sourceInvoices} data={data} loading={loading}/>
                        </Col>}
                    </>
                )}
            </Row>
        </Space>
    );
}

function Invoices({loading, sourceInvoices, data}) {
    const [viewing, setViewing] = useState(0);

    return (<>
            <Modal width={'70%'} open={viewing} footer={false} onCancel={() => setViewing(false)}>
                <InvoiceView id={viewing}/>
            </Modal>
            <Card>
                <List
                    loading={loading}
                    size="small"
                    itemLayout={'horizontal'}
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 4,
                        xl: 4,
                        xxl: 6,
                    }}
                    header={<Row justify={"space-between"}>
                        <Typography maxLength={10}>
                            {data?.lists[1]?.title}
                        </Typography>
                    </Row>}
                    locale={{
                        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Nenhum dado encontrado"}/>
                    }}
                    dataSource={sourceInvoices}
                    renderItem={(item) => (
                        <List.Item>
                            <Invoice setViewing={setViewing} {...item}/>
                        </List.Item>
                    )}
                />
            </Card>
        </>
    );
}

function Invoice({setViewing, ...rest}) {
    const [statusLoading, setStatusLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const [data, setData] = useState(rest);

    const {
        token: {colorSuccessBg, colorSuccess, colorWarningBg, colorWarning},
    } = theme.useToken();
    const handleRefresh = useCallback(() => {
        setStatusLoading(true);
        const dataData = data.name;
        updateInvoice(data.id).then((newInvoice) => {
            const updatedInvoice = newInvoice.data;
            setData({
                ...updatedInvoice,
                id: updatedInvoice.id.toString(),
                name: dataData,
                total: updatedInvoice.amount.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}),
                isChecked: updatedInvoice.status === 'PAID',
                type: "invoice"
            });
            messageApi.success("Fatura atualizada com sucesso");
        }).catch((e) => {
            console.error(e);
            messageApi.error("Falha ao atualizar fatura");
        }).finally(() => setStatusLoading(false));
    }, [data.id, data.name, messageApi]);


    return (
        <Card
            style={{width: "100%"}}
            actions={[
                <Button icon={<FilePdfOutlined/>} onClick={() => setViewing(data.id)}/>,
                // [
                //     <Button icon={<FilePdfOutlined/>}
                //             onClick={() => setViewing(data.id)}>
                //         PDF
                //     </Button>,
                //     ...[data.isChecked ? undefined : <Popconfirm
                //         description={"Você tem certeza que deseja confirmar o pagamento?"}
                //         cancelText={"Cancelar"}
                //         okText={"Sim"}
                //         onConfirm={() => setInvoiceStatus('PAID')}
                //         title={'Tem certeza?'}>
                //         <Button icon={<CheckCircleFilled/>} loading={statusLoading} disabled={statusLoading}>Pagar</Button>
                //     </Popconfirm>]
                //
                // ].filter(item => item)
                <Button icon={<ReloadOutlined/>} onClick={handleRefresh} loading={statusLoading}/>,
            ]}
            title={data.name}
        >
            <Card.Meta
                title={data.total?.toLocaleString()}
                avatar={<Avatar size={'small'} style={{
                    backgroundColor: data.isChecked ? colorSuccessBg : colorWarningBg,
                    color: data.isChecked ? colorSuccess : colorWarning
                }} icon={data.isChecked ? <CheckOutlined/> : <WarningOutlined/>}/>}
            />
        </Card>
    );
}

function ChangeRate({changePeriod}) {
    const {
        token: {colorSuccess, colorError},
    } = theme.useToken();

    if (changePeriod > 0)
        return (
            <Typography.Text style={{color: colorSuccess}}><CaretUpOutlined/> {
                (changePeriod * 100).toFixed(1).toLocaleString('pt-BR')
            }%</Typography.Text>
        );

    if (changePeriod < 0)
        return (
            <Typography.Text style={{color: colorError}}><CaretDownOutlined/> {
                Math.abs(changePeriod * 100).toFixed(1).toLocaleString('pt-BR')
            }%</Typography.Text>
        )

    return (
        <Typography.Text><LineOutlined/> {
            (changePeriod * 100).toFixed(1).toLocaleString('pt-BR')
        }%</Typography.Text>
    )
}

const translations = {
    'FLOW': "Fluxo",
    'MESSAGE': "Mensagem",
    'GENERATIVE': "Generativo",
    'SESSION': "Sessão",
    "OPEN": "Aberta",
    "PAID": "Paga",
    "OVERDUE": "Vencida"
}

const invoiceStatusColors = {
    "OPEN": "warning",
    "PAID": "success",
    "OVERDUE": "red"
}
const invoiceStatusIcons = {
    "OPEN": <WarningOutlined/>,
    "PAID": <CheckOutlined/>,
    "OVERDUE": <CloseOutlined/>
}

function InvoiceView({id}) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const messageApi = useContext(MessageApiContext);

    const treatFreeMessages = useCallback((services = []) => {
        let paidServices = {};
        let freeServices = []
        for (let service of services) {
            if (service.price <= 0) {
                freeServices.push(service);
                continue;
            }

            if (!paidServices[service.serviceType])
                paidServices[service.serviceType] = {}

            if (!paidServices[service.serviceType][service.serviceID])
                paidServices[service.serviceType][service.serviceID] = {}

            paidServices[service.serviceType][service.serviceID][service.countType] = service;
        }

        let result = []
        // This code presumes all services will have some free messages/services
        for (let freeService of freeServices) {
            if (!paidServices[freeService.serviceType] ||
                !paidServices[freeService.serviceType][freeService.serviceID] ||
                !paidServices[freeService.serviceType][freeService.serviceID][freeService.countType]
            ) {
                result.push({...freeService, freeQuantity: freeService.quantity, quantity: 0});
                continue;
            }


            let paidService = paidServices[freeService.serviceType][freeService.serviceID][freeService.countType];
            if (paidService) {
                result.push({...paidService, freeQuantity: freeService.quantity})
                continue
            }

            result.push({...freeService, freeQuantity: freeService.quantity, quantity: 0});
        }
        for(let service of services) {
            if(service.price > 0 && service.countType === "SESSION") {
                result.push({...service})
            }
        }
        return result;
    }, [])

    useEffect(() => {
        if (!id) return;

        setLoading(true);
        getInvoice(id).then(({data}) => {
            setData({...data, services: treatFreeMessages(data.services)});
        }).catch((err) => {
            messageApi.error('Erro ao carregar fatura');
            console.error(err);
        }).finally(() => setLoading(false));
    }, [id]);

    const name = useMemo(() => `Fatura de ${months[new Date(data?.startDate).getMonth() + 1]} - ${new Date(data?.startDate).getFullYear()}`, [data?.startDate])

    if (loading)
        return <Skeleton loading={loading}/>;

    return (
        <div>
            <Space direction={'vertical'}>
                <Typography.Title level={5}>
                    {name}
                </Typography.Title>
                <Tag color={invoiceStatusColors[data.status]} icon={invoiceStatusIcons[data.status]}>
                    {translations[data.status]}
                </Tag>
            </Space>
            <br/>
            <br/>
            <Space direction={'vertical'}>

                <Table dataSource={data?.services?.sort((a, b) => a?.serviceID - b?.serviceID)}
                       loading={loading}
                       scroll={{
                           y: 450,
                           x: '100%'
                       }}
                       pagination={false}
                       summary={(pageData) => {
                           let totalQuantity = 0;
                           let totalFreeQuantity = 0;
                           pageData.forEach(({quantity, freeQuantity}) => {
                               totalQuantity += quantity;
                               totalFreeQuantity += freeQuantity
                           });
                           return (
                               <>
                                   <Table.Summary.Row>
                                       <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                       <Table.Summary.Cell index={1}/>
                                       <Table.Summary.Cell index={2}/>
                                       <Table.Summary.Cell index={3}/>
                                       <Table.Summary.Cell index={4}>
                                           <Typography.Text>{totalQuantity}</Typography.Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={5}>
                                           <Typography.Text>{totalFreeQuantity}</Typography.Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={6}/>
                                       <Table.Summary.Cell index={7}>
                                           {data?.amount?.toLocaleString('pt-BR', {
                                               style: 'currency',
                                               currency: 'BRL'
                                           })}
                                       </Table.Summary.Cell>
                                   </Table.Summary.Row>
                               </>
                           );
                       }}
                >
                    <Table.Column
                        title={'ID'}
                        dataIndex={'serviceID'}
                        key={'serviceID'}
                    />
                    <Table.Column
                        title={'Tipo'}
                        dataIndex={'countType'}
                        key={'countType'}
                        render={(item) => translations[item]}
                    />
                    <Table.Column
                        title={'Tipo de serviço'}
                        dataIndex={'serviceType'}
                        key={'serviceType'}
                        render={(item) => translations[item]}
                    />
                    <Table.Column
                        title={'Serviço'}
                        dataIndex={'serviceName'}
                        key={'serviceName'}
                        ellipsis
                        render={(serviceName, {serviceType, serviceID}) =>
                            <Link target={'_blank'}
                                  to={serviceType === 'FLOW' ? `/chatbots/${serviceID}/versions` : `/assistants/${serviceID}`}>
                                <Tooltip title={serviceName}>
                                <Button disabled={!serviceName} icon={<LinkOutlined/>} type={'link'}
                                        style={{userSelect: 'all'}}>
                                    {serviceName ?? 'Deletado'}
                                </Button>
                                </Tooltip>
                            </Link>}
                    />
                    <Table.Column
                        title={'Qtd.'}
                        dataIndex={'quantity'}
                        key={'quantity'}
                        render={(item) => item?.toLocaleString('pt-BR')}
                        sorter={(a, b) => a.quantity - b.quantity}
                    />
                    <Table.Column
                        title={'Qtd. Grátis'}
                        dataIndex={'freeQuantity'}
                        key={'freeQuantity'}
                        render={(item) => item?.toLocaleString('pt-BR')}
                        sorter={(a, b) => a.freeQuantity - b.freeQuantity}
                    />
                    <Table.Column
                        title={'Preço'}
                        dataIndex={'price'}
                        sorter={(a, b) => a.price - b.price}
                        key={'price'}
                        render={(item) => item?.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            maximumFractionDigits: 4,
                        })}
                    />

                    <Table.Column
                        title={'Total'}
                        dataIndex={'amount'}
                        key={'amount'}
                        sorter={(a, b) => a.amount - b.amount}
                        render={(item) => item.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    />
                </Table>
                <CSVLink
                    filename={name.replaceAll(" ", "_")}
                    data={data?.services}>
                    <Button icon={<DownloadOutlined/>}>
                        Baixar
                    </Button>
                </CSVLink>
            </Space>
        </div>
    )
}
