import AdminLayout from "../../components/admin-layout";
import {
    Card,
    Form,
    message,
    Row,
    Space,
    DatePicker,
    Button,
    Typography, Tag, Alert, Modal, Select
} from "antd";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {getCompanies, getMy} from "../../services/companies";
import {getDashboard} from "../../services/dashboard";
import UserContext from "../../context/users";
import dayjs from 'dayjs';
import {
    SwapOutlined,
} from "@ant-design/icons";
import {InputSearch} from "../../components/input-search";
import {getChatBots} from "../../services/chatbot";
import {AdminRole} from "../../constants";
import {insightFromDashboardData} from "../../services/gpt";
import {DashboardGraphs} from "../../components/dashboard-graphs";
import MessageApiContext from "../../context/message-api";
import {getAssistants} from "../../services/assistant";

const {RangePicker} = DatePicker;
const defaultDate = [
    dayjs().subtract(30, 'day'),
    dayjs(),
]

export default function Dashboard() {
    const [user] = useContext(UserContext);
    const [company, setCompany] = useState({});
    const messageApi = useContext(MessageApiContext);
    const [data, setData] = useState({});
    const [insight, setInsight] = useState('');
    const [loading, setLoading] = useState(true);
    const [changePlanOpen, setChangePlanOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const defaultFilters = {
        company: company.id,
        startDate: defaultDate[0].toISOString(),
        endDate: defaultDate[1].toISOString(),
        service: localStorage.getItem("dashboardService") ?? "CHATBOT"
    };

    // useEffect(() => {
    //     if (!data)
    //         return;
    //
    //     const tm = setTimeout(() => {
    //         insightFromDashboardData(data)
    //             .then(({data: {insight}}) => setInsight(insight))
    //             .catch(console.error)
    //     }, 4 * 1000)
    //
    //     return () => clearTimeout(tm)
    // }, [data])

    const updateDashboard = useCallback((filters) => {
        setLoading(true);
        if (!filters?.company)
            filters.company = company.id;

        filters.startDate = filters.startDate.substring(0, 10) + 'T00:00:00Z'
        filters.endDate = filters.endDate.substring(0, 10) + "T23:59:59Z"

        getDashboard(filters).then(({data}) => setData(data)).catch(e => {
            console.error(e);
            messageApi.error("Falha ao carregar dashboard");
        }).finally(() => setLoading(false));
    }, [company])

    const handleSelectChange = useCallback((field, value) => {
        if (!value) return setFilters(old => {
            delete old[field];
            return {...old};
        })

        setFilters(old => ({...old, [field]: value}))
    }, [filters])

    useEffect(() => {
        if (Object.values(company ?? {})?.length < 1 || Object.values(user ?? {})?.length < 1)
            return;

        localStorage.setItem("dashboardService", filters?.service)
        setLoading(true);
        const timeout = setTimeout(() => {
            updateDashboard(filters)
        }, 300)

        return () => clearTimeout(timeout);
    }, [company, user, filters])

    useEffect(() => {
        if (Object.values(user ?? {}).length < 1)
            return;

        getMy().then(({data}) => {
            setCompany(data)
            defaultFilters.company = data.id;
            setFilters(old => ({...old, ...defaultFilters}))
        }).catch((e) => {
            console.error(e);
            messageApi.error("Falha ao carregar compania");
        })
    }, [user])

    const loadChatbots = useCallback((filterSent) => {
        if (filters.company)
            filterSent['companyID'] = filters.company;

        if (user.role !== AdminRole) {
            filterSent['companyID'] = user.companyID;
        }

        return getChatBots({...filterSent})
    }, [user, filters])

    const loadAssistants = useCallback((filterSent) => {
        if (filters.company)
            filterSent['companyID'] = filters.company;

        if (user.role !== AdminRole) {
            filterSent['companyID'] = user.companyID;
        }

        return getAssistants({...filterSent})
    }, [user, filters])

    const searchCallback = filters?.service === 'CHATBOT' ? loadChatbots : loadAssistants;

    return (
        <>
            <AdminLayout noPadding withBackground={false}>
                <Space style={{width: '100%'}} direction={'vertical'}>
                    <Space direction={'horizontal'} align={'center'} size={'small'}>
                        {user.role === AdminRole ? <Form.Item noStyle>
                                {company?.name &&
                                    <InputSearch
                                        bordered={false} size={'large'} popupMatchSelectWidth={false}
                                        onChange={(data) => handleSelectChange('company', data)}
                                        getCallback={getCompanies} defaultValue={company?.name}/>}
                            </Form.Item> :
                            <Typography.Title level={3} style={{margin: 0}}>{company.name}</Typography.Title>}
                        <Modal title={'Mudar plano'} open={changePlanOpen} onCancel={() => setChangePlanOpen(false)}
                               onOk={() => setChangePlanOpen(false)} cancelText={'Cancelar'}>
                            <Space direction={'vertical'}>
                                <Typography.Text>Mudança automática indisponível, por favor entre em contato para
                                    alterar de
                                    plano.</Typography.Text>
                                <Typography.Text type={'secondary'}>verbeux@verbeux.com.br</Typography.Text>
                            </Space>
                        </Modal>
                        {company?.plan && <Tag style={{cursor: 'pointer'}}
                                               onClick={() => setChangePlanOpen(true)}
                                               icon={<SwapOutlined/>}>{company.plan.name.toUpperCase()}</Tag>}
                    </Space>
                    {/*{insight?.length > 0 && <Alert type={'info'} message={insight}/>}*/}
                    <Card>
                        <Row justify={'space-between'}>
                            <Space>
                                <Form.Item style={{marginBottom: 0}} label={'Assistente'}>
                                    <InputSearch
                                        onClear={() => handleSelectChange('company', '')}
                                        onChange={(data) => handleSelectChange('serviceID', data)}
                                        allowClear getCallback={searchCallback}/>
                                </Form.Item>
                                <Form.Item style={{marginBottom: 0}} label={'Data'}>
                                    <RangePicker defaultValue={defaultDate}
                                                 onChange={(([start, end]) => {
                                                     handleSelectChange('startDate', start.toISOString())
                                                     handleSelectChange('endDate', end.toISOString())
                                                 })}
                                                 placeholder={['Início', 'Fim']}/>
                                </Form.Item>
                            </Space>
                            <Space>
                                <Select defaultValue={'CHATBOT'}
                                        variant={'filled'}
                                        value={filters?.service ?? "CHATBOT"}
                                        popupMatchSelectWidth={false}
                                        onChange={(v) => handleSelectChange('service', v)}>
                                    <Select.Option value={'CHATBOT'}>Fluxo</Select.Option>
                                    <Select.Option value={'ASSISTANT'}>Generativo</Select.Option>
                                </Select>
                                {/*<Button onClick={() => updateDashboard(filters)} icon={<ReloadOutlined/>}*/}
                                {/*        type={'primary'}>Atualizar</Button>*/}
                            </Space>
                        </Row>
                    </Card>
                    <DashboardGraphs data={data} loading={loading}/>
                </Space>
            </AdminLayout>
        </>
    )

}
