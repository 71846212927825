import {
    Button,
    Form,
    Input,
    InputNumber,
    Select,
    Modal,
    Row,
    Divider,
} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import React, {useCallback, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import MessageApiContext from "../../../context/message-api";

export default function PlanCreate({isOpen, setIsOpen, createPlanCallback, columns, refresh}) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const messageApi = useContext(MessageApiContext);

    const save = useCallback((values) => {
        setSaving(true);
        createPlanCallback(values)
            .then(() => {
                setIsOpen(false);
                messageApi.success("Plano criado com sucesso!");
                refresh();
            })
            .catch((e) => {
                console.error(e);
                messageApi.error("Falha ao salvar informações da empresa");
            }).finally(() => setSaving(false));
    }, []);

    return (
        <>
            <Modal
                title="Criar plano"
                centered
                visible={isOpen}
                onOk={() => setIsOpen(false)}
                onCancel={() => setIsOpen(false)}
                footer={null}
                width={"25%"}
            >
                <>
                    <Form
                        name="basic"
                        style={{
                            maxWidth: 600,
                            paddingTop: "30px",
                        }}
                        layout={"vertical"}
                        autoComplete="off"
                        form={form}
                        onFinish={save}
                    >
                        {columns.map(item => {
                            if (item.key === "id") return null;
                            return (
                                <Form.Item
                                    label={item.title}
                                    name={item.key}
                                    rules={[
                                        {
                                            required: true,
                                            message: `Digite um ${item.title} para o plano`,
                                        },
                                    ]}
                                >
                                    {item.isNumber && !item.options && (
                                        <InputNumber/>
                                    )}
                                    {!item.isNumber && !item.options && (
                                        <Input/>
                                    )}
                                    {item.options && (
                                        <Select
                                            options={item.options}
                                        />
                                    )}
                                </Form.Item>
                            );
                        })}
                        {/*<Form.Item*/}
                        {/*    label="Nome"*/}
                        {/*    name="name"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*            message: "Digite um nome para o plano",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <Input placeholder="Plano Pro"/>*/}
                        {/*</Form.Item>*/}
                        {/*<Form.Item*/}
                        {/*    label="Tipo"*/}
                        {/*    name="type"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*            message: "Selecione um tipo para o plano.",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <Select*/}
                        {/*        placeholder="Por Uso"*/}
                        {/*        options={[*/}
                        {/*            {value: "pack", label: "Pacote"},*/}
                        {/*            {value: "pay_per_use", label: "Por uso"},*/}
                        {/*        ]}*/}
                        {/*    />*/}
                        {/*</Form.Item>*/}
                        {/*<Form.Item*/}
                        {/*    label="Preço"*/}
                        {/*    name="price"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*            message: "Insira um preço para o plano.",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <InputNumber*/}
                        {/*        min={0.0001}*/}
                        {/*        placeholder="0.0001"*/}
                        {/*        step={0.0001}*/}
                        {/*        style={{width: "100%"}}*/}
                        {/*    />*/}
                        {/*</Form.Item>*/}
                        {/*<Form.Item*/}
                        {/*    label="Dia de pagamento"*/}
                        {/*    name="payDay"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*            message: "Digite um dia de pagamento para o plano.",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <Select*/}
                        {/*        placeholder="15"*/}
                        {/*        min={1}*/}
                        {/*        max={28}*/}
                        {/*        options={Array.from({length: 28}, (_, i) => ({*/}
                        {/*            value: i + 1,*/}
                        {/*            label: (i + 1).toString(),*/}
                        {/*        }))}*/}
                        {/*    />*/}
                        {/*</Form.Item>*/}

                        {/*<Form.Item*/}
                        {/*    label="Máximo de chatbots"*/}
                        {/*    name="maxChatbots"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*            message: "Insira uma quantidade máxima de chatbots.",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <InputNumber placeholder="10" style={{width: "100%"}} min={1}/>*/}
                        {/*</Form.Item>*/}

                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        justifyContent: "center",*/}
                        {/*        alignItems: "center",*/}
                        {/*        gap: "20px",*/}
                        {/*        flex: 1,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Máximo de requests"*/}
                        {/*        name="maxRequests"*/}
                        {/*        rules={[*/}
                        {/*            {*/}
                        {/*                required: true,*/}
                        {/*                message: "Insira um valor máximo de requisições.",*/}
                        {/*            },*/}
                        {/*        ]}*/}
                        {/*    >*/}
                        {/*        <InputNumber placeholder="500000" style={{width: "100%"}} min={1}/>*/}
                        {/*    </Form.Item>*/}

                        {/*    <Form.Item*/}
                        {/*        label="Requests para cobrar"*/}
                        {/*        name="minRequestsToBeBillable"*/}
                        {/*        rules={[*/}
                        {/*            {*/}
                        {/*                required: true,*/}
                        {/*                message: "Insira um valor mínimo de requisições.",*/}
                        {/*            },*/}
                        {/*        ]}*/}
                        {/*    >*/}
                        {/*        <InputNumber placeholder="400000" style={{width: "100%"}} min={1}/>*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}
                        <Form.Item>
                            <Divider/>
                            <Row justify={"end"} style={{gap: "8px"}}>
                                <Button key="cancel" onClick={() => setIsOpen(false)}>
                                    Cancelar
                                </Button>
                                <Button
                                    loading={saving}
                                    icon={<SaveOutlined/>}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Criar
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </>
    );
}
