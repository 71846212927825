import React, { createContext, useContext, useState } from 'react';
import { useNodesState } from 'reactflow';

// Create the context
const NodesContext = createContext();

// Create a provider component
export const NodesProvider = ({ children }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);

  return (
    <NodesContext.Provider value={{nodes, setNodes, onNodesChange}}>
      {children}
    </NodesContext.Provider>
  );
};

// Create a custom hook to use the context
export const useNodesContext = () => {
  return useContext(NodesContext);
};