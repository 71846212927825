import Fetch from "./index";
export const getAssistantPlan = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return Fetch("/assistant-plan/" + id)
}

export const getAssistantPlans = async (filters) => {
    const filter = new URLSearchParams(filters).toString();

    return Fetch(`/assistant-plan?${filter}`)
}

export const updateAssistantPlan = async (id, data) => {
    return Fetch(
        '/assistant-plan/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createAssistantPlan = async (data) => {
    return Fetch(
        '/assistant-plan/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}
