import styled from "styled-components";
import {Button, Card} from "antd";

export const Container = styled.div`
    padding: .6rem .6rem 0 .6rem;
    height: 50vh;
    max-height: 50vh;
    width: 25vw;
    min-width: 320px;
    max-width: 40vw;
    overflow-y: auto;
    overflow-x: hidden;
    background: ${props => props.bg};
    border-radius: ${props => props.borderRadius}px;
    box-shadow: ${props => props.boxShadow};
`;

export const CardButton = styled(Button)`
  cursor: pointer;
  height: auto;
  width: 125px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
`;
