import Fetch from "./index";

export const getAssistant = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return Fetch("/assistant/" + id)
}

export const getAssistants = async (filters) => {
    const filter = new URLSearchParams(filters).toString();
    return Fetch(`/assistant?${filter}`)
}

export const updateAssistant = async (id, data) => {
    return Fetch(
        '/assistant/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const deleteAssistant = async (id) => {
    return Fetch(
        '/assistant/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createAssistant = async (data) => {
    data.isActive = false;

    return Fetch(
        '/assistant/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const getFiles = async ({assistantID, ...filters}) => {
    const filter = new URLSearchParams(filters).toString();
    return Fetch(`/assistant/${assistantID}/file/?${filter}`)
}

export const getFile = async ({assistantID, fileId}) => {
    if (!assistantID || !fileId)
        return;

    return Fetch(`/assistant/${assistantID}/file/${fileId}`)
}

export const deleteFile = async ({assistantID, fileId}) => {
    if (!assistantID || !fileId)
        return Promise.reject("cannot delete without necessary params");

    return Fetch(
        `/assistant/${assistantID}/file/${fileId}`,
        {method: 'DELETE'},
    )
}

export const updateFile = async ({assistantID, fileId, data}) => {
    if (!assistantID || !fileId || !data)
        return Promise.reject("cannot update without necessary params");

    return Fetch(
        `/assistant/${assistantID}/file/${fileId}`,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createScrapWebsite = async ({assistantID, data}) => {
    return Fetch(
        `/assistant/${assistantID}/file/scraping`,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}
