import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useMemo, useState} from "react";
import ChatBotDataContext from "../../context/chatbot-data";
import ActionsContext from "../../context/actions";
import {getSnapshotVersionStatus} from "../../services/snapshot_version";
import {train} from "../../services/train";
import {Button, Col, Dropdown, Form, Menu, message, Row, Space, Switch, theme, Tooltip} from "antd";
import {PlayCircleOutlined, SaveOutlined, WarningOutlined} from "@ant-design/icons";
import UserContext from "../../context/users";
import TextArea from "antd/lib/input/TextArea";
import { useComponentData } from "../provider";
import MessageApiContext from "../../context/message-api";

export default function Navbar({isComponent}) {
    const [data, setData] = useContext(ChatBotDataContext);

    const [user] = useContext(UserContext);
    const [isTraining, setIsTraining] = useContext(ActionsContext)['train'];
    const isUpdating = useContext(ActionsContext)['isUpdating'];
    const update = useContext(ActionsContext)['update'];

    const {updateComponentState} = useComponentData()
    const [isNotSaved] = useContext(ActionsContext)['notSaved'];
    const [isNotSavedEntitiesIntents] = useContext(ActionsContext)['notSavedEntitiesIntents'];
    const location = useLocation();
    // const [iframe] = useContext(ActionsContext)['iframeLockEditor'];
    const messageApi = useContext(MessageApiContext);
    const [autoSave, setAutoSave] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const canOpenPopover = true;

    const menuItems = useMemo(() => {
        let res = [
            {
                key: "editor",
                label: <Link to={''}>Fluxo</Link>
            },
            {
                key: "dashboard",
                label: <Link to={'dashboard'}>Painel</Link>
            },
            {
                key: "intents",
                label: <Link to={'intents'}>Intenções</Link>
            },
            {
                key: "entities",
                label: <Link to={'entities'}>Entidades</Link>
            },
            // {
            //     key: "conversations",
            //     label: <Link to={'conversations'}>Conversas</Link>
            // },
            {
                key: "logs",
                label: <Link to={'logs'}>Histórico</Link>
            },
        ];

        if (data?.logs?.length > 0)
            res.push({
                key: "warnings",
                label: <Link to={'warnings'}>Avisos</Link>,
                danger: true,
            });

        return res;
    }, [data?.logs])

    const handleButtonClick = () => {
        if (canOpenPopover) {
            setIsVisible(true);
        }
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    useEffect(() => {
        setAutoSave(localStorage.getItem("autoSave") === 'true')
    }, [])

    useEffect(() => {
        localStorage.setItem("autoSave", autoSave);
    }, [autoSave])

    useEffect(() => {
        if (!data.id)
            return setIsTraining(false);

        if (data.status !== "DONE" && data.status !== 'EDITING' && data.status !== "TRAIN_ERROR")
            setIsTraining(true);
    }, [data])

    function updateStatus() {
        if (isComponent) return setIsTraining(false);
        getSnapshotVersionStatus(data.id).then(({data}) => {
            if (data.status === 'DONE') {
                setIsTraining(false);
                messageApi.success("Treinado com sucesso")
            }

            if (data.status === "TRAIN_ERROR") {
                setIsTraining(false);
                messageApi.error("Falha ao treinar modelo")
            }

            setData(old => {
                if (old.status === data.status)
                    return old;

                return {...old, status: data.status};
            });
        })
    }

    useEffect(() => {
        if (!data?.id || !isTraining)
            return;

        let trainInterval = setInterval(() => updateStatus(), 2000)
        return () => clearInterval(trainInterval);
    }, [isTraining])

    useEffect(() => {
        let interval;
        if (autoSave && isNotSaved && !isTraining) {
            interval = setInterval(() => {
                update()
            }, 1000 * 15);
        }

        return () => clearInterval(interval)

    }, [autoSave, isNotSaved, isTraining])

    function requestTrain() {
        if (!isTraining && data?.id) {
            setIsTraining(true);
            train(data, inputValue).then(() => {
                setInputValue("")
                setIsTraining(true)
            }).catch((err) => {
                console.error(err)
                setIsTraining(false);
                messageApi.error("Falha ao requisitar treino")
            })
        }
    }

    const renderPopoverContent = () => {
        return (
            <div>
                <TextArea value={inputValue} rows={4} onChange={handleInputChange} disabled={isTraining}
                          placeholder="Insira sua pipeline Rasa"/>
                <Button style={{marginTop: '10px', width: "100%"}} onClick={() => {
                    requestTrain();
                    handleClose()
                }}>Treinar</Button>
            </div>
        );
    };

    const pathname = location?.pathname?.split('/');
    const lastPath = pathname[pathname.length - 1]
    const penultimatePath = pathname.length > 1 && pathname[pathname.length - 2]

    const {
        token: {colorBorderSecondary, colorWarningBorder, colorWarningText, colorTextPlaceholder},
    } = theme.useToken();
    const selectedKeys = [penultimatePath, lastPath]

    const trainButtonProps = useMemo(() => {
        const basicsProps = {
            icon: <PlayCircleOutlined/>,
            onClick: requestTrain,
            message: 'Treine IA com entidades e intenções',
        }

        if (isTraining) {
            basicsProps.loading = true;
            basicsProps.disabled = true;
        }

        if (isNotSavedEntitiesIntents) {
            basicsProps.type = 'primary';
            basicsProps.message = 'Treinamento necessário';
        }

        const panics = data?.logs?.filter(({level}) => level === 'PANIC');
        if (panics?.length > 0)
            return {
                ...basicsProps,
                message: 'Antes de treinar verifique os avisos em vermelho.',
                danger: true,
                onClick: () => navigate('warnings'),
                icon: <WarningOutlined/>,
                style: {color: colorWarningText, borderColor: colorWarningBorder}
            }

        if (data?.status === 'TRAIN_ERROR')
            return {
                ...basicsProps,
                danger: true,
                type: "dashed",
                message: "Último treinamento mal sucedido, tente novamente!"
            }

        return basicsProps

    }, [data, isTraining])

    const TrainButton = (props) => <Button {...props} {...trainButtonProps}>Treinar</Button>;

    const disableSaveButton = !isNotSaved || isTraining || isUpdating;
    return (
        <div>

            <Row justify={'space-between'} align={'center'}>
                <Col span={18}>
                    <Menu mode="horizontal"
                          defaultSelectedKeys={['flow']}
                          selectedKeys={selectedKeys}
                          items={menuItems}
                    />
                </Col>
                <Col span={6}>
                    <div style={{transform: 'translateY(-1px)', borderBottom: `1px solid ${colorBorderSecondary}`}}>
                        <Row justify={'end'}>
                            <Space style={{marginRight: '1rem'}} direction={'horizontal'}>
                                <Dropdown
                                    trigger={['hover']}
                                    menu={{
                                        items: [{
                                            key: "autosave", label:
                                                <Form>
                                                    <Form.Item help={'15 segs'} style={{padding: 0}}
                                                               label={'Salvar Auto'}>
                                                        <Switch checked={autoSave}
                                                                onClick={value => setAutoSave(value)}/>
                                                    </Form.Item>
                                                </Form>
                                        }]
                                    }}
                                    placement="bottomRight"
                                    arrow={{pointAtCenter: true}}
                                >
                                    <Button
                                        onClick={() => update()}
                                        loading={isUpdating}
                                        type={disableSaveButton ? 'default' : 'primary'}
                                        icon={<SaveOutlined/>}
                                    />
                                </Dropdown>
                                <Tooltip
                                    title={trainButtonProps.message}>
                                    <TrainButton/>
                                </Tooltip>
                                {/*<Popover trigger="click" content={renderPopoverContent} open={isVisible}*/}
                                {/*         onOpenChange={handleClose} title="Configurações">*/}
                                {/*    <TrainButton/>*/}
                                {/*</Popover>*/}
                            </Space>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
