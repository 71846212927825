import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useStore} from 'reactflow';
import {
    Container,
    CustomHandleContainer,
    CustomSourceHandle,
    CustomTargetHandleJumpContainer,
    JumpTypografy
} from "./styles";
import ConditionSelector from "../condition-selector";
import ChatBotDataContext from "../../context/chatbot-data";
import ActionsContext from "../../context/actions";
import {
    Button,
    Col,
    Divider,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Tag,
    theme,
    Tooltip,
    Typography
} from "antd";
import {
    ArrowsAltOutlined,
    CloudOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    SendOutlined
} from "@ant-design/icons";
import Fetch from "../../services";
import {Locker} from "../locker";
import {newObjectID} from "../../utils";
import FlowContext from "../../context/flow";


const connectionNodeIdSelector = (state) => state.connectionNodeId;
export default memo(({data, isConnectable}) => {
    const connection = useStore(connectionNodeIdSelector);
    const isConnecting = !!connection;
    const [hovered, setHovered] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [fullData, setFullData] = useContext(ChatBotDataContext);
    const {flow} = useContext(FlowContext);
    const [isIframe] = useContext(ActionsContext)['iframe'];
    const [modalOpen, setModalOpen] = useState(false);
    const nodeData = useMemo(() => fullData.nodeGroups[data?.id], [fullData.nodeGroups[data.id]]);
    const conditionSplit = nodeData?.condition?.split(" ");
    const children = useMemo(()=> data.nodes)
    const isActive = useMemo(() => !!flow?.find(nodeID => nodeID === nodeData?.id), [flow, nodeData?.id]);
    const isLast = useMemo(() => flow[flow?.length - 1] === nodeData?.id, [flow, nodeData?.id]);
    const isFullLocked = nodeData?.lock?.title && nodeData?.lock?.condition && nodeData?.lock?.response && nodeData?.lock?.contextActions && nodeData?.lock?.move && nodeData?.lock?.delete;
    const nodeRef = useContext(ActionsContext)['nodeRef']

    const setTitle = useCallback((text) => {
        setFullData(old => {
            old.nodeGroups[nodeData.id].title = text;
            return {...old, nodes: old.nodes};
        })
    }, [nodeData?.id, setFullData])

    const deleteNode = useCallback((nodeData) => {
        setFullData(oldData => {
          const newData = { ...oldData };
          let position = newData.nodeGroups[nodeData.id].position
          for(let child of newData.nodeGroups[nodeData.id].nodes){
            newData.nodes[child].position.x = oldData.nodes[child].position.x + position.x
            newData.nodes[child].position.y = oldData.nodes[child].position.y + position.y
          }
          delete newData.nodeGroups[nodeData.id];
          return newData;
        });
      }, []);

    const renderHandlers = useCallback(()=> {
        return children.map((child)=> {
            return(
                <CustomSourceHandle
                borderRadius={borderRadius}
                bgColor={colorPrimaryBg}
                borderColor={colorPrimary}
                type="source"
                position="bottom"
                id={'c'}

            />
            )
        })
    },[])

    const {
        token: {colorPrimary, colorPrimaryBg, colorBorder, borderRadius, colorBgElevated},
    } = theme.useToken();

    useEffect(() => {
        if (hovered)
            return setShowButtons(true);

        const tm = setTimeout(() => {
            setShowButtons(false);
        }, 200)

        return () => clearTimeout(tm);
    }, [hovered])
    const renderChild = useCallback(() => {
        if(children && fullData){
        return children.map((child) => {

            return fullData?.nodes[child]?.title ? (<Tag style={{width:'92px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{fullData?.nodes[child]?.title}</Tag> ): null
        })}
    }, [children])
    const maximize = useCallback(()=> {
        setFullData(old => {
          old.nodeGroups[data.id].minimized = false;
          return {...old, nodes: old.nodes};
      })
      },[])

    return (<div>
        {nodeData && <>
            <Container primary={colorPrimary}
                       className={'custom-drag-handle'}
                       colorBgContainer={colorBgElevated}
                       borderRadius={borderRadius}
                       primaryBg={colorPrimaryBg}
                       ref={nodeRef}
                       border={colorBorder}
                       isLocked={!(!isIframe || (isIframe && !isFullLocked))}
                       isLast={isLast} active={isActive}>
                <Input disabled={isIframe && nodeData?.lock?.title}
                       bordered={false}
                       style={{width: '100%'}}
                       placeholder={'Digite...'} value={nodeData.title}
                       onChange={({target}) => setTitle(target.value)}/>
                <div style={{margin: '8px'}}>
                    <Row >
                        <Row gutter={[8,8]} wrap={true} style={{maxWidth:200}}>
                           {
                            renderChild()
                           }
                        </Row>
                        <Locker id={nodeData?.id}/>
                    </Row>
                </div>
            </Container>

            <CustomHandle
                type="target"
                position="top"
                id={'a'}
                isConnectable={isConnectable}
            />
            <CustomSourceHandle
                borderRadius={borderRadius}
                bgColor={colorPrimaryBg}
                borderColor={colorPrimary}
                type="source"
                position="bottom"
                id={'c'}

            />
        </>}
    </div>);
});
function CustomHandle(props) {
    const {
        token: {colorPrimaryBg, borderRadius, colorPrimaryBgHover},
    } = theme.useToken();

    return (
        <CustomHandleContainer bgColorHover={colorPrimaryBgHover} borderRadius={borderRadius}
                               bgColor={colorPrimaryBg} {...props}>
            {props.isConnecting && <Typography.Title level={3} style={{padding: 0, margin: 0}}>FLUXO</Typography.Title>}
        </CustomHandleContainer>
    )
}
