import {
  RobotOutlined,
  HomeOutlined,
  BankOutlined,
  UserOutlined,
  KeyOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Drawer, Flex, Layout, List, Menu, Typography, theme } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import React, { useCallback, useEffect, useState } from "react";
import { AdminRole, EditorRole, OwnerRole } from "../../constants";
import { refresh } from "../../services/auth";
import Loading from "../loading";
import ComponentCard from "../component-card";
import { newObjectID } from "../../utils";
import Search from "antd/es/input/Search";
import { getComponents } from "../../services/components";

const { Sider } = Layout;

export default function ComponentContainer({
  isOpen,
  setIsOpen,
  reactFlowInstance,
}) {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isFail, setIsFail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const locationSplit = location?.pathname?.split("/");
  const [components, setComponents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  
  const handleChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getComponents(itemsPerPage, currentPage, true).then((res) => {
      setComponents(res.data);
    });
  }, []);
  function createAuthComponent() {
    const ids = [newObjectID(), newObjectID(), newObjectID()];
    const edgesIds = [newObjectID(), newObjectID(), newObjectID()];

    const result = {
      nodes: {
        [ids[0]]: {
          id: ids[0],
          title: "auth",
          position: {
            x: 150,
            y: 20,
          },
          condition: "",
          skipUserInput: false,
        },
        [ids[1]]: {
          id: ids[1],
          title: "false",
          position: {
            x: 300,
            y: 300,
          },
          condition: "",
          skipUserInput: false,
        },
        [ids[2]]: {
          id: ids[2],
          type: "node",
          title: "true",
          position: {
            x: 20,
            y: 300,
          },
          condition: "",
          skipUserInput: false,
        },
      },
      edges: {
        [ids[0]]: {
          [edgesIds[0]]: {
            id: edgesIds[0],
            source: ids[0],
            target: ids[1],
            type: "flow",
          },
          [edgesIds[1]]: {
            id: edgesIds[0],
            source: ids[0],
            target: ids[2],
            type: "flow",
          },
        },
        [ids[1]]: {
          [edgesIds[1]]: {
            id: edgesIds[1],
            source: ids[1],
            target: ids[0],
            type: "flow",
          },
        },
      },

      title: "Auth component",
    };
    return result;
  }

  
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  const list = components ? components.map((component) => {
    return {
      title: component.name,
      value: {...component.data},
      author: component.company,
  }}) : [];

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredList = list.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const {
    token: {
      colorBgContainer,
      colorPrimaryBg,
      colorPrimaryActive,
      borderRadius,
      colorTextPlaceholder,
      border,
      colorTextBase,
    },
  } = theme.useToken();
  return (
    <>

      <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      mask={false}
      style={{
        backgroundColor: colorBgContainer,
      }}
      >
        <Flex vertical align="center" gap={15}>
        <Typography.Title
          level={3}
          style={{ whiteSpace: "nowrap", width: "80%" }}
          >
          Componentes
        </Typography.Title>
        <Search placeholder="Search" onChange={handleSearch} style={{width: "80%"}} />
        <Flex
          vertical
          align="center"
          justify="center"
          style={{ width: "100%" }}
          >
          <List
            style={{ width: "80%" }}
            dataSource={filteredList}
            grid={{ gutter: 16, column: 1 }}
            renderItem={(item) => (
              <List.Item>
                <ComponentCard
                  className="dndnode output"
                  onDragStart={onDragStart}
                  draggable
                  data={item.data}
                  title={item.title}
                  author={item.author}
                  value={item.value}
                  />
              </List.Item>
            )}
            pagination={
              filteredList.length > itemsPerPage
              ? {
                pageSize: itemsPerPage,
                style: { alignSelf: "center" },
              }
              : false
            }
            />
        </Flex>
            </Flex>
      </Drawer>
    </>
  );
}
