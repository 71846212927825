import React from "react";
import {getChatbotPlans, updateChatbotPlan, createChatbotPlan} from "../../../services/chatbot-plan";
import ListPlans from "../List";
import {Tag} from "antd";

const defaultColumns = [
    {
        title: "Nome",
        dataIndex: "name",
        key: "name",
        editable: true,
    },
    {
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        render: (type) => (type === "pack" ? <Tag>PACOTE</Tag> : <Tag>POR USO</Tag>),
        editable: true,
        options: [
            {value: "pack", label: "Pacote"},
            {value: "pay_per_use", label: "Por uso"},
        ],
    },
    {
        title: "Preço",
        dataIndex: "price",
        key: "price",
        render: (price) =>
            price?.toLocaleString("pt-br", {
                minimumFractionDigits: 4,
                currency: "BRL",
                style: "currency",
            }),
        editable: true,
        isNumber: true,
    },
    {
        title: "Preço por mensagem",
        dataIndex: "pricePerMessage",
        key: "pricePerMessage",
        render: (price) =>
            price?.toLocaleString("pt-br", {
                minimumFractionDigits: 4,
                currency: "BRL",
                style: "currency",
            }),
        editable: true,
        isNumber: true,
    },
    {
        title: "Preço por sessão",
        dataIndex: "pricePerSession",
        key: "pricePerSession",
        render: (price) =>
            price?.toLocaleString("pt-br", {
                minimumFractionDigits: 4,
                currency: "BRL",
                style: "currency",
            }),
        editable: true,
        isNumber: true,
    },
    {
        title: "Máximo de chamadas api",
        dataIndex: "maxRequests",
        key: "maxRequests",
        render: (maxRequests) => maxRequests?.toLocaleString(),
        editable: true,
        isNumber: true,
    },
    {
        title: "Mínimo para cobrança",
        dataIndex: "minRequestsToBeBillable",
        key: "minRequestsToBeBillable",
        render: (minRequestsToBeBillable) => minRequestsToBeBillable?.toLocaleString(),
        editable: true,
        isNumber: true,
    },
    {
        title: "Máximo de chatbots",
        dataIndex: "maxChatbots",
        key: "maxChatbots",
        render: (maxChatbots) => maxChatbots?.toLocaleString(),
        editable: true,
        isNumber: true,
    },
    {
        title: "Máximo de histórico",
        dataIndex: "maxLogSize",
        key: "maxLogSize",
        render: (maxLogSize) => maxLogSize?.toLocaleString(),
        editable: true,
        isNumber: true,
    },
    {
        title: "Máximo de mensagens",
        dataIndex: "maxMessages",
        key: "maxMessages",
        render: (value) => value?.toLocaleString(),
        editable: true,
        isNumber: true,
    },
    {
        title: "Máximo de sessões",
        dataIndex: "maxSessions",
        key: "maxSessions",
        render: (value) => value?.toLocaleString(),
        editable: true,
        isNumber: true,
    }
];

export default function ChatbotPlans() {
    return (
        <ListPlans title={'Planos de Chatbot'}
                   createPlanCallback={createChatbotPlan}
                   updatePlanCallback={updateChatbotPlan}
                   getPlansCallback={getChatbotPlans}
                   columns={defaultColumns}/>
    )
}
