import React, {useCallback, useMemo} from "react";
import {updateGenerativeTrigger} from "../../../../../services/generative-triggers";
import TriggerForm from "../Form";

const TriggerEdit = (props) => {
    const update = useCallback((assistant, values) => {
        return updateGenerativeTrigger(props.initValues.id, assistant, values);
    }, [])

    const initValues = useMemo(() => {
        let values = props.initValues;

        if (values?.args?.parameters?.properties) {
            const newValues = []
            for (const name of Object.keys(values.args.parameters.properties)) {
                newValues.push({
                    name: name,
                    ...values.args.parameters.properties[name],
                    required: values?.args?.parameters?.required?.includes(name),
                    limitOptions: values?.args?.parameters?.properties[name]?.enum?.length > 0
                })
            }
            values.args.parameters.properties = newValues
        }

        return values;
    }, [props.initValues]);

    return <TriggerForm onFinish={update} okText={'Salvar'} {...props} initValues={initValues}/>
}

export default TriggerEdit;
