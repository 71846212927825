import React, {memo, useState, useContext, useEffect, useCallback, useRef} from "react";
import {NodeResizer} from "reactflow";
import ChatBotDataContext from "../../context/chatbot-data";
import {Button, Input, Popconfirm, Row, Space} from "antd";
import {DeleteOutlined, ShrinkOutlined} from "@ant-design/icons";
import {debounce} from 'lodash';

export default memo(({data}) => {
    const [, setFullData] = useContext(ChatBotDataContext);
    const [showButtons, setShowButtons] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [size, setSize] = useState({width: 500, height: 500}); // initial size
    const ref = useRef()
    const deleteNode = useCallback((nodeData) => {
        setFullData(oldData => {
            const newData = {...oldData};
            let position = newData.nodeGroups[nodeData.id].position
            for (let child of newData.nodeGroups[nodeData.id].nodes) {
                newData.nodes[child].position.x = oldData.nodes[child].position.x + position.x
                newData.nodes[child].position.y = oldData.nodes[child].position.y + position.y
            }
            delete newData.nodeGroups[nodeData.id];
            return newData;
        });
    }, []);
    const setTitle = useCallback((text) => {
        setFullData(old => {
            old.nodeGroups[data.id].title = text;
            return {...old, nodes: old.nodes};
        })
    }, [data?.id, setFullData])

    const handleResizeStop = useCallback(debounce((event, {width, height, x, y}) => {
        setSize({width, height});
        setFullData(oldData => {
            const newData = {...oldData};
            newData.nodeGroups[data.id].size = {width, height};
            newData.nodeGroups[data.id].position = {
                x: x,
                y: y
            }

            return newData;
        });
    }, 250), []);
    const minimize = useCallback(() => {
        setFullData(old => {
            return {...old, nodeGroups: {...old.nodeGroups, [data.id]: {...old.nodeGroups[data.id], minimized: !old.nodeGroups[data.id].minimized}}};
        })
    }, [])

    useEffect(() => {
        if (hovered)
            return setShowButtons(true);

        const tm = setTimeout(() => {
            setShowButtons(false);
        }, 200)

        return () => clearTimeout(tm);
    }, [hovered])

    return (
        <>
            {showButtons && <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <Row gutter={[8, 8]}
                     align={'center'}
                     style={{
                         paddingBottom: 12,
                         width: '100%',
                         transform: 'translateY(-115%)',
                     }} justify={'space-between'}>
                    {/*<Button type={'primary'} onClick={() => {*/}
                    {/*    minimize()*/}
                    {/*}} icon={<ShrinkOutlined/>}/>*/}
                    {/*<Input style={{textAlign: "center", backgroundColor: "transparent", width: '50%', border: 'none',}}*/}
                    {/*       value={data.title} onChange={({target}) => {*/}
                    {/*    setTitle(target.value)*/}
                    {/*}}/>*/}

                    {/*<Popconfirm*/}
                    {/*    title="Você tem certeza?"*/}
                    {/*    description="Essa ação vai deletar o Grupo, mas não os nós."*/}
                    {/*    onConfirm={() => deleteNode(data)}*/}
                    {/*    cancelText={'Cancelar'}*/}
                    {/*>*/}
                    {/*    <Button style={{marginRight: 0}} danger icon={<DeleteOutlined/>}/>*/}
                    {/*</Popconfirm>*/}
                </Row>
            </div>
            }

            <div
                
                style={{display: "flex", alignItems: "center", justifyContent: "center", width: '100%', height: '100%'}}
                ref={ref} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>

                <NodeResizer  onResize={handleResizeStop} handleStyle={{width: 12, height: 12}}
                             lineStyle={{border: '2px solid white'}} minHeight={0} minWidth={0} color={'white'}/>

            </div>
        </>
    );
});

