import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;
export const ActionContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const VersionTag = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: #dedede;
  border-width: 0 1px 0 0;

  p {
    font-size: 1.4em;
    font-weight: bold;
  }
`;

export const ActiveLabel = styled.span`
  color: green;
`;

export const Input = styled.input`
  border: 0;
  font-size: 1.4em;
  background-color: transparent;
  width: 200px;
  align-self: center;
  align-content: center;
`;

export const Title = styled.h1`
  font-size: 1.4em;
  padding: 0;
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
