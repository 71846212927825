import { useCallback, useContext } from 'react';
import { useNodesContext } from '../context/nodesProvider';
import ChatBotDataContext from '../context/chatbot-data';

const useGroup = () => {
    const [data, setData] = useContext(ChatBotDataContext);
    const {nodes, setNodes, onNodesChange} = useNodesContext();
    const minimize = useCallback((nodeData) => {
        setData(old => {
            return {...old, nodeGroups: {...old.nodeGroups, [nodeData.id]: {...old.nodeGroups[nodeData.id], minimized: !old.nodeGroups[nodeData.id].minimized}}};
        })
    }, [])
    const addNodeToGroup = useCallback((nodeId, groupId)=> {
        setData(old => {
            const newNodes = {...old.nodeGroups}
            newNodes[groupId].nodes.push(nodeId)
            return {...old, nodeGroups: newNodes}
        })
        setNodes(old => {
            const updatedNodes = old.map(item => {
                if (item.id === nodeId) {
                    return {...item, parentNode: groupId}
                }
                return item
            })
            return updatedNodes
        })
    })
    const deleteGroup = useCallback((id) => {
        setData(old => {
            const newNodes = {...old.nodeGroups}
            delete newNodes[id]
            return {...old, nodeGroups: newNodes}
        })
        
        setNodes(old => {
            const updatedNodes = old.filter(item => item.id !== id);
            return updatedNodes
        })
    }, []);
    

    return {minimize, deleteGroup, addNodeToGroup};
}

export default useGroup;  