import Fetch from "./index";
import {newObjectID} from "../utils";

export const baseSnapshotVersion = (version, chatbot) => {
    let intentId =  newObjectID()
    let intentId2 =  newObjectID()
    let nodeId =  newObjectID()
    let responseId =  newObjectID()
    return (
        {
            status: "EDITING",
            chatbot: chatbot,
            version: version,
            intents: {
                [intentId]: {
                    id: intentId,
                    title: "Saudação",
                    examples: [
                        {
                            text: "Olá, tudo bem?"
                        },
                        {
                            text: "Bom dia, tudo certo?"
                        }
                    ]
                },
                [intentId2]: {
                    id: intentId2,
                    title: "Despedida",
                    examples: [
                        {
                            text: "Até a próxima!"
                        },
                        {
                            text: "Tchau!"
                        }
                    ]
                }
            },
            nodes: {
                [nodeId]: {
                    id: nodeId,
                    title: "Bem vindo",
                    condition: intentId,
                    response: responseId,
                    position: {
                        x: -93.47413855076047,
                        y: 161.17942181505788
                    }
                }
            },
            responses: {
                [responseId]: {
                    id: responseId,
                    type: "text",
                    data: "Olá, seja bem vindo ao seu chatbot! Este é um modelo básico edite usando a ferramenta Verbeux Editor."
                }
            },
            edges: {
                "000000000000000000000000": {
                    [nodeId]: {
                        id: newObjectID(),
                        type: "flow",
                        source: "000000000000000000000000",
                        target: nodeId
                    }
                }
            },
        }
    )
}

export const updateSnapshotVersion = async (id, data) => {
    if(!data) return Promise.reject(new Error('Empty data'));
    return await Fetch(
        '/snapshot-version/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        }
    )
}

export const patchSnapshotVersion = async (id, data) => {
    return Fetch(
        '/snapshot-version/' + id,
        {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        }
    )
}

export const createSnapshotVersion = async (data) => {
    return await Fetch(
        '/snapshot-version/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        }
    )
}
export const translateSnapshotVersion = async (data, chatbotId) => {
    return await Fetch(
        '/snapshot-version/translate/' + chatbotId,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        }
    )
}

export const deleteSnapshotVersion = async (id) => {
    return await Fetch(
        `/snapshot-version/${id}`,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        }
    )
}

export const getSnapshotVersions = async (chatbot, page, perPage) => {
    return await Fetch(`/snapshot-version/?order=DESC&field=updatedAt&chatbot=${chatbot}&page=${page ?? 1}&perPage=${perPage ?? 10}`)
}

export const getSnapshotVersion = async (id) => {
    return await Fetch("/snapshot-version/" + id)
}

export const getSnapshotVersionStatus = async (id) => {
    return await Fetch("/snapshot-version/" + id + "/status")
}
