import styled from "styled-components";

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 0 1rem;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
