import Fetch from "./index";

export const getGenerativeTriggers = async (filters = {}) => {
    if (!filters.assistantId)
        return Promise.reject("failed to read id");

    const assistantId = filters.assistantId;
    delete filters.assistantId;

    const filter = new URLSearchParams(filters).toString();

    return Fetch(`/assistant/${assistantId}/trigger/?` + filter)
}

export const createGenerativeTriggers = async (assistantId, body) => {
    return Fetch(
        `/assistant/${assistantId}/trigger/`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const updateGenerativeTrigger = async (triggerId, assistantId, body) => {
    return Fetch(
        `/assistant/${assistantId}/trigger/${triggerId}`,
        {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const deleteGenerativeTriggers = async (assistantId, id) => {
    if (!id || !assistantId)
        return Promise.reject("empty id sent");

    return  Fetch(
        `/assistant/${assistantId}/trigger/${id}`,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

