import { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

const PlatformContext = createContext();

export const PlatformProvider = ({ children }) => {
  const [isMac, setIsMac] = useState(false);

  useEffect(() => {
    setIsMac(navigator.platform.toUpperCase().indexOf('MAC') >= 0);
  }, []);

  return (
    <PlatformContext.Provider value={{ isMac }}>
      {children}
    </PlatformContext.Provider>
  );
};

export const usePlatform = () => {
  return useContext(PlatformContext);
};

export const useShortcuts = (shortcuts) => {
  const { isMac } = usePlatform();

  useEffect(() => {
    const handleKeyDown = (event) => {
      shortcuts.forEach(({ key, callback }) => {
        if (((isMac && event.metaKey) || (!isMac && event.ctrlKey)) && event.key === key) {
          callback(event);
        }
      });
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isMac, shortcuts]);
};

useShortcuts.propTypes = {
  shortcuts: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    })
  ).isRequired,
};