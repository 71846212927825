import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Button, Form, Input, List, message, Modal, Row, Space, Tag, Typography} from "antd";
import UserContext from "../../../context/users";
import {createAssistant, deleteAssistant, getAssistants, updateAssistant} from "../../../services/assistant";
import {useNavigate} from "react-router-dom";
import {AdminRole} from "../../../constants";
import ChatbotCard from "../../../components/chatbot-card";
import CreateChatbotCard from "../../../components/create-chatbot-card";
import MessageApiContext from "../../../context/message-api";
import {InputSearch} from "../../../components/input-search";
import {getCompanies} from "../../../services/companies";
import UpgradeWarning from "../../../components/upgrade-warning";

export default function ListGenerative() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const messageApi = useContext(MessageApiContext);
    const [filters, setFilters] = useState({});
    const [user] = useContext(UserContext);
    const [search, setSearch] = useState('');
    const [creating, setCreating] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [limitExceeded, setLimitExceeded] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const create = useCallback((data) => {
        setCreating(true);
        createAssistant(data).then(({data}) => {
            navigate(`${data?.id}`);
            messageApi.success("Assistente salvo com sucesso!")
        }).catch(({data: {errorMessage}}) => {
            if (errorMessage === 'max assistants reached') {
                setLimitExceeded(true)
                return messageApi.error("Limite de chatbots excedido.");
            }
            messageApi.error("Falha ao salvar informações do assistente")
        }).finally(() => setCreating(false));
    }, [form]);

    useEffect(() => {
        form.setFieldValue("companyID", user.companyID);
    }, [user, form])

    useEffect(() => {
        reloadList()
    }, [page, perPage, filters])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPage(1);
            reloadList();
        }, 400);

        return () => clearTimeout(timeout);
    }, [search])

    const reloadList = () => {
        setLoading(true);
        const filter = {search, searchField: 'name'};
        for (let key in filters)
            filter[key] = filters[key];

        getAssistants({page, perPage, graph: true, ...filter}).then(({data, total}) => {
            setData(data);
            setTotal(total);
        }).catch(console.error).finally(() =>
            setLoading(false)
        )
    }

    const filterArray = useMemo(() => Object.values(filters), [filters]);
    const filterArrayKeys = useMemo(() => Object.keys(filters), [filters]);

    return (
        <>
            <Modal confirmLoading={creating}
                   okText={"Criar"}
                   okButtonProps={{disabled: creating}}
                   cancelText={'Cancelar'}
                   open={createOpen}
                   onOk={form.submit}
                   onCancel={() => setCreateOpen(false)}
                   title={'Criar assistente'}>
                <Space direction={'vertical'}>
                    <Typography.Text>Após criar o seu assistente você poderá treina-lo com informações
                        pertinentes.</Typography.Text>
                    {limitExceeded && <UpgradeWarning
                        message={"Limite de bots excedido"}
                        description={"Você já ultrapassou o limite de chatbots do seu plano."}
                    />}
                    <Form
                        form={form}
                        layout={'vertical'}
                        onFinish={create}
                    >
                        {user.role === AdminRole && <Form.Item name={'companyID'}>
                            <InputSearch
                                style={{width: '100%', display: user.role === AdminRole ? 'inherit' : 'none'}}
                                size={'large'}
                                placeholder={'Selecionar empresa'}
                                getCallback={getCompanies}/>
                        </Form.Item>}
                        <Form.Item name={'name'} rules={[
                            {required: true, message: 'Nome do assistente é obrigatório'}
                        ]}>
                            <Input
                                placeholder={'Nome do assistente'} size={'large'}/>
                        </Form.Item>
                        <Form.Item style={{display: 'none'}}>
                            <Button htmlType={'submit'}></Button>
                        </Form.Item>
                    </Form>
                </Space>
            </Modal>
            <List
                itemLayout="horizontal"
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                locale={{
                    emptyText: <CreateChatbotCard
                        entity={'assistente inteligente'}
                        entitySecondary={'assistente por fluxo'}
                        linkSecondary={'/chatbots'}
                        handleCreate={() => setCreateOpen(true)}
                    />
                }}
                className={'antd-list-custom'}
                header={
                    <>
                        <Row justify={'space-between'}>
                            <Typography.Title level={3}>Generativo</Typography.Title>
                            <Space direction={'horizontal'}>
                                {filterArray.map((item, i) => <Tag closable onClose={() => setFilters(old => {
                                    delete old[filterArrayKeys[i]];
                                    return {...old};
                                })}>Empresa</Tag>)}
                                <Input.Search placeholder={'Pesquisa por nome'}
                                              onSearch={(search) => setSearch(search)}
                                              onChange={({target}) => setSearch(target.value)}/>
                                <Button onClick={() => setCreateOpen(true)} type="primary">
                                    Criar
                                </Button>
                                {/*{user.role === AdminRole && <Filters onFinish={setFilters} filters={filters}/>}*/}
                            </Space>
                        </Row>
                    </>
                }
                loading={loading}
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page
                }}
                dataSource={data}
                renderItem={item => (
                    <ChatbotCard messageApi={messageApi}
                                 setFilters={setFilters}
                                 isGenerative
                                 reloadList={reloadList}
                                 data={item}
                                 updateCall={updateAssistant}
                                 deleteCall={deleteAssistant}/>
                )}
            />
        </>
    );
}
