import styled from "styled-components";

export const Container = styled.div`
  background-color: ${props => props.background};
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.color};
  padding: 1rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  color: ${props => props.color};
`
