import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Dropdown, Input, Layout, Popconfirm, Row, Space, Table, theme, Tooltip, Typography, Upload} from "antd";
import {Routes, Route, Link} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";
import {DeleteOutlined, EditOutlined, ExportOutlined, ImportOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import EntityCreate from "../entity-create";
import EntityEdit from "../entity-edit";
import ExportJson from "../../components/export-json";
import MessageApiContext from "../../context/message-api";
import { convertCsvToArray, newObjectID } from "../../utils";
import ExportCsv from "../../components/export-csv";

export default function Entities() {
    const {
        token: {colorBgContainer, borderRadius},
    } = theme.useToken();

    return (
        <Layout.Content style={{margin: '16px', overflowY: 'auto', height: '100%'}}>
            <div style={{padding: 24, borderRadius: borderRadius, background: colorBgContainer}}>
                <Routes>
                    <Route index path={'/'} element={<ListEntities/>}/>
                    <Route index path={'create'} element={<EntityCreate/>}/>
                    <Route index path={':entity'} element={<EntityEdit/>}/>
                </Routes>
            </div>
        </Layout.Content>
    )
}

const columns = [
    {
        title: "Nome",
        dataIndex: "title",
        key: 'title',
        render: (text => `@${text}`)
    },
    {
        title: "Sinonimos",
        dataIndex: 'values',
        key: 'values',
        render: data => (data ?? []).reduce((acc, {synonyms}) => acc + (synonyms?.length ?? 0), 0)
    },
    {
        title: "",
        key: 'action',
        render: (_, record) => <Row justify={'end'}>
            <Button type={'text'} danger icon={<Popconfirm
                title="Você tem certeza?"
                description="Essa ação vai deletar a entidade por inteiro!"
                onConfirm={() =>{
                    record.delete(record?.id)}}
                cancelText={'Cancelar'}>
                <DeleteOutlined/>
            </Popconfirm>}/>
            <Button type={'text'} icon={<Link to={record?.id}><EditOutlined/></Link>}/>
        </Row>
    }
]

function ListEntities() {
    const [data, setData] = useContext(ChatBotDataContext);
    const messageApi = useContext(MessageApiContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [entities, setEntities] = useState([])

    useEffect(() => {
        setEntities(Object.values(data?.entitiesValued ?? {}))
    }, [data?.entitiesValued]);

    useEffect(() => {
        setTotal(entities?.length)
    }, [entities]);

    const deleteEntity = (id) => () => {
        setData(old => {
            delete old.entitiesValued[id];
            return {...old};
        });
    };

    const dataSource = entities
        ?.slice((page - 1) * perPage, perPage * page)
        ?.map(item => ({...item, delete: deleteEntity(item.id)}));

    const searchEntity = useCallback(({target}) => {
        setSearch(target.value)
    }, [setSearch]);

    useEffect(() => {
        const entities = Object.values(data?.entitiesValued ?? {});
        const timeout = setTimeout(() => {
            setEntities(entities.filter(item => {
                return !!item?.title?.toLowerCase().includes(search.toLowerCase());
            }))
        }, 200)

        return () => clearTimeout(timeout);
    }, [search, data?.entitiesValued])

    const normalizeString = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    };

    const getEntitiesFromCsv = (csvFile) => {
        const csvArray = convertCsvToArray(csvFile);
        let entities = {};
    
        for (let line of csvArray) {
            const [entitieTitle, valueName, ...synonyms] = line;
            let id;
            if (!entities[entitieTitle]) {
                id = newObjectID();
                entities[id] = {
                    id: id,
                    title: entitieTitle,
                    values: []
                };
            }
    
            entities[id].values.push({
                name: valueName,
                synonyms: synonyms.length > 0 ? synonyms : []
            });
        }
        return entities;
    };
    
    

    const handleImport = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                let newEntities;
                if(file.type === 'text/csv') {
                    newEntities = getEntitiesFromCsv(event.target.result)
                } else {
                    newEntities = JSON.parse(event.target.result);
                }
                const updatedEntities = { ...data.entitiesValued };
                Object.keys(newEntities).forEach(newEntityKey => {
                    const newEntity = newEntities[newEntityKey];
                    const newEntityTitleNormalized = normalizeString(newEntity.title)
                    const existingEntityKey = Object.keys(updatedEntities)
                        .find(key => normalizeString(updatedEntities[key].title) === newEntityTitleNormalized);

                    if (existingEntityKey) {
                        newEntity.values.forEach(newValue => {
                            updatedEntities[existingEntityKey].values.push(newValue);
                        });
                    } else {
                        updatedEntities[newEntityKey] = newEntity;
                    }
                });

                setData(oldData => ({
                    ...oldData,
                    entitiesValued: updatedEntities
                }));
                messageApi.success('Arquivo carregado com sucesso!');
            } catch (err) {
                console.error("Failed to read JSON")
                messageApi.error('Erro ao ler o arquivo JSON');
            }
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        accept: '.json, .csv',
        multiple: true,
        beforeUpload: handleImport
    };
    return (
        <>
            <Row justify={'space-between'} align={'top'} style={{marginBottom: 8}}>
                <Space direction={'horizontal'}>
                    <Typography.Title level={4}>Entidades</Typography.Title>
                    <Tooltip
                        title={'Entidades em um chatbot são informações específicas que o usuário fornece, como nomes, datas, locais, valores, entre outros.'}>
                        <Button icon={<QuestionCircleOutlined/>} type={'text'}/>
                    </Tooltip>
                </Space>
                <Space>
                    <Input.Search placeholder={'Pesquisar'} onChange={searchEntity} value={search}/>
                    <Tooltip title={'Exportar'}>
                        <Dropdown
                            placement="bottom"
                            dropdownRender={(menu) => (
                                <div >
                                    <Space
                                        style={{
                                        padding: 8,
                                        }}
                                    >
                                        <ExportJson filename={'entities'} data={data?.entitiesValued}>
                                            <Button disabled={total === 0}>JSON</Button>
                                        </ExportJson>
                                        <ExportCsv isEntitie={true} filename={'entities'} data={data?.entitiesValued}>
                                            <Button disabled={total === 0}>CSV</Button>
                                        </ExportCsv>
                                    </Space>
                                </div>
                            )}
                            >
                            <Button icon={<ExportOutlined/>}/>    
                        </Dropdown>
                    </Tooltip>
                    <Upload {...uploadProps}>
                        <Tooltip
                            title={'Importar'}>
                            <Button icon={<ImportOutlined/>}/>
                        </Tooltip>
                    </Upload>
                    <Link to={'create'}>
                        <Button type={'primary'}>Criar</Button>
                    </Link>
                </Space>
            </Row>
            <Table
                orientation={'left'}
                itemLayout={'horizontal'}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page
                }}
            />
        </>
    );
}
