import {
    ChatContainer, Message, MessagesContainer, ChatHeader, MessageContainer
} from "./styles";
import React, {useContext, useEffect, useRef, useState, useCallback, useMemo} from "react";
import {createSession, updateSession} from "../../services/session";
import ActionsContext from "../../context/actions";
import {
    Alert,
    Button,
    Col,
    Empty,
    Input,
    List,
    Popover,
    Row,
    Space,
    Tag,
    theme,
    Tooltip,
    Typography, Upload
} from "antd";
import {
    BugOutlined, DeleteOutlined,
    EnterOutlined, FileOutlined,
    FilePdfOutlined,
    GlobalOutlined,
    InfoCircleOutlined, LinkOutlined,
    LoadingOutlined, ThunderboltOutlined, UploadOutlined, VideoCameraOutlined, WarningOutlined,
    AudioOutlined,
    SendOutlined,
} from "@ant-design/icons";
import FlowContext from "../../context/flow";
import AudioMessage from "./AudioMessage";

import {useVoiceVisualizer, VoiceVisualizer} from 'react-voice-visualizer';

export default function ChatTest({generative, id, chatbot, status, internalVersion}) {
    const [text, setText] = useState('');
    const [messages, setMessages] = useState([]);
    const [session, setSession] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDebug, setIsDebug] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [failedSession, setFailedSession] = useState('');
    const [fileFromBlob, setFileFromBlob] = useState();
    const [audioDisabled, setAudioDisabled] = useState(false);
    const [audioUrl, setAudioUrl] = useState();
    const [audioDuration, setAudioDuration] = useState();

    const ctx = useContext(ActionsContext) ?? [];
    const [isTraining] = ctx['train'] ?? [];
    const {setFlow} = useContext(FlowContext);

    const inputRef = useRef();
    const recorderControls = useVoiceVisualizer();

    useEffect(() => {
        localStorage.setItem("isDebug", isDebug)
    }, [isDebug])

    const {
        startRecording,
        stopRecording,
        clearCanvas,
        isRecordingInProgress,
        isAvailableRecordedAudio,
        recordingTime,
        recordedBlob,
        audioSrc,
        duration
    } = recorderControls;

    const recorderProps = useMemo(() => ({
        height: 0,
        width: '0',
        barWidth: 0,
        isControlPanelShown: false,
        isDefaultUIShown: false,
        isProgressIndicatorShown: false,
        isAudioProcessingTextShown: false,
    }), []);

    const removeFile = (file) => {
        const newFileList = fileList.filter(item => item !== file);
        setFileList(newFileList);
    }

    const addFile = (file) => {
        setFileList([...fileList, file]);
    }

    const getFilesObjects = (fileList) => {
        return fileList.map((item) => {
            let fileType = item.type.split("/")[0];
            if (fileType === "audio") {
                return {data: item.name, type: "audio", url: audioUrl, audioDuration: audioDuration}
            }
            return {data: item.name, type: "file"}
        }) || []
    }

    function newMessage(text, fileList) {
        let tempText = text;
        setText('')
        setMessages(m => [...m, {
            data: {
                response: [
                    {
                        data: tempText,
                        type: "text"
                    },
                    ...(fileList?.length > 0 ? getFilesObjects(fileList) : [])
                ]
            }
        }]);
        updateSession(session.id, tempText, generative, fileList).then(({processed_nodes, ...rest}) => {
            setMessages(m => [...m, {data: rest, isMachine: true}]);
            if (processed_nodes) setFlow(flow => ([...flow, ...processed_nodes]));
        }).catch(({data}) => {
            console.error(data)
            setMessages(m => [...m, {
                data: {response: [{data: 'Falha interna: ' + data?.message, type: 'text'}]},
                isMachine: true,
                isError: true
            }]);
        })
        setFileList([]);
    }

    useEffect(() => {
        if (id)
            newSession();
    }, [id])

    useEffect(() => {
        setIsDebug(localStorage.getItem('isDebug'));
    }, [])

    function newSession() {
        setLoading(true)
        createSession(chatbot, id, generative).then(({data}) => {
            setSession(data);
            setMessages([]);
            setFlow([]);
            setFailedSession('');
        }).catch(e => setFailedSession(e?.message || 'Falha ao criar sessão')).finally(() => {
            setLoading(false)
        })
    }

    const createFileFromBlob = useCallback((blob) => {
        if (!isAvailableRecordedAudio) return;

        const fileName = 'audio.webm';
        const fileOptions = {type: blob.type, lastModified: Date.now()};
        const newFile = new File([blob], fileName, fileOptions);

        setFileFromBlob(newFile);

    }, [isAvailableRecordedAudio]);

    useEffect(() => {
        createFileFromBlob(recordedBlob);
    }, [createFileFromBlob]);

    useEffect(() => {
        if (!fileFromBlob) return;
        addFile(fileFromBlob);
    }, [fileFromBlob]);

    useEffect(() => {
        if (!isAvailableRecordedAudio) return;
        if (!audioSrc) return;
        if (!duration) return;
        setAudioUrl(audioSrc);
        setAudioDuration(duration);
    }, [audioSrc, duration, isAvailableRecordedAudio])

    useEffect(() => {
        if (fileList.length === 0) return;
        if (fileList[0].type !== "audio/webm;codecs=opus") return;
        if (!isAvailableRecordedAudio) return;
        if (!fileFromBlob) return;
        newMessage(text, fileList);
    }, [isAvailableRecordedAudio, fileList, fileFromBlob]);

    const handleRecordAudio = () => {
        startRecording();
        if (isRecordingInProgress) stopRecording();
    }

    const handleDeleteAudio = useCallback(() => {
        clearCanvas();
        if (fileFromBlob) removeFile(fileFromBlob);
    }, [fileFromBlob]);

    const Counter = () => {
        return (
            <div style={{color: 'red', fontWeight: '200'}}>
                {Math.round(recordingTime / 1000)}
                <AudioOutlined style={{marginLeft: '4px'}}/>
            </div>
        )
    };

    const uploadProps = {
        showUploadList: false,
        multiple: true,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    useEffect(() => {
        setAudioDisabled(false);
        if (fileList.length === 0) return;
        if (fileList[0].type !== "audio/webm;codecs=opus") {
            setAudioDisabled(true);
            return;
        }
    }, [fileList]);

    const {
        token: {colorPrimaryBorder, borderRadius, colorBgContainer},
    } = theme.useToken();

    return (<div style={{width: '100%', height: '100%'}}>
        <ChatHeader>
            <div/>
            <Space>
                <Tooltip title={"Modo de desenvolvedor"}>
                    <Button type={isDebug ? 'primary' : 'dashed'}
                            onClick={() => setIsDebug(!isDebug)}
                            icon={<BugOutlined/>}>

                    </Button>
                </Tooltip>
                <Button onClick={newSession} loading={loading} disabled={loading}
                        type={'dashed'}>Resetar</Button>
            </Space>
        </ChatHeader>
        <ChatContainer colorPrimaryBg={colorPrimaryBorder} borderRadius={borderRadius} colorBg={colorBgContainer}>
            <Space style={{margin: '1rem'}} direction={'vertical'}>
                {isTraining && <Alert
                    type={'warning'}
                    message={"Treinando..."}
                    closable
                    icon={<LoadingOutlined/>}
                    showIcon
                    description={"As últimas alterações não surtirão efeito nesse chat até que o treino esteja concluido."}
                />
                }
                {
                    failedSession && <Alert showIcon type={'error'} message={failedSession}/>
                }
                {
                    status === 'EDITING' &&
                    <Alert showIcon type={'warning'} message={'Chatbot ainda não foi treinado'}/>
                }
                {
                    fileList.map((file, i) => {
                        if (file.type === "audio/webm;codecs=opus") return;

                        return <Row gutter={[8, 8]} style={{margin: '0 8px'}} justify={'space-between'}>
                            <Space>
                                <FileOutlined/> {file?.name}
                            </Space>
                            <Button icon={<DeleteOutlined/>} onClick={() => uploadProps.onRemove(file)} danger
                                    type={'text'}/>
                        </Row>
                    })
                }
                <Row gutter={[8, 8]}>
                    {generative &&
                        (isRecordingInProgress ?
                                <Col flex={'32px'}>
                                    <Button icon={<DeleteOutlined/>} danger onClick={handleDeleteAudio}/>
                                </Col> :
                                <Col flex={'32px'}>
                                    <Upload  {...uploadProps}>
                                        <Button icon={<UploadOutlined/>}/>
                                    </Upload>
                                </Col>
                        )
                    }
                    <Col flex={"auto"}>
                        <Input
                            suffix={isRecordingInProgress ? <Counter/> : <EnterOutlined/>}
                            disabled={!session || (status === 'EDITING' && internalVersion < 2) || failedSession || isRecordingInProgress}
                            ref={inputRef}
                            loading={loading}
                            onKeyDown={(event) => event.key === "Enter" && newMessage(text, fileList)}
                            value={text}
                            onChange={({target}) => {
                                setAudioDisabled(true);
                                setText(target.value);
                                if (!target.value && fileList.length === 0) setAudioDisabled(false);
                            }}
                            placeholder={'Digite aqui...'}/>
                    </Col>
                    {generative && <Col flex={'32px'}>
                        <Button disabled={audioDisabled}
                                icon={isRecordingInProgress ? <SendOutlined/> : <AudioOutlined/>}
                                onClick={handleRecordAudio}/>
                    </Col>}
                </Row>
                <VoiceVisualizer controls={recorderControls} {...recorderProps}/>
            </Space>
            <Messages messages={messages} isDebug={isDebug} newMessage={newMessage}/>
        </ChatContainer>
    </div>)
}

export function Messages({messages, isDebug, newMessage, notAnimated}) {
    const {
        token: {colorText, colorBgLayout, colorErrorBg, colorErrorText, colorPrimaryBgHover},
    } = theme.useToken();

    const identifierIsVideo = identifier => identifier.includes("-")

    const formatIdentifier = (identifier) => {
        if (identifierIsVideo(identifier)) {
            const [start, end] = identifier.split(' - ').map(parseFloat);
            const formatTime = (time) => {
                const minutes = Math.floor(time / 60);
                const seconds = Math.round(time % 60);
                return `${minutes}:${seconds.toString().padStart(2, '0')} min`;
            };
            return `${formatTime(start)} - ${formatTime(end)}`;
        }
        return identifier;
    };
    const getSourceData = (source) => {
        return identifierIsVideo(source.identifier) ? source.title.startsWith("http") ? {
            icon: <GlobalOutlined/>,
            link: source.identifier,
            text: ""
        } : {
            icon: <VideoCameraOutlined/>,
            link: source.link,
            text: `No intervalo de ${formatIdentifier(source.identifier)}`
        } : {icon: <FilePdfOutlined/>, link: "", text: `Na página ${formatIdentifier(source.identifier)}`}
    }

    const sourcePopoverContent = (msg) =>
        <List
            size="small"
            dataSource={msg}
            renderItem={item => (
                <List.Item>
                    <Space direction={"vertical"}>
                        <Space>
                            {item && getSourceData(item).icon}
                            <Typography.Text>{item.title}</Typography.Text>
                            {item.link || item.identifier.startsWith("http") && (
                                <a href={getSourceData(item).link} target="_blank"
                                   rel="noopener noreferrer">
                                    <LinkOutlined/>
                                </a>
                            )}
                        </Space>
                        <Typography.Text>{getSourceData(item).text}</Typography.Text>
                    </Space>
                </List.Item>
            )}
        />


    if (messages.length < 1)
        return <Empty imageStyle={{ height: 150, transform: 'scale(2)'}}
                      image={Empty.PRESENTED_IMAGE_SIMPLE} 
                      description={'Nenhuma mensagem enviada'}/>

    return <MessagesContainer>
        {messages?.map(({data, isMachine, isError}, i) => <>
                {data?.response?.map((msg, i) => {
                    return <MessageContainer notAnimated={notAnimated} key={i} isMachine={isMachine}>
                        <>
                            {(['text', 'multiple'].includes(msg.type) && msg.data) &&
                                <Message bgColor={isMachine ? colorBgLayout : colorPrimaryBgHover}
                                         bgColorError={colorErrorBg}
                                         colorErrorText={colorErrorText}
                                         isError={isError}
                                         color={colorText}
                                         isMachine={isMachine}>
                                    {msg?.data}
                                </Message>}
                            {msg.type === 'options' && <Row gutter={[8, 8]}>
                                {msg.data.map(({text, fileList}, i) => <Col key={i}><Button
                                    onClick={() => newMessage(text, fileList)}>
                                    {text}
                                </Button></Col>)}
                            </Row>}
                        </>
                        {msg.type === 'file' &&
                            <Space direction={'vertical'}>
                                <Space>
                                    <FileOutlined/> {msg?.data}
                                </Space>
                            </Space>
                        }
                        {msg.type === "audio" &&
                            <Space direction={'vertical'}>
                                <React.Fragment>
                                    <AudioMessage src={msg.url} duration={msg.audioDuration}
                                                  bgColor={colorPrimaryBgHover} isMachine={isMachine}/>
                                </React.Fragment>
                            </Space>
                        }

                        {msg.type === 'trigger' &&
                            <Tooltip title={"Esta é uma chamada de gatilho sem nenhuma ação definida."}>
                                <Tag icon={<ThunderboltOutlined/>}
                                     color={'orange'}>
                                    {msg?.data?.function_name}({Object.values(msg?.data?.args ?? {})?.join(", ")})
                                </Tag>
                            </Tooltip>}
                        {msg.type === 'reference' && msg?.data?.length > 0 &&
                            <div style={{textAlign: 'right', marginRight: 4,}}>
                                <Typography.Text style={{fontSize: '12px', color: colorText}}>Fonte</Typography.Text>
                                <Popover placement={"top"}
                                         content={sourcePopoverContent(msg?.data)}
                                         title="Consulte as informações em:">
                                    <InfoCircleOutlined style={{marginLeft: 4, fontSize: '12px', verticalAlign: 'middle'}}/>
                                </Popover>
                            </div>}
                    </MessageContainer>
                })
                }
                {data.isAnythingElse &&
                    <Space>
                        <Tag icon={<WarningOutlined/>} color={'warning'}>Fora do contexto</Tag>
                    </Space>
                }
                {isMachine && isDebug &&
                    <Row style={{marginTop: 8}} gutter={[8, 8]}>
                        <>
                            {data?.intents?.filter(item => item.confidence > .7)?.map((item, i) => <Col><Tag
                                key={i}>
                                #{item.name} {parseInt(item.confidence * 100)}%
                            </Tag></Col>)}
                        </>

                        <>
                            {data?.entities?.filter(item => item.confidence > .7)?.map((item, i) => <Col><Tag
                                key={i}>
                                {item?.entity[0] === '@' ? `${item.entity}(${item?.matched})` : '@' + item.entity} {parseInt(item.confidence * 100)}%
                            </Tag></Col>)}
                        </>
                    </Row>}
            </>
        )}
    </MessagesContainer>;
}
