import {Button, Dropdown, Space, theme, Tooltip} from "antd";
import {HistoryOutlined} from "@ant-design/icons";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ChatBotDataContext from "../../context/chatbot-data";
import MessageApiContext from "../../context/message-api";
import {updatedSince} from "../../utils";
import useData from "../../hooks/useData";
import {PulseButton} from "./styles";

export default function LastEditions() {
    const [data, setData] = useContext(ChatBotDataContext);
    const {convertData} = useData();
    const messageApi = useContext(MessageApiContext);
    const [dataSavedList, setDataSavedList] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [saving, setSaving] = useState(false);
    const {
        token: {colorWarning},
    } = theme.useToken();

    const getSavedData = useCallback(() => {
        const listData = localStorage.getItem(`snapshot-${data.id}-last`)
        let parsedData;
        try {
            parsedData = JSON.parse(listData);
        } catch (e) {
            messageApi.error("Falha ao recuperar dados");
            localStorage.removeItem(`snapshot-${data.id}-last`);
            return
        }

        return parsedData;
    }, [data.id])

    const recover = useCallback((index) => {
        convertData(dataSavedList[index]);
        setData(dataSavedList[index]);
        messageApi.success("Dados recuperados com sucesso")
    }, [dataSavedList]);

    useEffect(() => {
        const recoveredData = getSavedData();
        if (recoveredData && recoveredData.length > 0)
            setDataSavedList(recoveredData);
    }, []);

    const saveData = useCallback(() => {
        setSaving(true)
        setDataSavedList(old => [{...data, offlineSave: new Date()}, ...old].slice(0, 5))
        const timeout = setTimeout(() => setSaving(false), 3000)

        return () => clearTimeout(timeout)
    }, [data])

    useEffect(() => {
        if (!data)
            return;

        const interval = setInterval(saveData, 60 * 1000);

        return () => clearInterval(interval)
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            let jsonData = JSON.stringify(dataSavedList);

            try {
                localStorage.setItem(`snapshot-${data.id}-last`, jsonData)
            } catch (e) {
                console.error(e)
                setErrorMessage("Limite de armazenamento excedido.")
            }
        }, 500)

        return clearTimeout(timeout)
    }, [dataSavedList, data.id]);


    const items = useMemo(() => {
        const data = dataSavedList.sort((a, b) =>
            new Date(b.offlineSave).getTime() - new Date(a.offlineSave).getTime());

        return data.map((item, index) => ({
            label: 'Recuperar - ' + updatedSince(new Date(item.offlineSave)) + ' atrás',
            key: index
        }))
    }, [dataSavedList]);

    const onClick = useCallback(({key}) => {
        recover(parseInt(key))
    }, [recover]);

    return (
        <div style={{position: "absolute", right: 12, top: 76}}>
            {items?.length > 0 && <Space direction={'vertical'}>
                <Tooltip title={errorMessage}>
                    <Dropdown menu={{
                        items,
                        onClick,
                    }}>
                        <PulseButton danger={!!errorMessage}
                                     saving={saving}
                                     color={colorWarning}
                                     icon={<HistoryOutlined/>}/>
                    </Dropdown>
                </Tooltip>
            </Space>}
        </div>
    )
}
