import {Alert, Button, Form, Input, message} from 'antd';
import {SaveOutlined, DeleteOutlined} from "@ant-design/icons";
import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getSnapshotVersion, patchSnapshotVersion, updateSnapshotVersion} from "../../services/snapshot_version";
import MessageApiContext from "../../context/message-api";

export default function VersionEdit() {
    const {id, snapshot} = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const messageApi = useContext(MessageApiContext);

    useEffect(() => {
        if (!snapshot)
            return;

        setLoading(true);
        getSnapshotVersion(snapshot).then(({data}) => {
            form.setFieldsValue(data)
            setLoading(false)
        }).catch((e) => {
            console.error(e)
            messageApi.error("Falha ao carregar informações do chatbot");
        })
    }, [snapshot, form])

    const save = useCallback((values) => {
        setSaving(true)
        patchSnapshotVersion(snapshot, values).then(() => {
            setSaving(false)
            navigate(`/chatbots/${id}/versions/`);
            messageApi.success("Chatbot salvo com sucesso!");
        }).catch(e => {
            console.error(e)
            setSaving(false);
            messageApi.error("Falha ao salvar informações do chatbot");
        })
    }, [snapshot, id])

    return (
        <Form
            name="basic"
            style={{
                maxWidth: 600,
            }}
            layout={'vertical'}
            disabled={loading}
            autoComplete="off"
            form={form}
            onFinish={save}
        >


            <Form.Item
                label="Versão"
                name="version"
                rules={[
                    {
                        required: true,
                        message: 'Esta versão deve ter um nome',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Alert
                message="Atenção"
                description="Aqui não será possível configurar intenções, entidades ou fluxo. Para isso utilize nosso app na página anterior clicando no título da versão.s"
                type="info"
                showIcon/>
            <br/>
            <Form.Item>
                <Button loading={saving} icon={<SaveOutlined/>} type="primary" htmlType="submit">
                    Salvar
                </Button>
                <Button danger style={{marginLeft: '1rem'}} htmlType="button" icon={<DeleteOutlined/>}>
                    Deletar
                </Button>
            </Form.Item>
        </Form>
    )
}
