import React, {memo} from "react";
import {Container} from "./styles";
import {Handle} from "reactflow";
import {FaUser} from "react-icons/fa";
import {theme} from "antd";


export default memo(({data, isConnectable}) => {
    const {
        token: {colorPrimary, colorBgContainer},
    } = theme.useToken();

    return (
        <Container background={colorBgContainer} color={colorPrimary}>
            <FaUser/>
            <Handle
                type="source"
                position="bottom"
                style={{width: 12, height: 8, borderRadius: '50%', background: colorBgContainer}}
                isConnectable={isConnectable}
                id={'c'}
            />
        </Container>
    )
})
