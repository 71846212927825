import styled, {css, keyframes} from "styled-components";
import {Button} from "antd";

const pulse = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`;

export const PulseButton = styled(props => <Button {...props}/>)`
    ${props => props.saving && css`
        transition: transform 0.3s;
        animation: ${pulse} 2s infinite;
        color: ${props => props.color};
        border-color: ${props => props.color};
    `}
    
`;
