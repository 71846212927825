import {Button, Divider, Form, Input, message, Select} from 'antd';
import {LockOutlined, SaveOutlined} from "@ant-design/icons";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getCompanies} from "../../services/companies";
import {createUser, getUser, updateUser} from "../../services/users";
import UserContext from "../../context/users";
import {EditorRole, AdminRole, OwnerRole} from '../../constants';
import {InputSearch} from "../../components/input-search";
import MessageApiContext from "../../context/message-api";

export default function EditUser() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {id} = useParams();
    const [user] = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);

    useEffect(() => {
        if (!id)
            return;

        setLoading(true);
        getUser(id).then(({data}) => {
            form.setFieldsValue(data)
            setLoading(false)
        }).catch((e) => {
            console.error(e)
            messageApi.error("Falha ao carregar informações do usuário")
        })
    }, [id, form])

    const optionsRoles = <>
        {[AdminRole, OwnerRole].includes(user.role) && <Select.Option value={EditorRole}>Editor</Select.Option>}
        {[AdminRole, OwnerRole].includes(user.role) && <Select.Option value={OwnerRole}>Dono</Select.Option>}
        {user.role === AdminRole && <Select.Option value={AdminRole}>Admin</Select.Option>}
    </>;

    const save = useCallback((values) => {
        setSaving(true)

        updateUser(id, values).then(() => {
            setSaving(false)
            navigate('/users');
            messageApi.success("Usuário atualizado com sucesso!")
        }).catch(e => {
            console.error(e)
            setSaving(false);
            messageApi.error("Falha ao atualizar usuário")
        })
    }, [id, user, navigate, setSaving, messageApi, createUser])

    return (
        <>
            <Form
                style={{
                    maxWidth: 600,
                }}
                disabled={loading}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={save}
            >
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Digite um nome para a empresa',
                        },
                    ]}
                >
                    <Input placeholder={'Digite um nome'}/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Digite um email válido'
                        },
                    ]}
                >
                    <Input placeholder={'voce@you.com'}/>
                </Form.Item>

                <Form.Item
                    label={'Permissão'}
                    name={'role'}
                    rules={[{required: true, message: "Permissão não pode ser vazia"}]}
                >
                    <Select placeholder={'Selecione a permissão'}>{optionsRoles}</Select>
                </Form.Item>
                <Form.Item
                    label={'CPF'}
                    name={'identity'}
                >
                    <Input disabled={true}/>
                </Form.Item>
                {user.role === AdminRole && <Form.Item
                    name="companyID"
                    label={'Empresa'}
                    rules={
                        [
                            {
                                required: true,
                                message: 'Você deve selecionar a empresa'
                            }
                        ]
                    }
                >
                    <InputSearch getCallback={getCompanies}/>
                </Form.Item>}
                <Divider/>
                <Form.Item
                    name="password"
                    label={'Senha'}
                    validateStatus={confirmPassword !== password ? 'error' : undefined}
                    hasFeedback
                    help={confirmPassword !== password ? 'As senhas não correspondem' : undefined}
                    rules={
                        [
                            {
                                min: 6,
                                message: 'A senha deve ter no mínimo 6 caracteres'
                            }
                        ]
                    }
                >
                    <Input
                        prefix={<LockOutlined/>}
                        type="password"
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    hasFeedback
                    label={'Confirmar senha'}
                    help={confirmPassword !== password ? 'As senhas não correspondem' : undefined}
                    validateStatus={confirmPassword !== password ? 'error' : undefined}
                >
                    <Input
                        prefix={<LockOutlined/>}
                        type="password"
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item>
                    <Button loading={saving} icon={<SaveOutlined/>} type="primary" htmlType="submit">
                        Salvar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
