import Fetch from "./index";

export const getApiKey = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return await Fetch("/chatbot/" + id)
}

export const getApiKeys = async (page, perPage, companyId) => {
    return await Fetch(`/api-key?page=${page ?? 1}&perPage=${perPage ?? 10}&companyId=${companyId ?? ''}`)
}

export const createApiKey = async (companyID) => {
    return await Fetch(
        '/api-key/',
        {
            method: 'POST',
            body: JSON.stringify({companyID}),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const deleteApiKey = async (id) => {
    return await Fetch(
        '/api-key/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}
