import {Title} from "./styles";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {deleteChatbot, updateChatBot} from "../../services/chatbot";
import {
    CheckCircleOutlined, CloseOutlined, ClusterOutlined, CrownOutlined, DeleteOutlined,
    EditOutlined, FilterOutlined, MessageOutlined, MoreOutlined, RightOutlined
} from '@ant-design/icons';
import {
    Badge,
    Button, Card, Dropdown, Empty, Flex, Form, Input,
    List,
    message, Modal, Progress,
    Row,
    Space, Statistic, Tag,
    theme, Tooltip, Typography,
} from "antd";
import {Link, Routes, Route, useNavigate} from "react-router-dom";
import ChatBotFlowEdit from "../chatbot-flow-edit";
import AdminLayout from "../../components/admin-layout";
import SelectFunction from "../select-function";
import "./style.css";
import ChatBotCreate from "../chatbot-create";
import {AdminRole, BaseComponent} from "../../constants";
import {getCompanies} from "../../services/companies";
import UserContext from "../../context/users";
import {InputSearch} from "../../components/input-search";
import { createComponent, deleteComponent, getComponents } from "../../services/components";
import { useComponentData } from "../../components/provider";
import MessageApiContext from "../../context/message-api";

export default function Components() {
    return (
        <Routes>
            <Route path={'/:id/versions/*'} element={<SelectFunction/>}/>
            <Route path={'*'} element={
                <AdminLayout>
                    <Routes>
                        <Route index path={'/'} element={<ChatBotIndex/>}/>
                        <Route path={'/create'} element={<ChatBotCreate/>}/>
                        <Route path={'/:id'} element={<ChatBotFlowEdit/>}/>
                    </Routes>
                </AdminLayout>
            }/>
        </Routes>
    )
}

function ChatBotIndex() {

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const {componentData, setComponentData} = useComponentData();
    const [components, setComponents] = useState([]);
    const messageApi = useContext(MessageApiContext);
    const [filters, setFilters] = useState({});
    const [user] = useContext(UserContext);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    ;
    useEffect(() => {
        reloadList()
    }, [page, perPage, filters])

    useEffect(() => {
        if (!search)
            return;

        const timeout = setTimeout(() => {
            reloadList()
        }, 400);

        return () => clearTimeout(timeout);
    }, [search])

    const reloadList = () => {
        setLoading(true);
        const filter = {search, searchField: 'name'};
        for (let key in filters)
            filter[key] = filters[key];

            getComponents(perPage, page, true).then((res) => {
                setComponents(res.data);

                setLoading(false)
              }).catch(e => {
                console.error(e)
                setLoading(false)
              });

    }
    const handleCreateComponent = () =>{
        createComponent(BaseComponent).then((res) => {
            navigate(`${res.data.id}/versions/flow`)
            reloadList()
        }).catch(e => {
            console.error(e)
        })
    }

    const filterArray = useMemo(() => Object.values(filters), [filters]);
    const filterArrayKeys = useMemo(() => Object.keys(filters), [filters]);

    return (
        <>

            <List
                itemLayout="horizontal"
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                className={'antd-list-custom'}
                header={
                    <>
                        <Row justify={'space-between'}>
                            <Title>Componentes</Title>
                            <Space direction={'horizontal'}>
                                {filterArray.map((item, i) => <Tag closable onClose={() => setFilters(old => {
                                    delete old[filterArrayKeys[i]];
                                    return {...old};
                                })}>Empresa</Tag>)}
                                <Input.Search placeholder={'Nome do assistente'}
                                              onSearch={(search) => setSearch(search)}/>

                                    <Button onClick={()=> {handleCreateComponent()}} type="primary">
                                        Criar
                                    </Button>
                                {user.role === AdminRole && <Filters onFinish={setFilters} filters={filters}/>}
                            </Space>
                        </Row>
                    </>
                }
                loading={loading}
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page
                }}
                dataSource={components}
                renderItem={item => (
                    <ChatbotContainer messageApi={messageApi} reloadList={reloadList} data={item}/>
                )}
            />
        </>
    );
}

function Filters({onFinish, filters}) {
    const [showFilter, setShowFilter] = useState(false);
    const [form] = Form.useForm();

    const onOk = useCallback(() => {
        form.validateFields().then(() => {
            setShowFilter(false);
            form.submit();
        });
    }, [])

    useEffect(() => {
        form.setFieldsValue(filters);
    }, [filters])

    return (
        <>
            <Button icon={<FilterOutlined/>} type="dashed"
                    onClick={() => setShowFilter(old => !old)}/>
            <Modal title="Filtros" open={showFilter} cancelText={'Cancelar'} onCancel={() => setShowFilter(false)}
                   onOk={onOk}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout={'vertical'}>
                    <Form.Item name={'companyID'} label={'Empresa'}>
                        <InputSearch getCallback={getCompanies}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const confirmDelete = {
    title: 'Tem certeza que deseja deletar?',
    content: (
        <Typography.Text>
            Deletando este chatbot todas as versões serão deletadas junto a ele.
        </Typography.Text>
    ),
    type: "error",
    okText: 'Deletar',
    cancelText: 'Cancelar',
};

function ChatbotContainer({data, reloadList}) {
    const [modal, contextHolder] = Modal.useModal();
    const messageApi = useContext(MessageApiContext);
    const navigate = useNavigate();

    const updateActive = (data, active) => {
        data.isActive = active
        messageApi.open({type: 'loading', content: active ? 'Ativando...' : 'Desativando...', duration: 0})
        updateChatBot(data.id, data)
            .then(() => {
                messageApi.destroy();
                messageApi.success('Ativado com sucesso!')
                reloadList();
            })
            .catch(e => {
                messageApi.error('Falha ao desativar')
                console.error(e)
            })
    }

    const chatbotMenuItems = useCallback((component) => [
        {
            label: !component.isActive ? 'Ativar' : 'Desativar',
            icon: !component.isActive ? <CheckCircleOutlined/> : <CloseOutlined/>,
            disabled: !component.active,
            onClick: () => {
                updateActive(component, !component.isActive)
            }
        },
        {
            label: 'Editar',
            icon: <EditOutlined/>,
            onClick: () => {
                navigate(component.id.toString());
            }
        },
        {
            danger: true,
            label: 'Deletar',
            icon: <DeleteOutlined/>,
            onClick: async () => {
                const confirmed = await modal.confirm(confirmDelete);
                if (!confirmed)
                    return;

                messageApi.open({type: 'loading', content: 'Deletando chatbot...', duration: 0})
                deleteComponent(component.id)
                    .then(() => {
                        messageApi.destroy();
                        messageApi.success('Deletado com sucesso')
                        reloadList();
                    })
                    .catch(e => {
                        messageApi.error('Falha ao deletar')
                        console.error(e)
                    })
            }
        }
    ], [])

    const chatbotLink = useMemo(() => {
        return data?.active ? `${data.id}/versions/editor/${data.active}` : `${data.id}/versions/`;
    }, [data]);

    return (
        <List.Item>
            <Card>
                <Space style={{width: '100%'}} direction={'vertical'} wrap={"nowrap"}>
                    <Row justify={'space-between'} align={'center'}>
                        <Link
                            to={chatbotLink}>
                            <Button style={{paddingLeft: 0}} size={'large'} type={'ghost'}>
                                <Space align={'center'}>

                                    <Tooltip title={data.name.length > 12 ? data.name : false}>
                                        <Typography.Title level={4}
                                                          style={{margin: 0}}>
                                            {data.name.length > 12 ? data.name.substring(0, 10) + '...' : data.name}
                                        </Typography.Title>
                                    </Tooltip>
                                </Space>
                                <RightOutlined style={{marginLeft: 8}}/>
                            </Button>
                        </Link>
                        <Dropdown trigger={['click']} selectable={false} menu={{
                            items: chatbotMenuItems(data)
                        }}>
                            <Button style={{marginTop: 4}} type={'ghost'} icon={<MoreOutlined/>}/>
                        </Dropdown>
                    </Row>
                            {/* <Space align={'center'}>

                                    <Tooltip title={data.description.length > 12 ? data.description : false}>
                                        <Typography.Text level={5} color={colorTextSecondary}
                                                          style={{margin: 0}}>
                                            {data.description.length > 12 ? data.description.substring(0, 20) + '...' : data.description}
                                        </Typography.Text>
                                    </Tooltip>
                                </Space> */}
                                <Space align={'center'}>
                                    {
                                        data.tags.length > 0 ? data.tags.map((tag)=> {
                                            return <Tag>{tag}</Tag>
                                        })
                                        :
                                        null
                                    }

                                </Space>

                </Space>

            </Card>
        </List.Item>
    )
}
