import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Input, Layout, Popconfirm, Row, Space, Table, theme, Tooltip, Typography, Upload, Dropdown} from "antd";
import {Routes, Route, Link} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";
import {DeleteOutlined, EditOutlined, ExportOutlined, ImportOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import IntentCreate from "../intent-create";
import IntentEdit from "../intent-edit";
import ExportJson from "../../components/export-json";
import MessageApiContext from "../../context/message-api";
import { convertCsvToArray, newObjectID } from "../../utils";
import ExportCsv from "../../components/export-csv";

export default function Intents() {
    const {
        token: {colorBgContainer, borderRadius},
    } = theme.useToken();

    return (
        <Layout.Content style={{margin: '16px', overflowY: 'auto', height: '100%'}}>
            <div style={{padding: 24, borderRadius: borderRadius, background: colorBgContainer}}>
                <Routes>
                    <Route index path={'/'} element={<ListIntents/>}/>
                    <Route index path={'/create'} element={<IntentCreate/>}/>
                    <Route index path={'/:intent'} element={<IntentEdit/>}/>
                </Routes>
            </div>
        </Layout.Content>
    )
}

const columns = [
    {
        title: "Nome",
        dataIndex: "title",
        key: 'title',
        render: (text => `#${text}`)
    },
    {
        title: "Exemplos",
        dataIndex: 'examples',
        key: 'title',
        render: data => data?.length
    },
    {
        title: "",
        key: 'action',
        render: (_, record) => <Row justify={'end'}>
            <Button type={'text'} danger icon={<Popconfirm
                title="Você tem certeza?"
                description="Essa ação vai deletar a intenção por inteiro!"
                onConfirm={record.deleteIntent}
                cancelText={'Cancelar'}>
                <DeleteOutlined/>
            </Popconfirm>}/>
            <Button type={'text'} icon={<Link to={record?.id}><EditOutlined/></Link>}/>
        </Row>
    }
]

function ListIntents() {
    const [data, setData] = useContext(ChatBotDataContext);
    const messageApi = useContext(MessageApiContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [intents, setIntents] = useState([])
    const [search, setSearch] = useState('');

    useEffect(() => {
        setIntents(Object.values(data?.intents ?? {}))
    }, [data])

    useEffect(() => {
        setTotal(intents?.length)
    }, [intents])

    const deleteIntent = (id) => () => {
        setData(old => {
            delete old.intents[id];
            return {...old};
        });
    }

    const dataSource = intents
        ?.slice((page - 1) * perPage, perPage * page)
        ?.map(item => ({...item, deleteIntent: deleteIntent(item.id)}));

    const searchIntent = useCallback(({target}) => {
        setSearch(target.value)
    }, [setSearch]);

    useEffect(() => {
        const intents = Object.values(data?.intents ?? {});
        const timeout = setTimeout(() => {
            setIntents(intents.filter(item => {
                if (item?.title?.toLowerCase().includes(search.toLowerCase()))
                    return true;

                const validExamples = item?.examples?.filter(item2 => item2?.text && item2?.text?.toLowerCase().includes(search.toLowerCase()));
                if (validExamples && validExamples?.length > 0)
                    return true;

                return false
            }))
        }, 200)

        return () => clearTimeout(timeout);
    }, [search, data?.intents])

    const normalizeString = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    };

    const getIntentionsFromCsv = (csvFile) => {
        const csvArray = convertCsvToArray(csvFile);
        let intentions = {};

        for (let line of csvArray) {
            const [exampleText, intentionTitle] = line;
            let id;
            if (!intentions[intentionTitle]) {
                id = newObjectID();
                intentions[id] = {
                    id: id,
                    title: intentionTitle,
                    examples: []
                };
            }

            intentions[id].examples.push({ text: exampleText });
        }
        return intentions;
    }
    
    const handleImport = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                let newIntents;
                if(file.type === 'text/csv') {
                    newIntents = getIntentionsFromCsv(event.target.result)
                } else {
                    newIntents = JSON.parse(event.target.result);
                }
                const updatedIntents = { ...data?.intents };
        
                Object.keys(newIntents).forEach(newIntentKey => {
                    const newIntent = newIntents[newIntentKey];
                    const newIntentNormalized = normalizeString(newIntent.title);

                    const existingIntentKey = Object.keys(updatedIntents)
                        .find(key => normalizeString(updatedIntents[key].title) === newIntentNormalized);
            
                    if (existingIntentKey) {
                        const existingExamples = updatedIntents[existingIntentKey].examples.map(example => example.text);
                        newIntent.examples.forEach(newExample => {
                            if (!existingExamples.includes(newExample.text)) {
                                updatedIntents[existingIntentKey].examples.push(newExample);
                            }
                        });
                    } else {
                        updatedIntents[newIntentKey] = newIntent;
                    }
                });
                setData(oldData => ({
                    ...oldData,
                    intents: updatedIntents
                }));
                messageApi.success('Arquivo carregado com sucesso!');
            } catch (err) {
                console.error("Failed to read JSON")
                messageApi.error('Erro ao ler o arquivo JSON');
            }
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        accept: '.json, .csv',
        multiple: true,
        beforeUpload: handleImport
    };


    return (
        <>
            <Row justify={'space-between'} align={'top'} style={{marginBottom: 8}}>
                <Space direction={'horizontal'}>
                    <Typography.Title level={4}>Intenções</Typography.Title>
                    <Tooltip
                        title={'Intenções em um chatbot referem-se às ações que um usuário deseja realizar ao se comunicar com o robô de bate-papo.'}>
                        <Button icon={<QuestionCircleOutlined/>} type={'text'}/>
                    </Tooltip>
                </Space>
                <Space>
                    <Input.Search placeholder={'Pesquisar'} onChange={searchIntent} value={search}/>
                    <Tooltip title={'Exportar'}>
                        <Dropdown
                            placement="bottom"
                            dropdownRender={(menu) => (
                                <div >
                                    <Space
                                        style={{
                                        padding: 8,
                                        }}
                                    >
                                        <ExportJson filename={'intents'} data={data?.intents}>
                                            <Button disabled={total === 0}>JSON</Button>
                                        </ExportJson>
                                        <ExportCsv filename={'intents'} data={data?.intents}>
                                            <Button disabled={total === 0}>CSV</Button>
                                        </ExportCsv>
                                    </Space>
                                </div>
                            )}
                            >
                            <Button icon={<ExportOutlined/>}/>    
                        </Dropdown>
                    </Tooltip>
                    <Upload {...uploadProps}>
                        <Tooltip
                            title={'Importar'}>
                            <Button icon={<ImportOutlined/>}/>
                        </Tooltip>
                    </Upload>
                    <Link to={'create'}>
                        <Button type={'primary'}>Criar</Button>
                    </Link>
                </Space>
            </Row>
            <Table
                orientation={'left'}
                itemLayout={'horizontal'}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page
                }}
            />
        </>
    );
}
