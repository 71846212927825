import Fetch from "./index";

export const getTriggerActions = async (assistantId, triggerId) => {
    if (!assistantId) return Promise.reject("failed to read id");

    return Fetch(`/assistant/${assistantId}/trigger/${triggerId}/action/`);
}

export const createTriggerAction = async (assistantId, triggerId, body) => {
    return Fetch(
        `/assistant/${assistantId}/trigger/${triggerId}/action/`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}

export const updateTriggerAction = async (assistantId, triggerId, actionId, body) => {
    return Fetch(
        `/assistant/${assistantId}/trigger/${triggerId}/action/${actionId}`,
        {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}

export const deleteTriggerAction = async (assistantId, triggerId, actionId) => {
    if (!triggerId || !assistantId) return Promise.reject("empty id sent");

    return Fetch(
        `/assistant/${assistantId}/trigger/${triggerId}/action/${actionId}`,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}
