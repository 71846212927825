import {Button, Col, Form, Input, message, Row, Select, Tooltip} from 'antd';
import {Switch} from "antd/lib";
import {SaveOutlined, DeleteOutlined} from "@ant-design/icons";
import {useCallback, useContext, useEffect, useState} from "react";
import {getChatBot, updateChatBot} from "../../services/chatbot";
import {useNavigate, useParams} from "react-router-dom";
import {getSnapshotVersions} from "../../services/snapshot_version";
import {AdminRole, GenerativeChatbot} from "../../constants";
import UserContext from "../../context/users";
import {getAiModels} from "../../services/system";
import MessageApiContext from "../../context/message-api";
import { getCompanies, getCompany } from '../../services/companies';
import { InputSearch } from '../../components/input-search';

export default function ChatBotFlowEdit({redirect = true}) {
    const {id} = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [user] = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const [snapshotVersions, setSnapshotVersions] = useState([]);
    const [loadingSnapshots, setLoadingSnapshots] = useState(true);
    const messageApi = useContext(MessageApiContext);
    const activeVersion = Form.useWatch("active", form);
    const [type, setType] = useState('');
    const [aiModels, setAiModels] = useState(true);
    const [aiModelsLoading, setAiModelsLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState('');
    const [filters, setFilters] = useState({});

    const loadAiModels = useCallback(() => {

        setAiModelsLoading(true);
        getAiModels().then(({data}) => {
            setAiModels(data.map(model => ({value: model, label: model})))
        }).catch((e) => {
            console.error(e)
            messageApi.error('Falha ao carregar modelos');
        })
            .finally(() => setAiModelsLoading(false))
    }, [])

    const getCompanyOptions = useCallback(async () => {
        let err;
        const {data} = await getCompanies({searchField: 'name'}).catch(e => err = e);
        if (err) return err;
        console.log(data);
        setCompanies(data.map((item) => ({
            label: item.name,
            value: item.id
        })));
    }, []);

    const handleSelectChange = useCallback((field, value) => {
        if (!value) return setFilters(old => {
            delete old[field];
            return {...old};
        })

        setFilters(old => ({...old, [field]: value}))
    }, [filters])

    useEffect(() => {
        if (!id)
            return;

        setLoading(true);
        getCompanyOptions().catch(e => console.log(e));

        getChatBot(id).then(({data}) => {
            form.setFieldsValue(data);
            getCompany(data?.companyID).then((company) => {
                setCompany(company.data);
            })
            setType(data?.type);
            setLoading(false);
        }).catch((e) => {
            console.error(e);
            messageApi.error("Falha ao carregar informações do chatbot");
        })

        setLoadingSnapshots(true);
        getSnapshotVersions(id).then(({data}) => {
            setSnapshotVersions(data);
            setLoadingSnapshots(false);
        }).catch((e) => {
            console.error(e);
            setLoadingSnapshots(false);
            messageApi.error("Falha ao carregar as versões do chatbot");
        });

        if (user.role === AdminRole)
            loadAiModels();

    }, [id, form, user, loadAiModels, messageApi, getCompanyOptions])

    const save = useCallback((values) => {
        setSaving(true)
        updateChatBot(id, values).then(() => {
            setSaving(false)
            if (redirect) navigate('/chatbots');
            messageApi.success("Chatbot salvo com sucesso!")
        }).catch(e => {
            console.error(e)
            setSaving(false);
            messageApi.error("Falha ao salvar informações do chatbot")
        })
    }, [id])

    return (
        <Form
            name="basic"
            layout={'vertical'}
            onFinish={save}
            disabled={loading}
            autoComplete="off"
            form={form}
        >

            <Form.Item
                label="Nome"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Digite um nome para o chatbot',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Descrição"
                name="description"
                rules={[
                    {
                        required: true,
                        message: 'Digite uma descrição curta para o chatbot',
                    },
                ]}
            >
                <Input.TextArea autoSize showCount maxLength={400}/>
            </Form.Item>
            <Row gutter={16}>
                <Col span={4}>
                    <Form.Item
                        label="Ativo"
                        name="isActive"
                        valuePropName="checked"
                        tooltip={activeVersion?.length < 1 ? 'Crie um modelo antes de ativar o chatbot' : 'Essa ação pode desabilitar o chatbot em produção'}
                    >
                        <Switch disabled={activeVersion?.length < 1 && type !== GenerativeChatbot}/>
                    </Form.Item>
                </Col>
                {type === GenerativeChatbot && (<Col span={9}>
                    <Form.Item
                        label="Restrito ao Contexto"
                        name="restrictedByContext"
                        valuePropName="checked"
                        tooltip={'Ao ativar as respostas do bot serão restritas aos conhecimentos dos documentos fornecidos.'}>
                        <Switch/>
                    </Form.Item>
                </Col>)}
            </Row>
            {type !== GenerativeChatbot && <Form.Item
                label="Versão ativa"
                name="active"
            >
                <Select
                    showSearch
                    placement={"Versão ativa"}
                    optionFilterProp="children"
                    placeholder={'Selecione a versão ativa'}
                    disabled={loadingSnapshots}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={(snapshotVersions ?? []).map(item => ({label: item.version, value: item.id}))}
                />
            </Form.Item>}

            <Form.Item
                label="Infra"
                name="infra"
                style={{display: user.role === AdminRole ? 'inherit' : 'none'}}
            >
                <Select
                    showSearch
                    placement={"Infra"}
                    optionFilterProp="children"
                    placeholder={'Selecione a infra'}
                    disabled={loadingSnapshots}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                        {label: "Verbeux AI", value: "EMBED"},
                        {label: "Multi modelos", value: "MULTI_MODEL"},
                        {value: "CLOUD_RUN", label: "GCP Cloud RUN"}
                    ]}
                />
            </Form.Item>

            <Form.Item
                label="Modelo IA"
                name="aiModel"
                style={{display: user.role === AdminRole ? 'inherit' : 'none'}}
            >
                <Select
                    showSearch
                    loading={aiModelsLoading}
                    optionFilterProp="children"
                    placeholder={'Selecione o modelo de IA'}
                    disabled={loadingSnapshots}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={aiModels}
                />
            </Form.Item>

            <Form.Item
                label="Empresa"
                name="company"
                style={{display: user.role === AdminRole ? 'inherit' : 'none'}}
            >
                <InputSearch
                    size={'large'} popupMatchSelectWidth={false}
                    onChange={(data) => handleSelectChange('company', data)}
                    getCallback={getCompanies} defaultValue={company?.name}/>

            </Form.Item>

            <Form.Item>
                <Button loading={saving} icon={<SaveOutlined/>} type="primary" htmlType="submit">
                    Salvar
                </Button>
                <Button danger style={{marginLeft: '1rem'}} htmlType="button" icon={<DeleteOutlined/>}>
                    Deletar
                </Button>
            </Form.Item>
        </Form>
    )
}
