import React, {useCallback, useContext, useEffect, useState} from "react";
import {Avatar, Button, List, message, Popconfirm, theme} from "antd";
import {Routes, Route, Link} from "react-router-dom";
import AdminLayout from "../../components/admin-layout";
import {Header, Title} from "./styles";
import {deleteUser, getUsers} from "../../services/users";
import {updatedSince} from "../../utils";
import {DeleteOutlined} from "@ant-design/icons";
import CreateUser from "../user-create";
import EditUser from "../user-edit";
import MessageApiContext from "../../context/message-api";

export default function Users() {
    return (
            <AdminLayout>
                <Routes>
                    <Route index path={'/'} element={<ListUsers/>}/>
                    <Route index path={'/:id'} element={<EditUser/>}/>
                    <Route index path={'/create'} element={<CreateUser/>}/>
                </Routes>
            </AdminLayout>
    )
}

function ListUsers() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const messageApi = useContext(MessageApiContext);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);

    const reload = useCallback(async () => {
        setLoading(true);
        const {data, total} = await getUsers(page, perPage);
        setUsers(data);
        setTotal(total);
        setLoading(false);
    }, [page, perPage, setUsers, setTotal, setLoading]);

    useEffect(() => {
        reload().catch(() => messageApi.error("Falha ao carregar usuários"))
    }, [page, perPage])

    return (
        <>

            <List
                orientation={'left'}
                itemLayout={'horizontal'}
                dataSource={users}
                loading={loading}
                header={
                    <Header>
                        <Title>Usuários</Title>
                        <Link to={'create'}>
                            <Button type={'primary'}>Criar</Button>
                        </Link>
                    </Header>
                }
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page
                }}
                renderItem={(item) => <UserContainer reload={reload} data={item}/>}
            />
        </>
    );
}


function UserContainer({data, reload}) {
    const {
        token: {colorPrimaryBg, colorPrimaryActive},
    } = theme.useToken();

    return (
        <List.Item
            actions={[
                <Link to={`${data?.id}`}>
                    <Button type={'text'}>Editar</Button>
                </Link>,
                <Popconfirm
                    title="Você tem certeza?"
                    description="Essa ação vai deletar o usuário ele perderá acesso a plataforma"
                    onConfirm={async () => {
                        await deleteUser(data?.id);
                        await reload().catch(console.error);
                    }}
                    cancelText={'Cancelar'}>
                    <Button danger type={'text'} style={{marginLeft: '1rem'}} icon={<DeleteOutlined/>}/>
                </Popconfirm>
            ]}
        >
            <List.Item.Meta
                title={data?.name}
                avatar={<Avatar size={'large'} style={{
                    backgroundColor: colorPrimaryBg,
                    color: colorPrimaryActive
                }}>{data?.name?.charAt(0)}</Avatar>}
                description={"Criado á " + updatedSince(new Date(data.createdAt))}
            />
        </List.Item>
    )
}
