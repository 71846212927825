import Fetch from "./index";

export const createSession = async (chatbot, model, generative = false) => {
    let route = '/dialog-manager-proxy/';
    let data = {chatbot: parseInt(chatbot), snapshot_version: model}

    if (generative) {
        route = '/generative-dialog-manager-proxy/';
        data.assistant_id = data.chatbot;
        delete data.snapshot_version;
        delete data.chatbot;
    }

    return Fetch(
        route,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
}

export const updateSession = async (id, message, generative = false, fileList) => {
    let route = '/dialog-manager-proxy/';
    if (generative) route = '/generative-dialog-manager-proxy/';

    let request = {
        method: 'PUT',
        body: JSON.stringify({message}),
        headers: {
            "Content-Type": "application/json",
        }
    }

    if (fileList) {
        const body = new FormData();
        body.set("message", message);
        body.set("fileToText", true);
        for (const fileKey in fileList) {
            let fileType = fileList[fileKey].type.split("/")[0];
            if (fileType === "image" && message === "") {
                body.set("message", "Segue a imagem");
            }
            body.set(`file_${fileKey}`, fileList[fileKey]);
        }
        delete request.headers['Content-Type'];
        request.body = body;
    }

    return Fetch(
        route + id,
        request
    );
}
