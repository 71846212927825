import {
    Badge,
    Button,
    Card, Col,
    Dropdown, Empty,
    List,
    message,
    Modal, Progress,
    Row,
    Space,
    Statistic, Tag,
    theme,
    Tooltip,
    Typography
} from "antd";
import {Link, useNavigate} from "react-router-dom";
import React, {useCallback, useContext, useMemo} from "react";
import {
    BulbOutlined,
    CheckCircleOutlined,
    CloseOutlined, CrownOutlined,
    DeleteOutlined,
    EditOutlined, MessageOutlined, MoreOutlined,
    RightOutlined
} from "@ant-design/icons";
import {AdminRole, GenerativeChatbot, Weekdays} from "../../constants";
import {Area, AreaChart, ResponsiveContainer, XAxis} from "recharts";
import MessageApiContext from "../../context/message-api";
import UserContext from "../../context/users";

export default function ChatbotCard({data, reloadList, deleteCall, updateCall, isGenerative = false, setFilters}) {
    const [modal, contextHolder] = Modal.useModal();
    const messageApi = useContext(MessageApiContext);
    const navigate = useNavigate();
    const [user] = useContext(UserContext);

    const updateActive = (chatbot, active) => {
        chatbot.isActive = active
        messageApi.open({type: 'loading', content: active ? 'Ativando...' : 'Desativando...', duration: 0})
        updateCall(chatbot.id, chatbot)
            .then(() => {
                messageApi.destroy();
                messageApi.success('Ativado com sucesso!')
                reloadList();
            })
            .catch(e => {
                messageApi.error('Falha ao desativar')
                console.error(e)
            })
    }

    const chatbotMenuItems = useCallback((chatbot) => [
        {
            label: !chatbot.isActive ? 'Ativar' : 'Desativar',
            icon: !chatbot.isActive ? <CheckCircleOutlined/> : <CloseOutlined/>,
            disabled: chatbot?.active === '',
            onClick: () => {
                updateActive(chatbot, !chatbot.isActive)
            }
        },
        {
            label: 'Editar',
            icon: <EditOutlined/>,
            onClick: () => {
                navigate(chatbot.id.toString());
            }
        },
        {
            danger: true,
            label: 'Deletar',
            icon: <DeleteOutlined/>,
            onClick: async () => {
                const confirmed = await modal.confirm(confirmDelete);
                if (!confirmed)
                    return;

                messageApi.open({type: 'loading', content: 'Deletando chatbot...', duration: 0})
                deleteCall(chatbot.id)
                    .then(() => {
                        messageApi.destroy();
                        messageApi.success('Deletado com sucesso')
                        reloadList();
                    })
                    .catch(e => {
                        messageApi.destroy();
                        messageApi.error('Falha ao deletar')
                        console.error(e)
                    })
            }
        }
    ], [])

    const chatbotLink = useMemo(() => {
        if (isGenerative)
            return `${data.id}`;

        if (data.type === GenerativeChatbot)
            return `/assistants`;

        return data?.active ? `${data.id}/versions/editor/${data.active}` : `${data.id}/versions/`;
    }, [data, isGenerative]);

    const {
        token: {colorPrimary, colorText, colorTextLabel, colorWarning},
    } = theme.useToken();

    return (
        <List.Item>
            {contextHolder}
            <Badge.Ribbon style={{display: data.type === GenerativeChatbot ? "" : "none"}}
                          text={<><BulbOutlined size={8}/> Generativo</>}>
                <Card>
                    <Space style={{width: '100%'}} direction={'vertical'}>
                        <Row justify={'space-between'} align={'center'}>
                            <Link
                                to={chatbotLink}>
                                <Button style={{paddingLeft: 0}} size={'large'} type={'ghost'}>
                                    <Space align={'center'}>
                                        <Badge status={data.isActive ? 'success' : 'error'}/>
                                        <Tooltip title={data.name.length > 12 ? data.name : false}>
                                            <Typography.Title level={4}
                                                              style={{margin: 0}}>
                                                {data.name.length > 12 ? data.name.substring(0, 12) + '...' : data.name}
                                            </Typography.Title>
                                        </Tooltip>
                                    </Space>
                                    <RightOutlined style={{marginLeft: 8}}/>
                                </Button>
                            </Link>
                            <Dropdown trigger={['click']} selectable={false} menu={{
                                items: chatbotMenuItems(data)
                            }}>
                                <Button style={{marginTop: 4}} type={'ghost'} icon={<MoreOutlined/>}/>
                            </Dropdown>
                        </Row>
                        <Row justify={'space-between'} align={'middle'}>
                            <Col>
                                <Statistic prefix={<MessageOutlined/>} suffix={
                                    <Space size={'small'}>
                                        <Typography.Text
                                            style={{color: colorTextLabel}}> / {data?.freeMessages?.toLocaleString()}</Typography.Text>
                                    </Space>
                                } value={data?.messageInMonth}/>
                            </Col>
                            <Col>
                                {user.role === AdminRole && <Button style={{padding: 0}}
                                                                    type={'ghost'}
                                                                    onClick={() => setFilters(old => ({...old, companyID: data.companyID}))}>
                                    <Tag
                                        color={data?.company?.isBlocked ? 'error' : 'success'}>{data?.company?.name}</Tag>
                                </Button>}
                            </Col>
                        </Row>
                        <Space>
                            <Typography.Text>Mensagens grátis</Typography.Text>
                            {data?.paying &&
                                <Tooltip
                                    title={`Este assistente possui mais que ${data?.freeMessages} mensagens no mês atual.`}>
                                    <Tag color={'gold'}>Premium <CrownOutlined/></Tag>
                                </Tooltip>
                            }
                        </Space>
                        <Progress showInfo={false} percent={data?.messageInMonth / data?.freeMessages * 100}
                                  strokeColor={data?.paying ? colorWarning : colorPrimary}/>
                        <ResponsiveContainer width={'100%'} height={125}>
                            {data?.graph?.length > 0 ? <AreaChart data={data.graph.map(data => ({
                                    ...data,
                                    timeRange: Weekdays[new Date(data.timeRange).getDay()]
                                }))}
                                                                  margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                                    <defs>
                                        <linearGradient id="colorTimeRange" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={colorPrimary} stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor={colorPrimary} stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <Area type="monotone" strokeWidth={3} dataKey="number"
                                          stackOffset={'expand'}
                                          fillOpacity={1} fill="url(#colorTimeRange)"
                                          label={({value, x, y}) => <g transform={`translate(${x},${y})`}>
                                              <text x={0} y={-26} dy={16} textAnchor="middle" fill="#666">
                                                  {value}
                                              </text>
                                          </g>}
                                          stroke={colorPrimary}/>
                                    <XAxis dataKey="timeRange" offset={15}/>
                                </AreaChart> :
                                <Empty style={{margin: 0, paddingTop: 30}} image={Empty.PRESENTED_IMAGE_SIMPLE}
                                       description={'Sem dados dos últimos 7 dias'}/>}
                        </ResponsiveContainer>
                    </Space>
                </Card>
            </Badge.Ribbon>
        </List.Item>
    )
}

const confirmDelete = {
    title: 'Tem certeza que deseja deletar?',
    content: (
        <Typography.Text>
            Deletando este chatbot todas as versões serão deletadas junto a ele.
        </Typography.Text>
    ),
    type: "error",
    okText: 'Deletar',
    cancelText: 'Cancelar',
};
