import {createContext} from 'react';

const ActionsContext = createContext({
    train: [],
    confirm: {},
    update: () => {},
    iframeLockEditor: [],
    focusedNode: [],
    loading: [],
});

export default ActionsContext;
