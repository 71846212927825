import {Select} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {debounce} from 'lodash';

export function InputSearch({getCallback, searchField = 'name', ...props}) {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);

    const search = useCallback(debounce((name) => {
        const timeout = setTimeout(async () => {
            setLoading(true);
            let err;
            const {data} = await getCallback({searchField, search: name}).catch(e => err = e);
            setLoading(false)
            if (err) return err;

            setOptions(data.map((item) => ({
                label: item.name,
                value: item.id
            })));
        }, 200)

        return () => clearTimeout(timeout)
    }, 500), [getCallback])

    useEffect(() => {
        const tm = setTimeout(() => {
            search('')
        }, 300)
        return () => clearTimeout(tm);
    }, [getCallback])

    return (
        <Select loading={loading}
                placeholder={'Selecione'}
                options={options}
                showSearch
                filterOption={false}
                style={{minWidth: 120}}
                notFoundContent={null}
                onSearch={search}
                allowClear
                popupMatchSelectWidth={false}
                {...props}
        />
    )
}
