import styled, {css, keyframes} from "styled-components";
import {Handle} from "reactflow";
import {Typography} from "antd";

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: .85;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.colorBgContainer};
  border-radius: ${props => props.borderRadius}px;
  border-width: 2px;
  padding: 8px;
  border-style: solid;
  cursor: grab;
  border-color: ${props => props.border};

  transition: 0.3s all ease;
  ${props => props.isLast && css`
    border-color: ${props => props.primary};
  `}

  ${props => (props.isEditing || props.isFocused) && css`
    border-color: ${props => props.primary};
    box-shadow: 0 0 10px ${props => props.primary};
  `}

  ${props => props.active && css`
    background-color: ${props => props.primaryBg};
  `}

  ${props => props.isLocked && css`
    background-color: #e8e8e8;
    opacity: .7;
  `}
`;

export const Horizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${props => props.margin || '0'};

  div {
    display: flex;
  }
`;

export const Title = styled.input`
  background-color: transparent;
  font-size: 1.2em;
  padding: 0;
  font-weight: bold;
  margin: 0;
  border: 0;
  width: 200px;
`;

export const Button = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;
  align-self: center;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
  color: ${props => props.color || '#3b3b3b'};
  font-size: .6em;

  ${props => props.close && css`
    align-self: flex-end;
  `}
`;

export const Drag = styled.img`
  position: absolute;
  height: 14px;
  user-select: unset;
  right: 12px;
  top: 12px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export const CustomHandleContainer = styled(Handle)`
  opacity: 0;

  ${props => props.isConnecting && css`
    width: calc(100% - 30px);
    border-radius: 0 ${props => props.borderRadius}px ${props => props.borderRadius}px 0;
    height: 100%;
    top: 0;
    right: 0;
    left: unset;
    transform: unset;
    opacity: .85;
    background-color: ${props => props.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    animation: ${appear} .3s ease;
    transition: background-color, opacity .2s ease;

    &:hover {
      background-color: ${props => props.bgColorHover};
      opacity: 1;
    }
  `}
`

export const CustomSourceHandle = styled(Handle)`
  width: 10px;
  bottom: -16px;
  height: 10px;
  opacity: 1;
  background-color: ${props => props.bgColor};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.borderColor};
  ${props => props.isFocused && css`
    border-color: ${props => props.primary};
    box-shadow: 0 0 10px ${props => props.primary};
  `}
`;

export const CustomTargetHandleJumpContainer = styled(Handle)`
  opacity: 0;

  ${props => props.isConnecting && css`
    width: 30px;
    left: 0;
    height: 100%;
    border-width: 0 1px 0 0;
    opacity: .85;
    background-color: ${props => props.bgColor};
    border-style: solid;
    border-color: ${props => props.borderColor};
    border-radius: ${props => props.borderRadius}px 0 0 ${props => props.borderRadius}px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${appear} .2s ease-in;
    transition: background-color, opacity .2s ease;

    &:hover {
      background-color: ${props => props.bgColorHover};
      opacity: 1;
    }
  `}
`;

export const JumpTypografy = styled(Typography.Text)`
  width: 50px;
  position: absolute;
  transform-origin: 0 0;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: rotate(-90deg) translate(-50%, -50%);
  padding: 0;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
`;
