import Fetch from "./index";

export const getSystemConditions = async () => {
    return Fetch("/system/conditions")
}

export const getAiModels = async () => {
    return Fetch("/system/ai-models")
}

export const webhookTest = async ({headers, url, method, body}) => {
    let data = {url, method};
    if (headers && Object.keys(headers).length > 0)
        data.headers = headers;
    if (body && Object.keys(body).length > 0)
        data.body = body;

    return Fetch("/system/webhook/test", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "content-type": "application/json"
        }
    })
}
