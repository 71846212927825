import Fetch from "./index";

export const getUser = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return await Fetch("/user/" + id)
}

export const getMe = async () => {
    return await Fetch("/user/me")
}

export const getUsers = async (page, perPage) => {
    return await Fetch(`/user?page=${page ?? 1}&perPage=${perPage ?? 10}`)
}

export const deleteUser = async (id) => {
    return await Fetch(
        '/user/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const updateUser = async (id, data) => {
    return await Fetch(
        '/user/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createUser = async (data) => {
    return await Fetch(
        '/user/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createPublicUser = async (data) => {
    return await Fetch(
        '/user/public',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        }
    )
}