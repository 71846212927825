import {useCallback, useContext} from 'react';
import ChatBotDataContext from '../context/chatbot-data';
import {useNodesContext} from '../context/nodesProvider';
import {conditionToString} from '../utils/editor';

const useNode = () => {
    const [data, setData] = useContext(ChatBotDataContext);
    const {nodes, setNodes, onNodesChange} = useNodesContext([]);

    const dataNodeToNode = useCallback((node, group) => {

        return {

            id: node.id,
            position: {
                y: node?.position?.y,
                x: node?.position?.x
            },
            dragHandle: '.custom-drag-handle',
            data: {
                ...node,
                isLast: nodes.length > 0 && nodes[nodes.length - 1] === node.id,
                isActive: nodes.includes(node.id),
                isIframe: nodes.isIframe,
                conditionString: conditionToString(data, node?.condition?.split(" ")),
            },
            type: 'node',
            parentNode: group ? group.id : null,
            extent: group ? 'parent' : null,
        }
    })

    function createNode(nodeData) {
        setData(old => {
            return {...old, nodes: {...old.nodes, [nodeData.id]: nodeData}}
        })
        setNodes(old => {
            return [...old, dataNodeToNode(nodeData)]
        })
    }

    function updateNode(id, nodeData) {
        setData(data => {
            const newNodeData = {
                ...data.nodes[id],
                ...nodeData,
            };

            const newData = {
                ...data,
                nodes: {
                    ...data.nodes,
                    [id]: newNodeData,
                },
            };

            setNodes(nodes => {
                const existingNodeIndex = nodes.findIndex(item => item.id === id);
                const existingNode = nodes[existingNodeIndex];
                if (JSON.stringify(existingNode) === JSON.stringify(nodeData)) {
                    return;
                }

                if (existingNodeIndex === -1)
                    return nodes;

                const updatedNodes = [...nodes];
                updatedNodes[existingNodeIndex] = dataNodeToNode({...newNodeData}, nodes);

                return updatedNodes
            });

            return newData;
        });
    }

    function deleteNode(id) {
        if (id === "000000000000000000000000") {
            return;
        }
        setData(old => {
            const newNodes = {...old.nodes}
            delete newNodes[id]
            return {...old, nodes: newNodes}
        })

        setNodes(old => {
            return old.filter(item => item.id !== id)
        })
    }

    function deleteNodes([id]) {
        if (id === "000000000000000000000000") {
            return;
        }
        setData(old => {
            const newNodes = {...old.nodes}
            delete newNodes[id]
            return {...old, nodes: newNodes}
        })

        setNodes(old => {
            return old.filter(item => item.id !== id)
        })
    }

    return {updateNode, createNode, deleteNode, dataNodeToNode, deleteNodes};
};

export default useNode;
