import {Alert, Button} from "antd";
import {ArrowUpOutlined} from "@ant-design/icons";
import React from "react";


export default function UpgradeWarning({message, description, ...props}) {
    return (
        <Alert
            type={'error'}
            message={message}
            description={description}
            action={<Button type={'dashed'} icon={<ArrowUpOutlined/>}>Fazer upgrade</Button>}
            {...props}
        />
    );
}
