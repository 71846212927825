import {Route, Routes} from "react-router-dom";
import AdminLayout from "../../components/admin-layout";
import React from "react";
import ListGenerative from "./List";
import EditGenerative from "./Edit";

export default function Generative() {
    return (
        <AdminLayout>
            <Routes>
                <Route index path={'/'} element={<ListGenerative/>}/>
                <Route path={'/:id/*'} element={<EditGenerative/>}/>
            </Routes>
        </AdminLayout>
    )
}
