import {Alert, Button, Form, Input, message} from 'antd';
import {SaveOutlined} from "@ant-design/icons";
import {useCallback, useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    baseSnapshotVersion,
    createSnapshotVersion,
} from "../../services/snapshot_version";
import MessageApiContext from "../../context/message-api";

export default function VersionCreate() {
    const {id} = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const messageApi = useContext(MessageApiContext);

    const save = useCallback((values) => {
        setSaving(true)
        createSnapshotVersion(baseSnapshotVersion(values?.version, Number(id))).then(() => {
            setSaving(false)
            navigate(`/chatbots/${id}/versions/`);
            messageApi.success("Chatbot criado com sucesso!")
        }).catch(e => {
            console.error(e)
            setSaving(false);
            messageApi.error("Falha ao criar chatbot")
        })
    }, [id])

    return (
        <Form
            name="basic"
            style={{
                maxWidth: 600,
            }}
            layout={'vertical'}
            autoComplete="off"
            onFinish={save}
            form={form}
        >

            <Form.Item
                label="Versão"
                name="version"
                rules={[
                    {
                        required: true,
                        message: 'Esta versão deve ter um nome',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Alert
                message="Atenção"
                description="Aqui não será possível configurar intenções, entidades ou fluxo. Para isso utilize nosso app na página anterior clicando no título da versão.s"
                type="info"
                showIcon/>
            <br/>
            <Form.Item>
                <Button loading={saving} icon={<SaveOutlined/>} type="primary" htmlType="submit">
                    Salvar
                </Button>
            </Form.Item>
        </Form>
    )
}
