import Fetch from "./index";

export const getCompany = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return await Fetch("/company/" + id)
}

export const getMy = async () => {
    return await Fetch("/company/my")
}

export const getCompanies = async (filters) => {
    const filter = new URLSearchParams(filters).toString();

    return await Fetch(`/company?${filter}`)
}

export const updateCompany = async (id, data) => {
    return await Fetch(
        '/company/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createCompany = async (data) => {
    return await Fetch(
        '/company/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}
