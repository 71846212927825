import {Link, Route, Routes} from "react-router-dom";
import {Button, Empty, List, Typography} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {getGenerativeSessions} from "../../../../services/generative-message-logs";
import MessageApiContext from "../../../../context/message-api";
import {EyeOutlined} from "@ant-design/icons";
import {updatedSince} from "../../../../utils";
import View from "./View";

export default function Historic({assistantId}) {
    return (<div>
        <Routes>
            <Route path={'/'} element={<HistoricList assistantId={assistantId}/>}/>
            <Route path={'/:historic_id'} element={<View assistantId={assistantId}/>}/>
        </Routes>
    </div>)
}

function HistoricList({assistantId}) {
    const [historic, setHistoric] = useState([]);
    const messageApi = useContext(MessageApiContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const [total, setTotal] = useState(0)

    useEffect(() => {
        setLoading(true)
        getGenerativeSessions({
            assistantID: assistantId,
            page, perPage
        }).then(({data, total}) => {
            setHistoric(data);
            setTotal(total);
        }).catch((error) => {
            messageApi.error("Erro ao buscar histórico");
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [assistantId, page, perPage]);

    return (<List
        loading={loading}
        pagination={{
            position: "bottom", align: "end", onChange: (page, perPage) => {
                setPage(page);
                setPerPage(perPage);
            }, total, pageSize: perPage, current: page,
        }}
        locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Nenhum histórico encontrado"}/>
        }}
        dataSource={historic}
        header={<Typography.Title level={3}>Histórico</Typography.Title>}
        renderItem={(item) => (<List.Item
            actions={[<Link to={`${item?.sessionId}`}>
                <Button type={'text'} icon={<EyeOutlined/>}/>
            </Link>]}
        >
            <List.Item.Meta
                style={{cursor: 'pointer'}}
                title={<Link to={`${item?.sessionId}`}>
                    {item?.sessionId}
                </Link>}
                description={updatedSince(new Date(item?.createdAt)) + ' atrás | ' + item?.conversations + ' Mensage' + (item?.conversations > 1 ? 'ns' : 'm')}
            />
        </List.Item>)}
    />)
}
