import React, {useCallback, useContext, useMemo, useState} from 'react';
import {getBezierPath} from 'reactflow';

import ChatBotDataContext from "../../context/chatbot-data";
import {Button, Popover, Space, Tooltip} from "antd";
import { FontSizeOutlined, ThunderboltOutlined} from "@ant-design/icons";

const foreignObjectSizeX = 24;
const foreignObjectSizeY = 24;
const defaultInteractionWidth = 20;
const tooltipTimeout = 0.3;

const jump_properties = {
    'jump': {
        icon: <ThunderboltOutlined/>,
        type: 'default',
        description: <div><b>Pulo Regular</b><br/>Pula para o próximo nó somente após a conclusão do atual.</div>
    },
    'jump_and_proccess': {
        icon: <ThunderboltOutlined/>,
        type: 'primary',
        description: <div><b>Pulo Com Processamento</b><br/>Pode pular para o próximo nó ignorando o processamento
            do nó anterior.</div>

    },
    'jump_and_response': {
        icon: <FontSizeOutlined/>,
        type: 'primary',
        description: <div><b>Pulo Regular</b><br/>Pula para o próximo nó somente após a conclusão do atual.</div>

    }
}

export default function JumpEdge({
                                     id,
                                     sourceX,
                                     sourceY,
                                     targetX,
                                     targetY,
                                     source,
                                     target,
                                     sourcePosition,
                                     targetPosition,
                                     style = {},
                                     markerEnd,
                                 }) {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [data, setData] = useContext(ChatBotDataContext);
    const [position, setPosition] = useState({x: 0, y: 0});
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleMouseOver = (e) => {
        let timeout = setTimeout(() => {
            if (!isTooltipOpen) setPosition({x: e.clientX, y: e.clientY});
        }, tooltipTimeout * 100)

        return () => {
            clearTimeout(timeout)
        }
    };

    const updateEdgeType = useCallback((type) => {
        setData(old => {
            const edges = old?.edges;
            edges[source][target].type = type;
            return {...old, edges: {...old.edges}};
        })
    }, [source, target])
    const sourceEdges = useMemo(() => data?.edges[source], [data?.edges, source])
    if (!sourceEdges || sourceEdges?.length < 0){ 
        return <></>;
}
    const IsRegularJump = sourceEdges[target]?.type === "jump";
    const IsProcessJump = sourceEdges[target]?.type === "jump_and_proccess";
    const IsReponseJump = sourceEdges[target]?.type === "jump_and_response";

    const tooltipStyle = {
        position: 'fixed',
        top: IsRegularJump ? position.y - 100 : position.y - 117,
        left: IsRegularJump ? position.x - 125 : position.x - 125,
        pointerEvents: 'none'
    };

    if (!data?.edges[source])
        return <></>;
    return (
        <>
            <Tooltip
                title={jump_properties[sourceEdges[target]?.type]?.description}
                overlayStyle={tooltipStyle}
                mouseEnterDelay={tooltipTimeout}
                placement={"top"}
                onOpenChange={isOpen => setIsTooltipOpen(isOpen)}
            >
                <g
                    id={`${id}-group`}
                    onMouseOver={handleMouseOver}
                >
                    <path
                        id={`${id}-hit-area`}
                        style={{...style, strokeWidth: defaultInteractionWidth, opacity: 0, strokeDasharray: 0}}
                        className="react-flow__edge-path"
                        d={edgePath}
                    />
                    <path
                        id={id}
                        style={{...style}}
                        className="react-flow__edge-path"
                        d={edgePath}
                        markerEnd={markerEnd}
                    />
                </g>

            </Tooltip>
            <foreignObject
                width={foreignObjectSizeX}
                height={foreignObjectSizeY}
                x={labelX - foreignObjectSizeX / 2}
                y={labelY - foreignObjectSizeY / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <Popover content={
                    <Space style={{width: '100%'}} direction={'vertical'}>
                        <Button onClick={() => updateEdgeType('jump')}
                                type={IsRegularJump ? 'primary' : 'dashed'} style={{width: '100%'}}>Regular</Button>
                        <Button onClick={() => updateEdgeType('jump_and_proccess')}
                                type={IsProcessJump ? 'primary' : 'dashed'}
                                style={{width: '100%'}}>Processamento</Button>
                        <Button onClick={() => updateEdgeType('jump_and_response')}
                                type={IsReponseJump ? 'primary' : 'dashed'} style={{width: '100%'}}>Resposta</Button>
                    </Space>
                } trigger={["hover"]} title="TIPOS DE PULO">
                    <Button size={'small'} {...jump_properties[data?.edges[source][target]?.type]}/>
                </Popover>
            </foreignObject>
        </>
    );
}
