export const conditionToString = (data, conditionSplit = []) => {
    let result = "";

    for (const cond of conditionSplit) {
        let splitCond = cond.split('-')
        if (splitCond.length > 1) {
            result += " " + splitCond[0]
            continue
        }

        if (data?.intents[cond]) {
            result += " #" + data?.intents[cond].title
            continue
        }

        if (data?.entitiesValued && data?.entitiesValued[cond]) {
            result += " @" + data?.entitiesValued[cond].title
            continue
        }

        result += " " + cond
    }

    return result
}
