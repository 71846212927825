import React, {useCallback, useContext, useState} from "react";
import {Button, Layout, List, message, Row, Space, Tag, theme, Tooltip, Typography} from "antd";
import {Routes, Route} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";
import {
    CheckOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined, WarningOutlined
} from "@ant-design/icons";

export default function Warnings() {
    const {
        token: {colorBgContainer, borderRadius},
    } = theme.useToken();

    return (
        <Layout.Content style={{margin: '16px', overflowY: 'auto', height: '100%'}}>
            <div style={{padding: 24, borderRadius: borderRadius, background: colorBgContainer}}>
                <Routes>
                    <Route index path={'/'} element={<ListWarnings/>}/>
                    {/*<Route index path={'/:warning_id'} element={<LogsView/>}/>*/}
                </Routes>
            </div>
        </Layout.Content>
    )
}

function ListWarnings() {
    const [data, setData] = useContext(ChatBotDataContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const {
        token: {colorError, colorWarning, colorInfo},
    } = theme.useToken();


    const solveItem = useCallback((index) => {
        setData(old => {
            let logs = old.logs;
            logs.splice(index + ((page - 1) * perPage), 1)
            return {...old, logs}
        })
    }, [setData, page, perPage])

    return (
        <>

            <List
                header={
                    <Row justify={'space-between'} align={'top'} style={{marginBottom: 8}}>
                        <Space direction={'horizontal'}>
                            <Typography.Title level={4}>Avisos</Typography.Title>
                            <Tooltip
                                title={'Avisos de erro de conversão.'}>
                                <Button icon={<QuestionCircleOutlined/>} type={'text'}/>
                            </Tooltip>
                        </Space>
                    </Row>
                }
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total: data?.logs?.length ?? 0,
                    pageSize: perPage,
                    current: page,
                }}
                itemLayout="horizontal"
                dataSource={data?.logs}
                renderItem={(item, i) => (
                    <List.Item
                        actions={[
                            <Button icon={<CheckOutlined/>} onClick={() => solveItem(i)}/>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 12,
                                }}>
                                    {item?.level === 'WARN' &&
                                        <WarningOutlined style={{fontSize: '24px', color: colorWarning}}/>}
                                    {item?.level === 'PANIC' &&
                                        <CloseCircleOutlined style={{fontSize: '24px', color: colorError}}/>}
                                    {item?.level === 'INFO' &&
                                        <InfoCircleOutlined style={{fontSize: '24px', color: colorInfo}}/>}
                                </div>}
                            title={getTitleById(data, item?.field, item?.id)}
                            description={item?.message}
                        />
                    </List.Item>
                )}
            />
        </>
    );
}

function getTitleById(data, type, id) {
    switch (type) {
        case "INTENT":
            return <><Tag>Intenção</Tag> #{data?.intents && data?.intents[id] ? data?.intents[id]?.title : 'N/A'}</>
        case "ENTITY":
            return <>
                <Tag>Entidade</Tag> @{data?.entitiesValued && data?.entitiesValued[id] ? data?.entitiesValued[id]?.title : 'N/A'}</>
        case "RESPONSE":
            return <><Tag>Resposta</Tag> {id ?? 'N/A'}</>;
        case "NODE":
            return <><Tag>Nó</Tag> {data?.nodes[id] ? data?.nodes[id]?.title : 'N/A'} </>;
        case "EDGE":
            return <><Tag>Aresta</Tag> {id ?? 'N/A'}</>;
    }
}
