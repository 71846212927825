import React, {useEffect, useState} from "react";
import {Button, Popconfirm} from "antd";
import {CloseOutlined, DownloadOutlined} from "@ant-design/icons";

export default ({messageApi, data, loading, buttonLoading, uninstall, install}) => {
    const [logging, setLogging] = useState(false);
    const [unavailable, setUnavailable] = useState(false);
    const [requestData, setRequestData] = useState({});

    const onLoginClick = () => {
        setLogging(true);
        try {
            window.FB.login(function (response) {
                if (response.authResponse) {
                    const code = response.authResponse.code;
                    setRequestData((old) => ({...old, code}))
                    messageApi.success("Login realizado com sucesso!");
                } else {
                    messageApi.info("Operação cancelada pelo usuário");
                }
                setLogging(false);
            }, {
                config_id: '1911134839312936',
                response_type: 'code',
                override_default_response_type: true,
                extras: {
                    feature: "whatsapp_embedded_signup",
                    sessionInfoVersion: 3
                }
            });
        } catch (e) {
            messageApi.error("Falha ao realizar operação");
            setLogging(false);
        }
    };

    useEffect(() => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: '370681945902930',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v20.0'
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        try {
            const sessionInfoListener = (event) => {
                if (event.origin == null) {
                    return;
                }

                // Make sure the data is coming from facebook.com
                if (!event.origin.endsWith("facebook.com")) {
                    return;
                }

                try {
                    const data = JSON.parse(event.data);
                    if (data.type === 'WA_EMBEDDED_SIGNUP') {
                        // if user finishes the Embedded Signup flow
                        if (data.event === 'FINISH') {
                            const result = data.data;
                            setRequestData((old) => ({...old, ...result}))
                        }
                        // if user reports an error during the Embedded Signup flow
                        else if (data.event === 'ERROR') {
                            const {error_message} = data.data;
                            console.error("error ", error_message);
                        }
                        // if user cancels the Embedded Signup flow
                        else {
                            const {current_step} = data.data;
                            console.warn("Cancel at ", current_step);
                        }
                    }
                } catch {
                }
            };

            window.addEventListener('message', sessionInfoListener);
        } catch (e) {
            messageApi.error("Falha ao configurar login no meta.");
            setUnavailable(true);
        }
    }, []);

    useEffect(() => {
        const {code, phone_number_id, waba_id} = requestData;
        if (!code || !phone_number_id || !waba_id)
            return;
        install(code, phone_number_id, waba_id)
        setRequestData({});
    }, [requestData]);

    if (data) return (
        <div>
            <Popconfirm title={'Tem certeza?'}
                        onConfirm={uninstall}
                        description={'Essa ação é irreversível. (Você ainda poderá instalar novamente)'}>
                <Button type={'default'}
                        loading={loading || buttonLoading}
                        danger
                        icon={<CloseOutlined/>}
                >{loading ? undefined : 'Remover'}</Button>
            </Popconfirm>
        </div>
    )

    return (
        <div>
            <Button type={'primary'}
                    loading={loading || buttonLoading || logging}
                    onClick={onLoginClick}
                    disabled={unavailable}
                    icon={<DownloadOutlined/>}
            >{loading ? undefined : logging ? 'Logando...' : unavailable ? 'Indisponível' : 'Instalar'}</Button>
        </div>
    );
};
