import styled from "styled-components";


export const Description = styled.div`
  height: 50px;
  overflow: auto;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;

export const Title = styled.h1`
  font-size: 1.4em;
  padding: 0;
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

