import Fetch from "./index";

export const getComponents = async (perPage, page ,withData) => {
    
    return await Fetch("/component/?perPage" + perPage + "&page=" + page + "&withData=" + withData)
}

export const createComponent = async (data) => {
    data.isActive = false;

    return Fetch(
        '/component/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const getComponent = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");
        const response = await Fetch(`/component/${id}`)
        const {data} = await response
        const mappedResponse = {
            data:{
            ...data,
            ...data.data }        
        }
    return mappedResponse
}
export const deleteComponent = async (id)=> {
    return Fetch(
        '/component/' + id,
        {
            method: 'DELETE',
        }
    )
}
export const updateComponent = async (id, data) => {
    const mappedData ={
        id: data.id,
        createdAt: data.createdAt,
        data: {
            status: data.status,
            edges: data.edges,
            entities: data.entities,
            intents: data.intents,
            internalVersion: data.internalVersion,
            modelVersion: data.modelVersion,
            nodes: data.nodes,
            responses: data.responses
        },
        description: data.description,
        company: data.company,
        name: data.name,
        tags: data.tags,
        variables: data.variables
    }
    const response = await Fetch(
        `/component/${id}`,
        {
            method: 'PUT',
            body: JSON.stringify(mappedData),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
    const responseData = response.data
    const mappedResponse = {
        data:{
        ...responseData,
        ...responseData.data }        
    }
    return mappedResponse
}
