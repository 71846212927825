import {Button, Result} from "antd";
import {Link} from "react-router-dom";

export default function CreateChatbotCard({entity, handleCreate, entitySecondary, linkSecondary}) {
    return (
        <Result
            status="404"
            title={`Nenhum ${entity} encontrado`}
            subTitle={`Crie agora um ${entity} para atender seus clientes.`}
            extra={[
                <Button onClick={handleCreate} type="primary" key="criar">
                    Criar
                </Button>,
                <Link to={linkSecondary}>
                    <Button key="buy">Criar {entitySecondary}</Button>
                </Link>,
            ]}
        />
    )
}
