import {Header, Title} from "./styles";
import React, {useContext, useEffect, useState} from "react";
import {
    CheckOutlined,
    CopyOutlined,
    DeleteOutlined,
    LinkOutlined
} from '@ant-design/icons';
import { Button, List, message, Popconfirm, Space, theme, Typography} from "antd";
import { Routes, Route} from "react-router-dom";
import AdminLayout from "../../components/admin-layout";
import SelectFunction from "../select-function";
import "./style.css";
import {createApiKey, deleteApiKey, getApiKeys} from "../../services/apiKeys";
import {updatedSince} from "../../utils";
import UserContext from "../../context/users";
import {AdminRole} from "../../constants";
import MessageApiContext from "../../context/message-api";

export default function ApiKeys() {
    return (
        <Routes>
            <Route path={'/:id/versions/editor/:snapshot/*'} element={<SelectFunction/>}/>
            <Route path={'*'} element={
                <AdminLayout>
                    <Routes>
                        <Route index path={'/'} element={<ApiKeysIndex/>}/>
                        {/*<Route path={'/:id'} element={<ApiKeysView/>}/>*/}
                        {/*<Route path={'/create'} element={<ChatBotCreate/>}/>*/}
                        {/*<Route path={'/:id/versions/*'} element={<Versions/>}/>*/}
                    </Routes>
                </AdminLayout>
            }/>
        </Routes>
    )
}

function ApiKeysIndex() {
    const [page, setPage] = useState(1);
    const [user] = useContext(UserContext);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [apiKeys, setApiKeys] = useState([]);
    const [generating, setGenerating] = useState(false);
    const messageApi = useContext(MessageApiContext);

    useEffect(() => {
        reloadList()
    }, [page, perPage])

    const reloadList = () => {
        setLoading(true);
        getApiKeys(page, perPage).then(({data, total}) => {
            setLoading(false);
            setApiKeys(data);
            setTotal(total);
        }).catch(console.error)
    }

    return (
        <>

            <List
                orientation={'left'}
                itemLayout={'horizontal'}
                header={
                    <Header>
                        <Title>Apikeys</Title>
                        <Space>
                            <a href={'https://docs.verbeux.com.br/docs/intro'} target={'_blank'} rel="noreferrer">
                                <Button icon={<LinkOutlined />} type={'dashed'}>Documentação</Button>
                            </a>
                            <Button loading={generating} type={'primary'} onClick={() => {
                                setGenerating(true);
                                createApiKey(user?.companyID).then(() => {
                                    reloadList()
                                }).catch(e => {
                                    messageApi.error("Falha ao gerar api key")
                                }).finally(() => {
                                    setGenerating(false)
                                })
                            }}>Gerar</Button>
                        </Space>
                    </Header>
                }
                loading={loading}
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page
                }}
                dataSource={apiKeys}
                renderItem={item => (
                    <ApiKeyContainer messageApi={messageApi} reload={reloadList} data={item}/>
                )}
            />
        </>
);
}


function ApiKeyContainer({data, reload, messageApi}) {
    const [user] = useContext(UserContext);

    const {
        token: {colorPrimary, colorPrimaryActive},
    } = theme.useToken();

    return (
        <List.Item
            actions={[
                <Popconfirm
                    title="Você tem certeza?"
                    description="Essa ação vai deletar a apiKey, ela deixará de funcionar"
                    onConfirm={async () => {
                        await deleteApiKey(data?.id).catch(e => {
                            messageApi.error("Falha ao deletar")
                        });
                        await reload().catch(console.error);
                    }}
                    cancelText={'Cancelar'}>
                    <Button danger type={'text'} style={{marginLeft: '1rem'}} icon={<DeleteOutlined/>}/>
                </Popconfirm>
            ]}
        >
            <List.Item.Meta
                title={<Typography.Text copyable={{
                    icon: [<CopyOutlined style={{color: colorPrimary}} key="copy-icon"/>,
                        <CheckOutlined style={{color: colorPrimary}} key="copied-icon"/>],
                    tooltips: ['Copiar', 'Copiado!'],
                }}>{data?.data}</Typography.Text>}
                description={(user.role === AdminRole ? data?.company?.name + " | " : '') + "Criado á " + updatedSince(new Date(data.createdAt))}
            />
        </List.Item>
    )
}
