import React, {useContext, useEffect, useState} from "react";
import {Button, Input, Layout, List, message, Row, Space, theme, Tooltip, Typography} from "antd";
import {Routes, Route, Link} from "react-router-dom";
import {
    EyeOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import {getSessions} from "../../services/conversation-logs";
import {updatedSince} from "../../utils";
import LogsView from "../logs-view";
import ChatBotDataContext from "../../context/chatbot-data";
import MessageApiContext from "../../context/message-api";

export const LogsRoutes = (props) => <Routes>
    <Route index path={'/'} element={<ListLogs {...props}/>}/>
    <Route index path={'/:sessionId'} element={<LogsView {...props}/>}/>
</Routes>;

export default function Logs() {
    const [data] = useContext(ChatBotDataContext);
    const {
        token: {colorBgContainer, borderRadius},
    } = theme.useToken();

    return (
        <Layout.Content style={{margin: '16px', overflowY: 'auto', height: '100%'}}>
            <div style={{padding: 24, borderRadius: borderRadius, background: colorBgContainer}}>
                <LogsRoutes chatbot={data?.chatbot} snapshot={data?.id}/>
            </div>
        </Layout.Content>
    )
}

function ListLogs({chatbot}) {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [total, setTotal] = useState(10);
    const [loading, setLoading] = useState(true);
    const messageApi = useContext(MessageApiContext);
    const [search, setSearch] = useState('')

    useEffect(() => {
        setLoading(true);
        getSessions(
            {
                chatbotId: chatbot,
                perPage,
                page
            })
            .then(({data, total}) => {
                if (search.length > 0) {
                    setList(data.filter(item => item.sessionId.includes(search)))
                    setTotal(data.filter(item => item.sessionId.includes(search)).length)
                } else {
                    setList(data);
                    setTotal(total);
                }
            }).catch(e => {
            messageApi.error("Falha ao carregar logs :(");
        }).finally(() => {
            setLoading(false);
        })
    }, [chatbot, perPage, page, search])

    return (
        <>
            <List
                loading={loading}
                header={
                    <Row wrap={false} justify={'space-between'} align={'top'} style={{marginBottom: 8}}>
                        <Space wrap={false} direction={'horizontal'}>
                            <Typography.Title style={{whiteSpace: 'nowrap'}} level={4}>Histórico</Typography.Title>
                            <Tooltip
                                title={'Histórico de conversas do seu chatbot.'}>
                                <Button icon={<QuestionCircleOutlined/>} type={'text'}/>
                            </Tooltip>

                        </Space>
                        <Space><Input.Search placeholder={'Pesquisar'}
                                             onSearch={(search) => setSearch(search)}/></Space>

                    </Row>
                }
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page,
                }}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (

                    <List.Item
                        actions={[
                            <Link to={`${item?.sessionId}`}>
                                <Button type={'text'} icon={<EyeOutlined/>}/>
                            </Link>
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <Link to={`${item?.sessionId}`}>
                                    {item?.sessionId}
                                </Link>}
                            description={updatedSince(new Date(item?.createdAt)) + ' atrás | ' + item?.conversations + ' Mensage' + (item?.conversations > 1 ? 'ns' : 'm')}
                        />
                    </List.Item>
                )}
            />
        </>
    );
}
