import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Layout, List, Popconfirm, Row, Space, theme, Tooltip, Typography} from "antd";
import {Routes, Route, Link} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import ConversationEdit from "../conversation-edit";
import ConversationCreate from "../conversation-create";

export default function Conversations() {
    const {
        token: {colorBgContainer, borderRadius},
    } = theme.useToken();

    return (
        <Layout.Content style={{margin: '16px', overflowY: 'auto', height: '100%'}}>
            <div style={{padding: 24, borderRadius: borderRadius, background: colorBgContainer}}>
                <Routes>
                    <Route index path={'/'} element={<ListConversations/>}/>
                    <Route index path={'/create'} element={<ConversationCreate/>}/>
                    <Route index path={':conversation'} element={<ConversationEdit/>}/>
                </Routes>
            </div>
        </Layout.Content>
    )
}

function ListConversations() {
    const [data, setData] = useContext(ChatBotDataContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);

    const remove = useCallback((i) => {
        setData(old => {
            old.conversation.splice(i, 1);
            return {...old, conversation: old.conversation};
        })
    }, [data, setData])

    useEffect(() => {
        setTotal(data?.conversation?.length)
    }, [data])

    return (
        <List
            header={
                <Row justify={'space-between'} align={'top'} style={{marginBottom: 8}}>
                    <Space direction={'horizontal'}>
                        <Typography.Title level={4} >Conversas</Typography.Title>
                        <Tooltip
                            title={'Com as conversas você pode treinar o modelo atraves de exemplos de conversas reais.'}>
                            <Button icon={<QuestionCircleOutlined/>} type={'text'}/>
                        </Tooltip>
                    </Space>
                    <Link to={'create'}>
                        <Button type={'primary'}>Criar</Button>
                    </Link>
                </Row>
            }
            pagination={{
                position: "bottom",
                align: "end",
                onChange: (page, perPage) => {
                    setPage(page);
                    setPerPage(perPage);
                },
                total,
                pageSize: perPage,
                current: page
            }}
            itemLayout="horizontal"
            dataSource={data?.conversation}
            renderItem={(item, i) => (
                <List.Item
                    actions={[
                        <Link to={`${i}`}>
                            <Button type={'text'}>Editar</Button>
                        </Link>,
                        <Popconfirm
                            title="Você tem certeza?"
                            description="Essa ação vai deletar todos os exemplos dessa conversa"
                            cancelText={'Cancelar'}
                            onConfirm={() => remove(i)}
                        >
                            <Button type={'text'} danger icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    ]}
                >
                    <List.Item.Meta
                        // avatar={}
                        title={
                            <Link to={`${i}`}>
                                {item?.title}
                            </Link>}
                        description={item?.messages?.length + ' exemplo' + (item?.messages?.length > 1 ? 's' : '')}
                    />
                </List.Item>
            )}
        />
    );
}
