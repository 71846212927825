import Fetch from "./index";

export const train = async (data, pipeline) => {
    if (!data)
        return Promise.reject("missing data");

    return await Fetch(`/train/`, {
        method: 'POST',
        body: JSON.stringify({...data, pipeline}),
        headers: {
            "Content-Type": "application/json",
        }
    })
}
