import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Avatar, Button, List, Popconfirm, Result, Row, Space, Tag, Tooltip, Typography, Dropdown, Menu} from "antd";
import {deleteGenerativeTriggers, getGenerativeTriggers} from "../../../../services/generative-triggers";
import messageApiContext from "../../../../context/message-api";
import {DeleteOutlined, PlusOutlined, ThunderboltOutlined, DownOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import TriggerCreate from "./Create";
import TriggerEdit from "./Edit";
import MessageApiContext from "../../../../context/message-api";
import {createTriggerAction, deleteTriggerAction, getTriggerActions, updateTriggerAction} from "../../../../services/generative-actions";
import ActionEdit from "./Actions/Edit";

export default function Triggers({assistantId}) {
    const [loading, setLoading] = useState(true);
    const [triggers, setTriggers] = useState([]);
    const messageApi = React.useContext(messageApiContext);
    const [createOpen, setCreateOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [total, setTotal] = useState(0)

    const update = useCallback(() => {
        setLoading(true);
        getGenerativeTriggers({assistantId, page, perPage})
            .then(({data}) => {
                setTriggers(data)
                setTotal(data.length)
            })
            .catch((err) => {
                console.error(err);
                messageApi.error("Falha ao carregar gatilhos.");
            }).finally(() => {
            setLoading(false);
        });
    }, [assistantId, page, perPage])

    useEffect(() => {
        if (!assistantId)
            return;

        update();
    }, [assistantId]);

    const handleOpen = useCallback(() => {
        setCreateOpen(true);
    }, [])


    const CreateButton = ({size}) => <Button onClick={handleOpen}
                                             icon={<ThunderboltOutlined/>}
                                             size={size}
                                             type="primary"
                                             key="criar">
        Criar
    </Button>;

    return (<>
        <TriggerCreate onSuccess={update} open={createOpen} assistantId={assistantId} setOpen={setCreateOpen}/>

        <List
            loading={loading}
            dataSource={triggers}
            pagination={{
                position: "bottom",
                align: "end",
                onChange: (page) => {
                    setPage(page);
                },
                total,
                pageSize: 5,
                current: page,
            }}
            header={
                <Row justify={'space-between'}>
                    <Typography.Title level={3}>Gatilhos</Typography.Title>
                    <Space direction={'horizontal'}>
                        <CreateButton size={'middle'}/>
                    </Space>
                </Row>
            }
            locale={{
                emptyText: <Result
                    title={`Gatilhos são eventos que disparam ações no seu assistente.`}
                    subTitle={`Use gatilhos para criar integrações ou qualquer tipo de consulta durante o uso do assistente.`}
                    extra={[
                        <CreateButton size={'large'}/>,
                        <Link target={'_blank'} to={'https://docs.verbeux.com.br'}>
                            <Button size={'large'} key="buy">Documentação</Button>
                        </Link>,
                    ]}
                />
            }}
            renderItem={(item) => {
                return (<TriggerItem onSuccess={update} key={item.id} item={item}/>)
            }}
        />
    </>)
}

const TriggerItem = ({item, onSuccess}) => {
    const [editOpen, setEditOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const [actions, setActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const [countActions, setCountActions] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        if (countActions === 3) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }, [countActions]);

    const addAction = useCallback(async (data) => {
        return createTriggerAction(data.assistantID, data.generativeTriggerID, data)
            .then(() => {
                messageApi.success("Ação criada com sucesso");
                onSuccess();
                getActions();
            }).catch((err) => {
            console.error(err);
            messageApi.error("Falha ao adicionar ação");
        });
    },[]);

    const getActions = useCallback(() => {
        getTriggerActions(item.assistantID, item.id)
            .then((actions) => {
                setActions(() => actions.data.reverse());
                setCountActions(() => actions.data.length);
            })
            .catch((err) => {
                console.error(err);
                messageApi.error("Falha ao carregar ações.");
            });
    }, [item.assistantID, item.id, messageApi]);

    const updateAction = useCallback(async (action) => {
        await updateTriggerAction(item.assistantID, action.generativeTriggerID, action.id, action)
            .then(() => {
                messageApi.success("Ação atualizada com sucesso");
                onSuccess();
                getActions();
            }).catch((err) => {
                console.error(err);
                messageApi.error("Falha ao atualizar ação"); 
            });
    }, []);

    const deleteAction = useCallback(async (actionID) => {
        return deleteTriggerAction(item.assistantID, item.id, actionID)
            .then(() => {
                onSuccess();
                getActions();
            }).catch((err) => {
            messageApi.error("Falha ao deletar ação.");
        });
    }, []);

    const onDelete = useCallback(() => {
        setDeleting(true);
        deleteGenerativeTriggers(item.assistantID, item.id)
            .then(() => {
                onSuccess();
                messageApi.success("Gatilho deletado com sucesso.");
            })
            .catch((err) => {
                console.error(err);
                messageApi.error("Falha ao deletar gatilho.");
            }).finally(() => {
            setDeleting(false);
        });
    }, [item.assistantID, item.id, messageApi, onSuccess]);

    useEffect(() => {
        getActions();
    }, [getActions]);

    const items = actions.slice(0, 3).map((action, index) => ({
        label: `Ação ${index + 1}`,
        key: index,
    }));

    const onClick = ({key}) => {
        setSelectedAction(actions[key]);
    };

    const ButtonActions = () => {
        return (
            <Tooltip title={isButtonDisabled ? "Limite de ações atingido" : ""}>
                <Button disabled={isButtonDisabled}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setSelectedAction(() => -1);
                        }}>
                    Ações
                </Button>
            </Tooltip>
        )
    }

    return (
        <>
            <TriggerEdit onSuccess={onSuccess} open={editOpen} initValues={item} assistantId={item.assistantID}
                         setOpen={setEditOpen}/>
            <ActionEdit
                trigger={item}
                data={selectedAction}
                clear={() => setSelectedAction(null)}
                addAction={addAction}
                deleteAction={deleteAction}
                updateAction={updateAction}
            />

            <List.Item actions={[
                <Dropdown.Button
                    menu={{
                        items,
                        onClick
                    }}
                    buttonsRender={(buttons) => [<ButtonActions key="customButton"/>, buttons[1]]}
                >
                </Dropdown.Button>,
                <Popconfirm cancelText={'Cancelar'}
                            onConfirm={onDelete}
                            title={"Tem certeza?"}
                            description={"Essa ação vai deletar o gatilho e suas ações"}>
                    <Button disabled={deleting} loading={deleting} icon={<DeleteOutlined/>} danger/>
                </Popconfirm>
            ]}>
                <List.Item.Meta
                    title={item.args.name}
                    onClick={() => setEditOpen(true)}
                    style={{cursor: "pointer"}}
                    avatar={<Avatar icon={<ThunderboltOutlined/>}/>}
                    description={item.args.description}/>
            </List.Item>
        </>
    );
};


