import {Button, Col, Form, Input, Row} from 'antd';
import {CloseOutlined, MinusCircleOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import React, {useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";
import ChatBotDataContext from "../../context/chatbot-data";
import {newObjectID} from "../../utils";

export default function EntityCreate() {
    const [form] = Form.useForm();
    const [, setData] = useContext(ChatBotDataContext);
    const navigate = useNavigate();

    const save = useCallback((values) => {
        setData(old => {
            if (!old?.entitiesValued || old?.entitiesValued?.length < 1)
                old.entitiesValued = {};

            const id = newObjectID();
            values.id = id;
            old.entitiesValued[id] = values;
            return {...old};
        });

        navigate('..');
    }, [navigate, setData])

    return (
        <Form
            name="basic"
            style={{
                maxWidth: 600,
            }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            onFinish={save}
        >
            <Form.Item
                label="Título"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'O título é obrigatório',
                    },
                ]}
            >
                <Input placeholder={'Digite um titulo'}/>
            </Form.Item>

            <Form.List name="values"
                       rules={[
                           {
                               validator: async (_, messages) => {
                                   if (!messages || messages.length < 1) {
                                       return Promise.reject(new Error('É necessário 1 exemplo no mínimo'));
                                   }
                               },
                           },
                       ]}
            >
                {(fields, {add, remove}, {errors}) => (<>
                    {fields?.map((field, index) => (<Form.Item
                        key={field.key}
                        label={index === 0 ? 'Valores' : ''}
                        required={true}
                        tooltip={'Digite exemplos e separe os sinonimos por virgula'}
                    >
                        <Row justify={'space-between'} align={'center'}>
                            <Col span={22}>
                                <Row direction={'horizontal'}>
                                    <Col span={8}>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            name={[field.name, "name"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Adicione uma mensagem ou delete esse campo",
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input placeholder={'Valor'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}/>
                                    <Col span={15}>
                                        <Form.List {...field}
                                                   validateTrigger={['onChange', 'onBlur']}
                                                   name={[field.name, "synonyms"]}
                                                   rules={[
                                                       {
                                                           validator: async (_, messages) => {
                                                               if (!messages || messages.length < 1) {
                                                                   return Promise.reject(new Error('É necessário 1 exemplo no mínimo'));
                                                               }
                                                           },
                                                       },
                                                   ]}
                                        >
                                            {(fields, {add, remove}, {errors}) => (<>
                                                {fields?.map((field, index) => <Form.Item
                                                    key={field.key}
                                                    required={true}
                                                    tooltip={'Digite exemplos e separe os sinonimos por virgula'}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        name={[field.name]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Adicione uma mensagem ou delete esse campo",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <Input placeholder={'Sinônimo'} suffix={<Button type={'text'} onClick={() => remove(index)} size={'small'} danger icon={<CloseOutlined/>}/>}/>
                                                    </Form.Item>
                                                </Form.Item>)}
                                                <Button onClick={() => add('')} icon={<PlusOutlined/>}>Novo sinônimo</Button>
                                            </>)}
                                        </Form.List>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={1}>
                                {fields.length > 1 ? (<MinusCircleOutlined
                                    style={{marginTop: '.5rem'}}
                                    className="dynamic-delete-button"
                                    onClick={() => remove(field.name)}
                                />) : null}
                            </Col>
                        </Row>
                    </Form.Item>))}
                    <Form.Item>
                        <Button
                            icon={<PlusOutlined/>}
                            type="dashed"
                            onClick={() => add({})}>Adicionar exemplo</Button>
                    </Form.Item>
                    <Form.Item>
                        <Form.ErrorList errors={errors}/>
                    </Form.Item>
                </>)}
            </Form.List>
            <Form.Item>
                <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                    Criar
                </Button>
            </Form.Item>
        </Form>
    )
}
