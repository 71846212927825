import {Form, Input} from "antd";

export default function SetLabel() {
    return (
        <>
            <Form.Item label={'Nome da etiqueta'} name={['action', 'data', 'name']}>
                <Input placeholder={'Etiqueta'}/>
            </Form.Item>
        </>
    )
}
