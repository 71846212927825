import {Button, Divider, Form, Input, message, Select} from 'antd';
import {LockOutlined, SaveOutlined} from "@ant-design/icons";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { getCompanies} from "../../services/companies";
import {isValidCPF} from "../../utils";
import {createUser} from "../../services/users";
import UserContext from "../../context/users";
import {EditorRole, AdminRole, OwnerRole} from '../../constants';
import MessageApiContext from "../../context/message-api";
import {InputSearch} from "../../components/input-search";

export default function CreateUser() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [user] = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);
    const cpf = Form.useWatch('identity', form);
    const cpfIsValid = useCallback((cpf) => isValidCPF(cpf), []);

    const optionsRoles = <>
        {[AdminRole, OwnerRole].includes(user.role) && <Select.Option value={EditorRole}>Editor</Select.Option>}
        {user.role === AdminRole && <Select.Option value={OwnerRole}>Dono</Select.Option>}
        {user.role === AdminRole && <Select.Option value={AdminRole}>Admin</Select.Option>}
    </>;

    const save = useCallback((values) => {
        setSaving(true)
        if (!values.companyID)
            values.companyID = user.companyID;

        createUser(values).then(() => {
            setSaving(false)
            navigate('/users');
            messageApi.success("Usuário criado com sucesso!")
        }).catch(e => {
            console.error(e)
            setSaving(false);
            messageApi.error("Falha ao criar usuário")
        })
    }, [user, navigate, setSaving, messageApi, createUser])

    return (
        <>

            <Form
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={save}
            >
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Digite um nome para a empresa',
                        },
                    ]}
                >
                    <Input placeholder={'Digite um nome'}/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Digite um email válido'
                        },
                    ]}
                >
                    <Input placeholder={'voce@you.com'}/>
                </Form.Item>

                <Form.Item
                    label={'Permissão'}
                    name={'role'}
                    rules={[{required: true, message: "Permissão não pode ser vazia"}]}
                >
                    <Select placeholder={'Selecione a permissão'}>{optionsRoles}</Select>
                </Form.Item>

                {/*<Form.Item*/}
                {/*    label="CPF"*/}
                {/*    name="identity"*/}
                {/*    hasFeedback*/}
                {/*    validateStatus={!cpfIsValid(cpf) ? 'error' : ''}*/}
                {/*    help={!cpfIsValid(cpf) ? 'CPF inválido' : ''}*/}
                {/*    rules={[*/}
                {/*        {*/}
                {/*            required: false,*/}
                {/*            message: 'CPF deve ser válido',*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    <MaskedInput mask={'000.000.000-00'}/>*/}
                {/*</Form.Item>*/}
                {user.role === AdminRole && <Form.Item
                    name="companyID"
                    label={'Empresa'}
                    rules={
                        [
                            {
                                required: true,
                                message: 'Você deve selecionar a empresa'
                            }
                        ]
                    }
                >
                    <InputSearch getCallback={getCompanies}/>
                </Form.Item>}
                <Divider/>
                <Form.Item
                    name="password"
                    label={'Senha'}
                    validateStatus={confirmPassword !== password ? 'error' : undefined}
                    hasFeedback
                    help={confirmPassword !== password ? 'As senhas não correspondem' : undefined}
                    rules={
                        [
                            {
                                required: true,
                                message: 'Por favor, insira a Senha!',
                            },
                            {
                                min: 6,
                                message: 'A senha deve ter no mínimo 6 caracteres'
                            }
                        ]
                    }
                >
                    <Input
                        prefix={<LockOutlined/>}
                        type="password"
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    hasFeedback
                    label={'Confirmar senha'}
                    help={confirmPassword !== password ? 'As senhas não correspondem' : undefined}
                    validateStatus={confirmPassword !== password ? 'error' : undefined}
                    rules={
                        [
                            {
                                required: true,
                                message: 'Por favor, insira a Senha!',
                            }
                        ]
                    }
                >
                    <Input
                        prefix={<LockOutlined/>}
                        type="password"
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item>
                    <Button loading={saving} icon={<SaveOutlined/>} type="primary" htmlType="submit">
                        Criar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
