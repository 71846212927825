import {Button, Col, Form, Input, Row} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";

export function Response({type, isLocked, isIframe, data, add, remove, onChange, ...props}) {
    if (type === 'text') {
        return <Form.Item rule={{
            max: 600,
            message: 'Digite digite uma mensagem de até 600 caracteres',
        }}>
            <Input.TextArea value={data instanceof Array ? '' : data} showCount placeholder={'Digite um texto de resposta'} maxLength={3000} autoSize
                            disabled={(isIframe && isLocked)} onChange={({target: {value}}) => onChange(value)} {...props}/>
        </Form.Item>;
    }

    return (
        <>{((data instanceof Array) ? data : [{text: ''}])?.map((item, i) =>
                <Form.Item
                    key={i}
                    tooltip={'As respostas serão escolhidas de maneira aleatória'}
                    rules={[
                        {
                            validator: async (_, messages) => {
                                if (!messages || messages.length < 2) {
                                    return Promise.reject(new Error('É necessário 2 mensagens de exemplo no mínimo'));
                                }
                            },
                        },
                    ]}
                >
                    <Row justify={'space-between'} align={'center'}>
                        <Col span={22}>
                            <Form.Item noStyle>
                                <Input.TextArea disabled={(isIframe && isLocked)}
                                                value={item.text}
                                                autoSize
                                                onChange={({target: {value: v}}) => onChange(v, i)}
                                                placeholder={'Digite uma resposta'}
                                                {...props}/>
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            {data.length > 1 ? (<MinusCircleOutlined
                                style={{marginTop: '.5rem', cursor: 'pointer'}}
                                className="dynamic-delete-button"
                                onClick={() => props.disabled ? () => {} : remove(i)}
                            />) : null}
                        </Col>
                    </Row>
                </Form.Item>
            )}
            <Form.Item>
                <Button
                    icon={<PlusOutlined/>}
                    type="dashed"
                    disabled={(isIframe && isLocked)}
                    {...props}
                    onClick={() => add()}
                >Adicionar Opção</Button>
            </Form.Item>
        </>
    )
}
