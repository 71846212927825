import React, { useState } from "react";
import { Card, Empty, theme } from "antd";
import {
	Area,
	AreaChart,
	Bar,
	BarChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
	Cell,
} from "recharts";
export function Overview({ data, graphType }) {
	const [activeIndex, setActiveIndex] = useState(null);
	const {
		token: { colorPrimary, colorBgElevated, colorBgTextActive, colorText },
	} = theme.useToken();

	if (!data || data?.length < 1) {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={"Nenhum dado encontrado"}
			/>
		);
	}

	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<Card
					size="small"
					style={{
						backgroundColor: colorBgElevated,
						color: colorText,
						boxShadow: "rgba(0, 0, 0, 0.1) 1px 2px 10px",
						borderRadius: "4px",
					}}
					className="custom-tooltip"
				>
					<p style={{ textAlign: "center", color: colorText }}>
						{payload[0].payload.name} <br />
					</p>
					<p className="intro">
						<span style={{ color: "#888888" }}>Total: </span>
						{payload[0].value}
					</p>
				</Card>
			);
		}
		return null;
	};

	let graphs = {
		bar: (
			<BarChart
				width={500}
				height={300}
				data={data}
				margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
			>
				<XAxis dataKey="name" fontSize={12} />
				<YAxis fontSize={12} />
				<Tooltip
					cursor={false}
					animationEasing="ease-out"
					content={<CustomTooltip />}
					position={{ y: 0 }}
				/>
				<Bar
					dataKey="total"
					radius={[4, 4, 0, 0]}
					style={{ cursor: "pointer" }}
					onMouseEnter={(_, index) => setActiveIndex(index)}
					onMouseLeave={() => setActiveIndex(null)}
				>
					{data.map((_, index) => (
						<Cell
							key={`cell-${index}`}
							fill={activeIndex === index ? colorBgTextActive : colorPrimary}
						/>
					))}
				</Bar>
			</BarChart>
		),
		area: (
			<AreaChart
				width={500}
				height={300}
				data={data}
				margin={{ top: 5, right: 30, bottom: 5, left: 20 }}
			>
				<defs>
					<linearGradient id="colorTimeRange" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor={colorPrimary} stopOpacity={0.8} />
						<stop offset="95%" stopColor={colorPrimary} stopOpacity={0} />
					</linearGradient>
				</defs>
				<Area
					type="monotone"
					strokeWidth={3}
					dataKey="total"
					stackOffset={"expand"}
					fillOpacity={1}
					fill="url(#colorTimeRange)"
					stroke={colorPrimary}
				/>
				<XAxis dataKey="name" fontSize={12} />
				<YAxis fontSize={12} />
				<Tooltip
					animationEasing="ease-out"
					content={<CustomTooltip />}
					position={{ y: 0 }}
				/>
			</AreaChart>
		),
	};

	return (
		<ResponsiveContainer width="100%" height={350}>
			{graphs[graphType]}
		</ResponsiveContainer>
	);
}
