import { CloudOutlined, ConsoleSqlOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Card, Flex, Typography, theme } from "antd";
import React, { useEffect, useState } from "react";
import { newObjectID } from "../../utils";

function ComponentCard({ onDragStart, value, title, createComponent, author, data }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isIconHovered, setIsIconHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const generateNewIds = () => {
    const newNodes = {};
    const newEdges = {};
    const newResponses = {}
    const idMapping = {};
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const nodeId in value.nodes) {
      const node = value.nodes[nodeId];
      minX = Math.min(minX, node.position.x - 20);
      minY = Math.min(minY, node.position.y - 20);
      maxX = Math.max(maxX, node.position.x + 230);
      maxY = Math.max(maxY, node.position.y + 100);
    }

    const containerWidth = maxX - minX;
    const containerHeight = maxY - minY;

    for (const nodeId in value.nodes) {
      const newId = newObjectID();
      const node = value.nodes[nodeId];
      const newPosition = {
        x: node.position.x - minX,
        y: node.position.y - minY,
      };
      newNodes[newId] = {
        ...node,
        id: newId,
        position: newPosition,
      };
      idMapping[nodeId] = newId;
    }
  
    for (const sourceId in value.edges) {
      newEdges[idMapping[sourceId]] = {};
      if (sourceId !== "000000000000000000000000") {
        for (const edgeId in value.edges[sourceId]) {
          const newId = newObjectID();
          newEdges[idMapping[sourceId]][idMapping[value.edges[sourceId][edgeId].target]] = {
            ...value.edges[sourceId][edgeId],
            id: newId,
            source: idMapping[sourceId],
            target: idMapping[value.edges[sourceId][edgeId].target],
            type: value.edges[sourceId][edgeId].type,
          };
        }
      }
    }
    for (const responseId in value.responses) {
      const newId = newObjectID();
      Object.values(newNodes).find((node) => node.responses && node.responses.includes(responseId) ? newNodes[node.id].responses[node.responses.indexOf(responseId)] = newId : null);
      newResponses[newId] = {
        ...value.responses[responseId],
        id: newId,
      };
    }
  
    return { componentId: value.id, nodes: newNodes, edges: newEdges, title: title, parentWidth: containerWidth, parentHeight: containerHeight, responses: newResponses, intents: value.intents, entities: value.entities, componentId: value.id};
  };
    
    

    
    
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleIconMouseEnter = () => {
    setIsIconHovered(true);
  };
  
  const handleIconMouseLeave = () => {
    setIsIconHovered(false);
  };
  const {token: { colorPrimary, colorPrimaryBg, colorTextBase, fontFamily, borderRadius, fontSize, colorBgBase,colorBgElevated,  colorTextPlaceholder, colorPrimaryBorderHover, colorPrimaryTextHover, padding}} = theme.useToken();

return (
  <Card
    style={{
      cursor: "grab",
      width: "100%",
      padding: "0 8px", 
      position: "relative",
      backgroundColor: colorBgElevated,
      borderRadius: borderRadius, 
      transition: "0.2s",
      borderColor: isHovered ? colorPrimaryTextHover : colorTextPlaceholder,
    }}
    size="small"
    onDragStart={(event) => onDragStart(event, JSON.stringify(generateNewIds()))}
    draggable
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <Flex center style={{ gap: "20px" }}>
    <CloudOutlined size={"large"} style={{fontSize: 30, color: isHovered ? colorPrimaryTextHover : colorTextBase,
            transition: "0.2s",}}
      />
    <Flex vertical>
    <Typography.Title 
    level={5}
          style={{ 
            whiteSpace: "nowrap", 
            fontFamily:fontFamily, 
            color: isHovered ? colorPrimaryTextHover : colorTextBase, 
            maxWidth: "100px",
            overflow: "hidden", 
            textOverflow: "ellipsis",
            margin: 0,
            transition: "0.2s",
          }}
        >
          {title}
        </Typography.Title>
        <Typography.Title level={5}
          style={{ 
            whiteSpace: "nowrap", 
            fontFamily:fontFamily, 
            color: isHovered ? colorPrimaryTextHover : colorTextPlaceholder, 
            fontSize: fontSize * 0.8, 
            overflow: "hidden", 
            textOverflow: "ellipsis" ,
            transition: "0.2s",
            margin: 0,
           
          }}
        >
          {author}
        </Typography.Title>
    </Flex>
    <FileSearchOutlined style={{ transition: "0.2s",position: "absolute", right: 10, top: 10,color: isIconHovered ? colorTextBase : colorTextPlaceholder, cursor: "pointer"}}
    onMouseEnter={handleIconMouseEnter} 
    onMouseLeave={handleIconMouseLeave} />
    </Flex>
    
  </Card>
);
}

export default ComponentCard;