import {Container} from "./styles";
import SelectFunction from "../select-function";

export default function EditorIFrame() {
    return (
        <Container>
            <SelectFunction isIframe={true}/>
        </Container>
    );
}
