import Fetch from "./index";

export const getInvoice = async (id) => {
    if (!id) return Promise.reject("failed to read id");
    return Fetch(`/invoice/${id}`, {
    });
}

export const updateInvoice = async (id) => {
    return Fetch(`/invoice/${id}`, {
        method: 'PUT',
    });
}

export const deleteInvoice = async (companyID, month) => {
    return Fetch(`/invoice/${companyID}`, {
        method: 'DELETE',
        body: JSON.stringify({ month }),
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const createInvoice = async (companyID, month) => {
    return Fetch(`/invoice`, {
        method: 'POST',
        body: JSON.stringify({ companyID, month }),
        headers: {
            "Content-Type": "application/json",
        }
    });
}
