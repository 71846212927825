import Fetch from "./index";

export const getChatBot = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return Fetch("/chatbot/" + id)
}

export const getChatBots = async (filters = {}) => {
    filters["type"] = "FLOW";

    const filter = new URLSearchParams(filters).toString();
    return Fetch(`/chatbot?${filter}`)
}

export const updateChatBot = async (id, data) => {
    return Fetch(
        '/chatbot/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const migrateToEmbed = async (id) => {
    return Fetch(
        '/chatbot/' + id + '/migrate-embed',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            }
        },
    );
}

export const deleteChatbot = async (id) => {
    return Fetch(
        '/chatbot/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createChatbot = async (data) => {
    data.isActive = false;

    return Fetch(
        '/chatbot/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createChatbotFormData = async ({name, description, type, filesData}) => {
    const data = new FormData();
    data.set("name", name);
    data.set("description", description);
    data.set("type", type);
    for (const fileKey in filesData) {
        data.set(`docs_${fileKey}`, filesData[fileKey]?.originFileObj);
    }

    return Fetch(
        '/chatbot/',
        {
            method: 'POST',
            body: data
        },
    )
}
