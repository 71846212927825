import Fetch from "./index";

export const getSessions = async ({
                                      perPage = 10,
                                      page = 1,
                                      order = "DESC",
                                      searchField = "state.snapshot_version",
                                      field = "createdAt",
                                      ...rest
                                  }) => {
    const filters = {perPage, page, order ,field, ...rest};
    for (let key in filters) {
        if (!filters[key])
            delete filters[key];
    }

    const filter = new URLSearchParams(filters).toString();
    return Fetch(`/conversation-log-elastic/sessions?${filter}`);
}

export const getSessionHistory = async (sessionId, page, perPage) => {
    if (!sessionId)
        return Promise.reject("missing session id");

    return Fetch(`/conversation-log-elastic?sessionId=${sessionId}&order=ASC&field=createdAt&page=${page}&perPage=${perPage}`);
}
