import {Button, Card, Skeleton, Space, Typography} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import React from "react";

export default function IntentSuggestions({addAll, removeAll, addSuggestion, removeSuggestion, suggestions, loading}) {
    return (
        <Card
            actions={[<Button onClick={addAll} disabled={loading} type={'default'} icon={<CheckOutlined/>}>Aceitar
                todas</Button>,
                <Button onClick={removeAll} disabled={loading} icon={<CloseOutlined/>} type={'text'}
                        danger>Negar</Button>]}>
            <Skeleton title={'Sugestões'} active loading={loading}>
                <Card.Meta title={'Sugestões'}
                           description={
                               <Space direction={'vertical'}>
                                   {suggestions.map((item, i) =>
                                       <Space direction={'horizontal'}>
                                           <Space direction={'horizontal'}>
                                               <Button onClick={() => addSuggestion(i)} size={'small'}
                                                       icon={<CheckOutlined/>}/>
                                               <Button onClick={() => removeSuggestion(i)} danger type={'text'}
                                                       size={'small'}
                                                       icon={<CloseOutlined/>}/>
                                           </Space>
                                           <Typography.Text key={i}>{item}</Typography.Text>
                                       </Space>
                                   )}
                               </Space>
                           }
                />
            </Skeleton>
        </Card>
    );
}
