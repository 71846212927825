import Fetch from "./index";

export const getWhatsappInstance = async ({assistantID}) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    return Fetch("/whatsapp-instance/?assistantID=" + assistantID)
}

export const createWhatsappInstance = async (body) => {
    const data = new FormData();
    for (const key in body) {
        data.set(key, body[key]);
    }

    return Fetch(
        '/whatsapp-instance/',
        {
            method: 'POST',
            body: data,
        },
    )
}

export const deleteWhatsappInstance = async (id) => {
    return Fetch(
        '/whatsapp-instance/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const updateWhatsappInstance = async (id, body) => {
    delete body['createdAt']
    delete body['updatedAt']
    delete body['deletedAt']
    delete body['id']

    const data = new FormData();
    for (const key in body) {
        data.set(key, body[key]);
    }

    return Fetch(
        '/whatsapp-instance/' + id,
        {
            method: 'PUT',
            body: data,
        },
    )
}

export const verifyPhoneNumber = (id) => {
    return Fetch(
        '/whatsapp-instance/' + id + '/verify-phone-number',
        {
            method: 'POST',
        },
    )
}

export const configureWebhook = (id) => {
    return Fetch(
        '/whatsapp-instance/' + id + '/configure-webhook',
        {
            method: 'POST',
        },
    )
}

export const registerNumber = (id, pin) => {
    return Fetch(
        '/whatsapp-instance/' + id + '/register-number',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({pin})
        },
    )
}
