import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Mentions,
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined,
    SendOutlined,
} from "@ant-design/icons";

import MessageApiContext from '../../../../../../context/message-api';
import {webhookTest} from '../../../../../../services/system';

const WebhookForm = ({form, initValues: data, parameters}) => {
    const body = Form.useWatch('body', form);
    const messageApi = useContext(MessageApiContext);
    const [bodyInvalid, setBodyInvalid] = useState(false);
    const [testLoading, setTestLoading] = useState(false);
    const [response, setResponse] = useState('');
    const [mentions, setMentions] = useState([]);

    const testResult = parameters?.properties?.reduce((acc, property) => {
        acc[property.name] = 'exemplo';
        return acc;
    }, {});

    const testBody = JSON.stringify(testResult, null, 2);

    const arrayToObject = useCallback(({array}) => {
        let result = {};
        for (var i = 0; i < array?.length; i++) {
            result[array[i].key] = array[i].value;
        }
        return result;
    }, []);

    useEffect(() => {
        if (data && data !== -1) {
            form.setFieldsValue({
                ...data.action,
                headers: Object.entries(data.action.header || {}).map(([key, value]) => ({key, value})),
                body: data.action.body
            });
        }
    }, [data]);

    useEffect(() => {
        if (!body) return;

        try {
            JSON.parse(body);
            setBodyInvalid(false);
        } catch (e) {
            setBodyInvalid(true);
        }
    }, [body]);

    useEffect(() => {
        if (parameters) {
            parameters?.properties.forEach((parameter) => {
                let name = parameter?.name;
                setMentions([...mentions, {label: name, value: name}]);
            })
        }

    }, [parameters])

    const test = useCallback(({method, url, body, headers}) => {
        let finalHeaders = arrayToObject({array: headers});

        setTestLoading(true);
        webhookTest({
            headers: finalHeaders,
            body,
            method,
            url
        }).then(({data}) => {
            setResponse(JSON.stringify(data, null, 4));
            messageApi.info("Teste enviado")
        }).catch(e => {
            messageApi.error("Falha ao realizar request, mais detalhes no console");
            console.error(e);
        }).finally(() => setTestLoading(false));
    }, [arrayToObject, messageApi]);

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={20}>
                    <Form.Item
                        name={'url'}
                        rules={[{
                            required: true, message: 'URL é obrigatório',
                        }, {
                            pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                            message: 'URL inválido',
                        }]}
                    >
                        <Mentions prefix='$' split='' placeholder='URL da requisição'
                                  options={mentions}/>
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <Form.Item
                        name={'method'}
                        rules={[{
                            required: true, message: "Selecione o método"
                        }]}
                    >
                        <Select
                            placeholder={'Selecione o método'}
                            options={["POST", "GET", "PUT", "DELETE"].map((value) => ({value, label: value}))}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.List style={{width: '100%'}} name="headers">
                {(fields, {add, remove}) => (<>
                    {fields.map((field, index) => (<Form.Item
                        required={true}
                        label={index === 0 && 'Headers'}
                        style={{marginBottom: 0, width: '100%'}}
                        key={field.key}
                    >
                        <Row gutter={[8, 8]} align={'center'}>
                            <Col span={8}>
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{
                                        required: true,
                                        whitespace: true,
                                        message: "Adicione uma chave ou delete esse campo",
                                    },]}
                                    name={[field.name, 'key']}
                                >
                                    <Input placeholder="Chave"/>
                                </Form.Item>
                            </Col>
                            <Col span={15}>
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{
                                        required: true,
                                        whitespace: true,
                                        message: "Adicione um valor ou remova o campo",
                                    },]}
                                    name={[field.name, 'value']}
                                >
                                    <Mentions prefix='$' split='' placeholder='Valor'
                                              options={mentions}/>
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Button onClick={() => remove(field.name)} icon={<MinusCircleOutlined/>}/>
                            </Col>
                        </Row>
                    </Form.Item>))}
                    <Form.Item style={{marginBottom: '1rem'}}>
                        <Button
                            type="dashed"
                            onClick={() => {
                                add({key: '', value: ''});
                            }}
                            icon={<PlusOutlined/>}
                        >
                            Adicionar Header
                        </Button>
                    </Form.Item>
                </>)}
            </Form.List>
            <Form.Item
                name={'body'}
                label={'Corpo da Requisição'}
                help={'Esse é um exemplo do corpo da requisição para teste'}
                validateStatus={bodyInvalid ? 'error' : ''}
                hasFeedback
                initialValue={testBody}
            >
                <Input.TextArea rows={4} maxLength={2000} placeholder={'Body enviado na request'}/>
            </Form.Item>
            <Row justify={'end'}>
                <Form.Item>
                    <Button loading={testLoading} icon={<SendOutlined/>}
                            onClick={test}
                            type={'primary'}>
                        Testar
                    </Button>
                </Form.Item>
            </Row>
            {response?.length > 0 &&
                <Input.TextArea size={'small'} style={{resize: "none"}} value={response} rows={4}/>}
            <Divider style={{marginTop: response?.length > 0 ? 'inherit' : 0}}/>
        </>
    );
};

export default WebhookForm;
