import {
    Link,
    Route,
    Routes as Switch,
    useParams,
} from "react-router-dom";
import Editor from "../editor";
import Navbar from "../../components/navbar";
import Provider, {useComponentData} from "../../components/provider";
import {
    Button,
    Card,
    Col,
    Empty,
    Flex,
    Layout,
    List,
    message,
    Modal,
    Popconfirm,
    Row,
    Space,
    Statistic,
    theme,
    Typography
} from "antd";

import React, {useCallback, useContext, useEffect, useState} from "react";
import ChatBotDataContext from "../../context/chatbot-data";
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined, CopyOutlined, DeleteOutlined, EditOutlined,
    LoadingOutlined, RocketOutlined, SettingOutlined, StarOutlined,
    WarningOutlined
} from "@ant-design/icons";
import Loading from "../../components/loading";
import ActionsContext from "../../context/actions";
import Intents from "../intents";
import Entities from "../entities";
import Conversations from "../conversations";
import {getChatBot, migrateToEmbed, updateChatBot} from "../../services/chatbot";
import {KeyIFrameLocalStorage} from "../../components/context-action-input";
import Logs from "../logs";
import Warnings from "../warnings";
import {getDashboard} from "../../services/dashboard";
import dayjs from "dayjs";
import UserContext from "../../context/users";
import {DashboardGraphs} from "../../components/dashboard-graphs";
import {getSnapshotVersions} from "../../services/snapshot_version";
import {updatedSince} from "../../utils";
import {AdminRole} from "../../constants";
import {getComponent} from "../../services/components";
import {getAiModels} from "../../services/system";
import MessageApiContext from "../../context/message-api";

export default function SelectFunction({isIframe}) {
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    const isComponent = window.location.pathname.split("/")[1] === "components";
    if (isIframe) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('apiKey')
        if (token) localStorage.setItem(KeyIFrameLocalStorage, token);
    }
    return (
        <Provider isIframe={isIframe}>
            <Layout className="site-layout">
                <Layout.Header style={{padding: 0, background: colorBgContainer}}>
                    <Navbar isIframe={isIframe} isComponent={isComponent}/>
                </Layout.Header>
                <Layout.Content>
                    <Content isComponent={isComponent}/>
                </Layout.Content>
            </Layout>
        </Provider>
    );
}

function Content({isComponent}) {
    const [loading] = useContext(ActionsContext)['loading'];

    if (loading)
        return <Loading title={'Carregando chatbot...'}/>

    return (
        <Switch>
            <Route path={'intents/*'} element={<Intents/>}/>
            <Route path={'conversations/*'} element={<Conversations/>}/>
            <Route path={'logs/*'} element={<Logs/>}/>
            <Route path={'warnings/*'} element={<Warnings/>}/>
            <Route path={'entities/*'} element={<Entities/>}/>
            <Route path={'dashboard'} index element={<Index isComponent={isComponent}/>}/>
            <Route path={''} element={<Editor/>} errorElement={<h1>Deu ruim</h1>}/>
        </Switch>
    );
}

const steps = {
    "WAITING": 0,
    "TRAINING": 1,
    "TRAIN_DONE": 2,
    "UPDATING_SERVICE": 2,
    "TRAIN_ERROR": 3,
    "DONE": 4,
    "EDITING": 5,
}

const stepText = ["Fila", "Treinando", "Roteando", "Falha", "Treinado", "Editando"]
const stepIcon = [<WarningOutlined/>, <LoadingOutlined/>, <LoadingOutlined/>, <CloseCircleOutlined/>,
    <CheckOutlined/>, <EditOutlined/>]

const defaultDate = [
    dayjs().subtract(7, 'day'),
    dayjs(),
]

function Index({isComponent}) {
    const {id} = useParams()
    const [data, setData] = useContext(ChatBotDataContext);
    const [current, setCurrent] = useState(0);
    const [updateModelOpen, setUpdateModelOpen] = useState(false);
    const [graphData, setGraphData] = useState({});
    const messageApi = useContext(MessageApiContext);
    const [chatbot, setChatbot] = useState({});
    const [versions, setVersions] = useState([]);
    const [graphLoading, setGraphLoading] = useState(true);
    const [, setIsTraining] = useContext(ActionsContext)['train'];
    const [user] = useContext(UserContext);
    const [chatbotLoading, setChatbotLoading] = useState(false);
    const [activateLoading, setActivateLoading] = useState(false);
    const [activateChatbotLoading, setActivateChatbotLoading] = useState(false);
    const {setComponentData} = useComponentData();

    const {
        token: {colorPrimary, colorSuccessText, colorErrorText, colorText},
    } = theme.useToken();

    useEffect(() => {
        setCurrent(steps[data?.status]);
    }, [data.status, setCurrent])

    useEffect(() => {
        if (!data?.id) {
            return;
        }

        loadChatbot();
        if (isComponent) return;
        loadGraph();
        getVersions();
    }, [data?.id, chatbot?.id])

    const defaultFilters = {
        serviceID: data.chatbot,
        startDate: defaultDate[0].toISOString(),
        endDate: defaultDate[1].toISOString()
    };

    const updateActive = (active) => {
        setActivateChatbotLoading(true);
        updateChatBot(chatbot.id, {...chatbot, isActive: active})
            .then(() => {
                messageApi.success('Ativado com sucesso!')
                setChatbot(old => ({...chatbot, isActive: active}))
            })
            .catch(e => {
                messageApi.error('Falha ao desativar')
                console.error(e)
            }).finally(() => {
            setActivateChatbotLoading(false);
        })
    }

    const changeProdModel = useCallback((id) => {
        setActivateLoading(true);
        updateChatBot(chatbot.id, {...chatbot, active: id}).then(() => {
            setChatbot(old => ({...old, active: id}));
            messageApi.success("Modelo ativo com sucesso!")
        }).catch(e => {
            console.error(e)
            messageApi.error("Falha ao ativar modelo, tente novamente...")
        }).finally(() => setActivateLoading(false))
    }, [chatbot])

    function loadGraph() {
        setGraphLoading(true)
        let filters = {...defaultFilters}
        filters.startDate = defaultFilters.startDate.substring(0, 10) + 'T00:00:00Z';
        filters.endDate = defaultFilters.endDate.substring(0, 10) + "T23:59:59Z";
        filters.service = "CHATBOT";

        getDashboard(filters).then(({data}) => {
            setGraphData(data)
        }).catch(e => {
            console.error(e);
            messageApi.error("Falha ao carregar dashboard");
        })
            .finally(() => setGraphLoading(false));
    }

    const getVersions = useCallback(() => {
        if (!chatbot?.id)
            return;

        getSnapshotVersions(chatbot.id, 1, 2).then(({data}) => {
            setVersions(data)
        }).catch(console.error);
    }, [chatbot?.id])

    function loadChatbot() {
        setChatbotLoading(true)
        if (isComponent) {
            return getComponent(id)
                .then(({data}) => setComponentData(data))
                .catch(() => messageApi.error("Falha ao carregar o componente"))
                .finally(() => setChatbotLoading(false))
        }
        getChatBot(data?.chatbot)
            .then(({data}) => setChatbot(data))
            .catch(() => messageApi.error("Falha ao carregar o chatbot"))
            .finally(() => setChatbotLoading(false))
    }

    const migrateEmbed = useCallback(() => {
        migrateToEmbed(chatbot?.id).then(() => {
            setUpdateModelOpen(false);
            setIsTraining(true);
            messageApi.success('Migração iniciada!')
        }).catch(e => {
            console.error(e)
            messageApi.error('Falha ao migrar')
        })
    }, [chatbot?.id])

    return (
        <>

            <Modal title="Atualize agora sua inteligência artificial!" open={updateModelOpen} cancelText={'Cancelar'}
                   onOk={migrateEmbed} onCancel={() => setUpdateModelOpen(false)}>
                <Typography.Paragraph>- Pode haver um downtime (tempo offline) de aproximadamente 1
                    minuto.</Typography.Paragraph>
                <Typography.Paragraph>- Não será mais necessário treinar a IA a cada nova inserção de
                    intenções.</Typography.Paragraph>
                <Typography.Paragraph>- Maior acertividade e previsibilidade.</Typography.Paragraph>
                <Typography.Paragraph>- Suporte a multiplas linguas.</Typography.Paragraph>
            </Modal>
            <Layout.Content style={{margin: '16px', overflowY: 'auto', height: '100%'}}>
                <Row gutter={[12, 12]}>
                    <Col span={18}>
                        <Space style={{width: '100%'}} direction={'vertical'}>
                            <Card>
                                <Row align={'middle'} justify={'space-between'}>
                                    <Col>
                                        <Typography.Title
                                            editable={{
                                                onChange: (v) => isComponent ? setData(old => ({
                                                    ...old,
                                                    name: v
                                                })) : setData(old => ({...old, version: v})),
                                                icon: <EditOutlined style={{color: colorPrimary}}/>,
                                                tooltip: 'Editar'
                                            }}
                                            style={{padding: 0, margin: 0}}
                                            level={2}>
                                            {isComponent ? data.name : data?.version}
                                        </Typography.Title>
                                        <Space>
                                            <Typography.Text>{isComponent ? "Componente" : "Chatbot"}:</Typography.Text>
                                            <Typography.Text keyboard title={'test'} copyable={{
                                                icon: [<CopyOutlined style={{color: colorPrimary}} key="copy-icon"/>,
                                                    <CheckOutlined style={{color: colorPrimary}} key="copied-icon"/>],
                                                tooltips: ['Copiar', 'Copiado!'],
                                            }}>{isComponent ? data?.id : chatbot?.id}</Typography.Text>
                                            {!isComponent && <Typography.Text>Versão: </Typography.Text>}
                                            {!isComponent && <Typography.Text keyboard title={'test'} copyable={{
                                                icon: [<CopyOutlined style={{color: colorPrimary}} key="copy-icon"/>,
                                                    <CheckOutlined style={{color: colorPrimary}} key="copied-icon"/>],
                                                tooltips: ['Copiar', 'Copiado!'],
                                            }}>{data?.id}</Typography.Text>}
                                        </Space>
                                    </Col>
                                    <Col>
                                        <Space direction={'horizontal'}>
                                            {!isComponent && chatbot?.infra === 'MULTI_MODEL' && chatbot?.active === data?.id && user.role === AdminRole &&
                                                <Button type={'primary'} icon={<RocketOutlined/>}
                                                        onClick={() => setUpdateModelOpen(true)}>Atualizar
                                                    modelo</Button>}
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                            {isComponent ? <Card>
                                <Empty/>
                            </Card> : <DashboardGraphs graphType={'area'} withList={false} data={graphData}
                                                       loading={graphLoading}/>}
                        </Space>
                    </Col>
                    <Col span={6}>
                        <Space style={{width: '100%'}} direction={'vertical'}>
                            <Card loading={chatbotLoading}>
                                {!isComponent && <Statistic
                                    title="Estado do Modelo"
                                    value={stepText[current ?? 5]}
                                    valueStyle={{color: current === 4 ? colorSuccessText : current === 3 ? colorErrorText : colorText}}
                                    prefix={stepIcon[current ?? 5]}
                                />}
                            </Card>
                            <Card title={isComponent ? "Tags" : null} loading={chatbotLoading}>
                                {!isComponent ? <Statistic
                                    title="Estado do Chatbot"
                                    value={chatbot?.isActive ? 'Ativo' : 'Desativado'}
                                    valueStyle={{color: chatbot?.isActive ? colorSuccessText : colorErrorText}}
                                    prefix={chatbot?.isActive ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
                                /> : <>
                                    {data.tags.length > 0 ? data.tags.map((tag, i) => {
                                        return (
                                            <Card size="small"><Flex justify="space-between" align="center">
                                                <Typography.Text>{tag}</Typography.Text>
                                                <Popconfirm
                                                    title="Deletar tag"
                                                    onConfirm={() => {
                                                        const newTags = data.tags.filter((t, index) => index !== i)
                                                        setData(old => ({...old, tags: newTags}))
                                                    }}
                                                    description={`Tem certeza que deseja deletar a tag ${tag}?`}>
                                                    <Button icon={<DeleteOutlined style={{color: colorErrorText}}/>}>

                                                    </Button>
                                                </Popconfirm>
                                            </Flex>
                                            </Card>)
                                    }) : null}
                                </>
                                }
                                {!isComponent && !chatbot?.isActive &&
                                    <Button loading={activateChatbotLoading} onClick={() => updateActive(true)}
                                            disabled={!chatbot?.active} style={{marginTop: 8}}
                                            type="primary">
                                        Ativar
                                    </Button>}
                            </Card>
                            {!isComponent && <Card loading={chatbotLoading}>
                                <Statistic
                                    title="Modelo ativo"
                                    value={chatbot?.active === data.id ? 'Sim' : 'Não'}
                                    valueStyle={{color: chatbot?.active === data.id ? colorSuccessText : colorErrorText}}
                                    prefix={chatbot?.active === data.id ? <CheckCircleOutlined/> :
                                        <CloseCircleOutlined/>}
                                />
                                {chatbot?.active !== data.id &&
                                    <Button loading={activateLoading} onClick={() => changeProdModel(data.id)}
                                            style={{marginTop: 8}} type="primary">
                                        Ativar
                                    </Button>}
                            </Card>}
                            {!isComponent && <Card>
                                <List
                                    header={<Row justify={'space-between'}>
                                        <Typography>Versões</Typography>
                                        <Link to={`../../${chatbot.id}/versions`}><Button type={'link'}>Ver
                                            todas</Button></Link>
                                    </Row>}
                                    dataSource={versions}
                                    loading={chatbotLoading}
                                    renderItem={(item) => <List.Item>
                                        <List.Item.Meta
                                            title={<Link to={`../../${chatbot.id}/versions/editor/${item.id}`}>
                                                {item.version.length > 30 ? item.version.substring(0, 30) + '...' : item.version}
                                            </Link>}
                                            description={"Editado á " + updatedSince(new Date(item.updatedAt))}
                                        />
                                    </List.Item>}
                                />
                            </Card>}
                        </Space>
                    </Col>
                </Row>
            </Layout.Content>
        </>
    )
}
