import {ButtonClose, Container, Position} from "./styles";
import {theme} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {Rnd} from "react-rnd";
import {useCallback, useEffect, useState} from "react";

export default function FloatingContainer({
                                              wrapper,
                                              children,
                                              y = 20,
                                              x,
                                              uid,
                                              height = 300, width = 300,
                                              onClose = () => {
                                              }
                                          }) {
    const {
        token: {colorBgContainer, borderRadius, boxShadow, colorBorder},
    } = theme.useToken();
    const [defaultData, setDefaultData] = useState({});

    useEffect(() => {
        let data = localStorage.getItem(`pos-${uid}`);
        if (data) {
            let {x, y, width, height} = JSON.parse(data)
            setDefaultData({x, y, width, height});
        } else
            setDefaultData({
                x: x ?? (wrapper?.current?.offsetWidth - 300 - 20),
                y: y,
            });
    }, [x, y, wrapper, uid]);

    const saveData = useCallback(({x, y, width, height}) => {
        localStorage.setItem(`pos-${uid}`, JSON.stringify({
            x, y, width, height
        }))
    }, [uid]);

    if (!wrapper?.current || Object.keys(defaultData).length <= 0)
        return <></>;

    return (
        <Rnd
            bounds={".wrapper"}
            default={defaultData}
            minHeight={height}
            minWidth={width}
            onDragStop={
                (_, data) =>
                    saveData({
                        width: data.node.style.width,
                        height: data.node.style.height,
                        x: data.x,
                        y: data.y
                    })
            }
            onResizeStop={
                (_, __, ref, ___, pos) =>
                    saveData({
                        width: ref.style.width,
                        height: ref.style.height,
                        ...pos
                    })
            }
        >
            <Position>
                <ButtonClose background={colorBgContainer} borderColor={colorBorder} onClick={onClose}
                             boxShadow={boxShadow} type={'default'} danger icon={<CloseOutlined/>}/>
                <Container borderColor={colorBorder} colorBgContainer={colorBgContainer} borderRadius={borderRadius}
                           boxShadow={boxShadow}>
                    {children}
                </Container>
            </Position>
        </Rnd>
    );
}
