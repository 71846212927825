import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {isEdge, NodeToolbar, useReactFlow, useStore} from 'reactflow';
import {
    Container,
    CustomHandleContainer,
    CustomSourceHandle,
    CustomTargetHandleJumpContainer,
    JumpTypografy
} from "./styles";
import {
    Button,
    Col,
    Divider,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Tag,
    theme,
    Tooltip,
    Typography
} from "antd";
import {
    ArrowLeftOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    SendOutlined
} from "@ant-design/icons";
import {Locker} from "../locker";
import {newObjectID} from "../../utils";
import {webhookTest} from "../../services/system";
import NodeEditor from "../node-editor";
import ChatBotDataContext from "../../context/chatbot-data";
import MessageApiContext from "../../context/message-api";
import FlowContext from "../../context/flow";


const connectionNodeIdSelector = (state) => state.connectionNodeId;
export default memo(({data, isConnectable, selected}) => {
    const [nodeData, setNodeData] = useState(data);
    const connection = useStore(connectionNodeIdSelector);
    const {deleteElements, addNodes} = useReactFlow();
    const isConnecting = !!connection;
    const isIframe = data.isIframe;
    const { flow } = useContext(FlowContext);

    const [modalOpen, setModalOpen] = useState(false);
    const isFullLocked = nodeData?.lock?.title && nodeData?.lock?.condition && nodeData?.lock?.response && nodeData?.lock?.contextActions && nodeData?.lock?.move && nodeData?.lock?.delete;
    // const groupButtonRef = useContext(ActionsContext)['groupButtonRef']
    const groupButtonRef = useRef(null);
    // const nodeRef = useContext(ActionsContext)['nodeRef']
    const nodeRef = useRef(null);

    useEffect(() => {
        setNodeData(data);
    }, [data])

    const httpActions = useMemo(() =>
            nodeData?.actions?.filter(({type}) => type === 'HTTP_REQUEST'),
        [nodeData.actions]);

    const {
        token: {colorPrimary, colorPrimaryBg, colorBorder, borderRadius, colorBgElevated},
    } = theme.useToken();

    return (<div>
        {nodeData && <>
            <NodeToolbar
                className={'nodrag nofocus'}
                isVisible={data.toolbarVisible}
                position={'left'}>
                <NodeEditor setNodeData={setNodeData} nodeData={nodeData}/>
            </NodeToolbar>
            <Container primary={colorPrimary}
                       className={'custom-drag-handle'}
                       colorBgContainer={colorBgElevated}
                       borderRadius={borderRadius}
                       isFocused={selected}
                       primaryBg={colorPrimaryBg}
                       ref={nodeRef}
                       active={flow.includes(nodeData?.id)}
                       border={colorBorder}
                       isLocked={!(!isIframe || (isIframe && !isFullLocked))} isEditing={selected}
                       isLast={flow && flow[flow.length-1] === nodeData?.id}>
                <Typography.Title level={5} ellipsis
                                  style={{width: 170, margin: '0 12px'}}>{nodeData.title}</Typography.Title>
                <Typography.Text ellipsis
                                 style={{width: 150, margin: '0 12px'}}>{nodeData?.conditionString}</Typography.Text>
                <div style={{margin: '8px'}}>
                    <Row justify={'space-between'}>
                        <Space style={{maxWidth: 200}} size={8} wrap>
                            {httpActions?.length > 0 && <Tag style={{margin: 0}}>Webhook</Tag>}
                            {nodeData?.contextActions?.length > 0 && <Tag style={{margin: 0}}>Contexto</Tag>}
                            {nodeData?.selectChildrenByPriority > 0 && <Tag style={{margin: 0}} icon={<ArrowLeftOutlined/>} >Prioridade</Tag>}
                        </Space>
                        <Locker id={nodeData?.id}/>
                    </Row>
                </div>
                {httpActions?.length > 0 &&
                    <WebhookModal actionIndex={0} nodeId={nodeData?.id} action={httpActions[0]?.data}
                                  setNodeData={setNodeData}
                                  open={modalOpen} setOpen={setModalOpen}/>}
            </Container>

            <CustomHandle
                isConnecting={isConnecting}
                type="target"
                position="top"
                id={'a'}
                isConnectable={isConnectable}
            />
            <CustomHandleJump
                isConnecting={isConnecting}
                type="target"
                position="left"
                id={'b'}
                isConnectable={isConnectable}
            />
            <CustomSourceHandle
                invert={nodeData?.skipUserInput}
                borderRadius={borderRadius}
                bgColor={colorPrimaryBg}
                isFocused={selected}
                borderColor={colorPrimary}
                type="source"
                position="bottom"
                isConnectable={isConnectable}
                id={'c'}

            />
        </>}
    </div>);
});

export const WebhookModal = ({open, isNew, setOpen, action, nodeId, actionIndex, deleteWebhook, setNodeData}) => {
    const [, setData] = useContext(ChatBotDataContext);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const method = Form.useWatch('method', form);
    const body = Form.useWatch('body', form);
    const mapping = Form.useWatch('responseMap', form2);
    const [bodyInvalid, setBodyInvalid] = useState(false);
    const [testLoading, setTestLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const [response, setResponse] = useState('');
    const [evals, setEvals] = useState(['']);

    useEffect(() => {
        form.setFieldsValue(action);
        form2.setFieldsValue(action);
    }, [action, form, form2])

    useEffect(() => {
        if (mapping?.length < 1 || response?.length < 1) {
            setEvals([]);
            return;
        }

        const res = JSON.parse(response);
        let evalsToSet = [];
        mapping?.forEach(item => {
            let dt = ''
            try {
                dt = JSON.stringify(eval(`res.${item.jsonPath}`))
            } catch (e) {
                dt = JSON.stringify(e.message)
            }
            evalsToSet.push(dt?.substring(0, 120) + (dt?.length > 120 ? '...' : ''));
        });
        setEvals(evalsToSet);
    }, [mapping, response])

    useEffect(() => {
        if (!body) return;

        try {
            JSON.parse(body)
            setBodyInvalid(false);
        } catch (e) {
            setBodyInvalid(true);
        }
    }, [body])
    const arrayToObject = useCallback(({array}) => {
        let result = {}
        for (var i = 0; i < array?.length; i++) {
            result[array[i].key] = array[i].value;
        }
        return result
    }, [])
    const test = useCallback(({method, url, body, headers}) => {
        let finalHeaders = arrayToObject({array: headers})

        setTestLoading(true);
        webhookTest({
            headers: finalHeaders,
            body,
            method,
            url
        }).then(({data}) => {
            setResponse(JSON.stringify(data, null, 4))
        }).catch(e => {
            messageApi.error("Falha ao realizar request, mais detalhes no console")
            console.error(e)
        }).finally(() => setTestLoading(false))
    }, [])

    const save = async () => {
        const actionData = {...form.getFieldsValue(), ...form2.getFieldsValue()};
        try {
            await form.validateFields()
            await form2.validateFields()
        } catch (e) {
            return;
        }

        setNodeData(old => {
            old.actions[actionIndex].data = actionData;
            return old;
        })
        setData(old => {
            old.nodes[nodeId].actions[actionIndex].data = actionData;
            return old;
        })
        setOpen(false)
    }

    return (<>

        <Modal
            width={1000}
            title="Webhook"
            open={open}
            onOk={save}
            onCancel={() => {
                setOpen(false);
                if (isNew) {
                    deleteWebhook(actionIndex);
                }
            }}
            cancelText={'Fechar'}
            footer={[
                <Button danger onClick={() => {
                    deleteWebhook(actionIndex);
                    setOpen(false);
                }}>Deletar</Button>,
                ...(isNew ? [] : [<Button onClick={() => setOpen(false)}>Fechar</Button>]),
                <Button onClick={save} type={'primary'}>Salvar</Button>
            ]}
        >
            <Typography.Text level={5}>Você pode realizar requests http e mapear a resposta em uma variável de
                contexto. Você pode usar variáveis de contexto em todos os campos, basta usar $ antes da variavel. Ex:
                $name</Typography.Text>
            <Divider/>
            <Form
                form={form}
                name={'webhook'}
                layout={'vertical'}
                disabled={testLoading}
                onFinish={test}
                initialValues={{}}
            >

                <Row gutter={[8, 8]}>
                    <Col span={16}>
                        <Form.Item
                            name={'url'}
                            rules={[{
                                required: true, message: 'URL é obrigatório',
                            }, {
                                pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                                message: 'URL inválido',
                            }]}
                        >
                            <Input placeholder={'URL da request'}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'timeout'}
                            rules={[{
                                required: true, message: 'Timeout inválido (5s~60s)',
                            }]}
                        >
                            <InputNumber style={{width: '100%'}} defaultValue={5} min={5} max={60}
                                         placeholder={'Timeout em segundos'}/>
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name={'method'}
                            rules={[{
                                required: true, message: "Selecione o método"
                            }]}
                        >
                            <Select
                                placeholder={'Selecione o método'}
                                options={["POST", "GET", "PUT", "DELETE"].map((value) => ({value, label: value}))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List style={{width: '100%'}} name="headers">

                    {(fields, {add, remove}) => (<>
                        {fields.map((field, index) => (<Form.Item
                            required={true}
                            label={index === 0 && 'Headers'}
                            style={{marginBottom: 0, width: '100%'}}
                            key={field.key}
                        >
                            <Row gutter={[8, 8]} align={'center'}>
                                <Col span={8}>
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[{
                                            required: true,
                                            whitespace: true,
                                            message: "Adicione uma chave ou delete esse campo",
                                        },]}
                                        name={[field.name, 'key']}
                                    >
                                        <Input placeholder="Chave"/>
                                    </Form.Item>
                                </Col>
                                <Col span={15}>
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[{
                                            required: true,
                                            whitespace: true,
                                            message: "Adicione um valor ou remova o campo",
                                        },]}
                                        name={[field.name, 'value']}
                                    >
                                        <Input placeholder="Valor"/>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button onClick={() => remove(field.name)} icon={<MinusCircleOutlined/>}/>
                                </Col>
                            </Row>
                        </Form.Item>))}
                        <Form.Item style={{marginBottom: '1rem'}}>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    add({jsonPath: '', context: ''});
                                }}
                                icon={<PlusOutlined/>}
                            >
                                Adicionar Header
                            </Button>
                        </Form.Item>
                    </>)}
                </Form.List>
                {['POST', 'PUT', 'GET', 'DELETE'].includes(method) && <Form.Item
                    name={'body'}
                    validateStatus={bodyInvalid ? 'error' : ''}
                    hasFeedback
                    help={'Deve ser um json válido'}
                >
                    <Input.TextArea rows={4}
                                    showCount
                                    maxLength={2000}
                                    placeholder={'Body enviado na request'}/>
                </Form.Item>}
                <Row justify={'end'}>
                    <Form.Item>
                        <Button loading={testLoading} icon={<SendOutlined/>} htmlType={'submit'}
                                type={'primary'}>Testar</Button>
                    </Form.Item>
                </Row>
                {response?.length > 0 &&
                    <Input.TextArea size={'small'} style={{resize: "none"}} value={response} rows={4}/>}
                <Divider style={{marginTop: response?.length > 0 ? 'inherit' : 0}}/>
            </Form>
            <Form
                layout={'vertical'}
                form={form2}>
                <Form.List
                    name="responseMap"
                    rules={[{
                        validator: async (_, messages) => {
                            if (!messages || messages.length < 1) {
                                return Promise.reject(new Error('É necessário 1 mapeamento no mínimo'));
                            }
                        },
                    },]}
                >
                    {(fields, {add, remove}, {errors}) => (<>
                        {fields.map((field, index) => (<Form.Item
                            required={true}
                            key={field.key}
                            label={index === 0 && 'Mapeamento'}
                            style={{marginBottom: 0}}
                            tooltip={'Mapeie o json a uma variável de contexto'}
                        >
                            <Row justify={'space-between'} align={'center'}>
                                <Col span={8}>
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[{
                                            required: true,
                                            whitespace: true,
                                            message: "Adicione uma variavel de contexto ou delete esse campo",
                                        },]}
                                        name={[field.name, 'context']}
                                    >
                                        <Input prefix={'$'} placeholder="Variável de contexto"/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        {...field}
                                        help={evals[index]}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[{
                                            required: true,
                                            whitespace: true,
                                            message: "Adicione um caminho json ou delete esse campo",
                                        },]}
                                        name={[field.name, 'jsonPath']}
                                    >
                                        <Input placeholder="Caminho JSON"/>
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    {fields.length >= 1 ? (<MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />) : null}
                                </Col>
                            </Row>
                        </Form.Item>))}
                        <Form.Item
                            style={{marginBottom: '1rem'}}
                            help={errors}
                            validateStatus={errors?.length > 0 && 'error'}
                        >
                            <Button
                                type="dashed"
                                onClick={() => {
                                    add({jsonPath: '', context: ''});
                                }}
                                icon={<PlusOutlined/>}
                            >
                                Adicionar mapeamento
                            </Button>
                        </Form.Item>
                    </>)}
                </Form.List>
            </Form>
        </Modal>
    </>);
}

function CustomHandle(props) {
    const {
        token: {colorPrimaryBg, borderRadius, colorPrimaryBgHover},
    } = theme.useToken();

    return (
        <CustomHandleContainer bgColorHover={colorPrimaryBgHover} borderRadius={borderRadius}
                               bgColor={colorPrimaryBg} {...props}>
            {props.isConnecting && <Typography.Title level={3} style={{padding: 0, margin: 0}}>FLUXO</Typography.Title>}
        </CustomHandleContainer>
    )
}

function CustomHandleJump(props) {
    const {
        token: {colorPrimaryBg, borderRadius, colorPrimaryBorder, colorPrimaryBgHover},
    } = theme.useToken();

    return (
        <CustomTargetHandleJumpContainer {...props} bgColorHover={colorPrimaryBgHover} bgColor={colorPrimaryBg}
                                         borderRadius={borderRadius} borderColor={colorPrimaryBorder}>
            {props.isConnecting && <JumpTypografy>PULO</JumpTypografy>}
        </CustomTargetHandleJumpContainer>
    )
}
