import {useState, useEffect, useCallback, useContext} from 'react';
import {useEdgesContext} from '../context/edgesProvider';
import {MarkerType} from 'reactflow';
import ChatBotDataContext from '../context/chatbot-data';

const useEdge = () => {
    const [data, setData] = useContext(ChatBotDataContext);
    const {edges, setEdges, onEdgesChange} = useEdgesContext();

    const dataEdgeToEdge = useCallback((dataEdge, animated) => {
        return {
            id: dataEdge.id,
            source: dataEdge.source,
            target: dataEdge.target,
            animated,
            data: {
                type: dataEdge.type
            },
            updatable: true,
            targetHandle: dataEdge.type.substring(0, 4) === 'jump' ? 'b' : 'a',
            type: dataEdge.type.substring(0, 4) === 'jump' ? 'jump' : 'smoothstep',
            markerStart: {
                markerUnits: 10
            },
            markerEnd: {
                width: 15,
                height: 15,
                type: MarkerType.ArrowClosed
            },
        }
    }, []);

    const deleteEdge = (removedEdges) => {
        setData(old => {
            for (let edge of removedEdges) {
                if (old.edges && old.edges[edge.source] && old.edges[edge.source][edge.target])
                    delete old.edges[edge.source][edge.target];
            }
            return {...old};
        });
    }
    const createEdge = (edgeData) => {
        if (!data?.edges)
            setData(old => {
                return {...old, edges: {[edgeData.source]: {[edgeData.target]: edgeData}}}
            })
        else if (!data?.edges[edgeData?.source])
            setData(old => {
                return {...old, edges: {...old.edges, [edgeData.source]: {[edgeData.target]: edgeData}}}
            })
        else
            setData(old => {
                return {...old,
                    edges: {
                        ...old.edges,
                        [edgeData.source]: {...old.edges[edgeData.source], [edgeData.target]: edgeData}
                    }
                }
            })

        setEdges(old => {
            return [...old, dataEdgeToEdge(edgeData)]
        })
    }

    return {dataEdgeToEdge, createEdge, deleteEdge};
};

export default useEdge;
