import styled, {css, keyframes} from "styled-components";
import {slideInLeft, slideInRight} from "react-animations";

const slideRight = keyframes`${slideInRight}`;
const slideLeft = keyframes`${slideInLeft}`;

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin: .4rem 1rem;
  padding: .6rem 0;
  max-height: 10%;

  img {
    width: 18px;
  }
`;

export const Button = styled.button`
  border: 0;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-items: center;
  box-shadow: 0 0 10px rgba(102, 102, 185, 0.47);
  margin: 0 .6rem;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s opacity ease;

  ${props => props.isOpen && css`
    color: #e03b3b;
    opacity: 1;
  `}
  &:hover {
    opacity: 1;
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  height: calc(90% - .4rem);
  width: 100%;
  
  overflow-x: clip;
  transition: .3s width ease;
  

  /* Estilo para Firefox */
  scrollbar-color: ${props => props.colorPrimaryBg}; ${props => props.colorBg}; 
  scrollbar-width: thin; /* Largura da scrollbar */

`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem 1rem 1rem;
`;


const loading = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`

export const WarningInfo = styled.p`
  font-size: .8em;
  color: #7c7c7c;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  
  span {
    margin-left: .4rem;
  }
`;

export const WarningContainer = styled.div`
  font-size: 1em;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    margin: .6rem 0;

    span {
      animation-name: ${loading};
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      width: 18px;
      height: 18px;
    }

    p {
      margin: 0 1rem;
      font-weight: 500;
    }
  }
`;

export const MessageContainer = styled.div`
  margin: .6rem 0;
  max-width: 75%;
  align-self: flex-start;
  ${props => !props.notAnimated && css`
    animation: .3s ${slideLeft};
  `}

  ${props => !props.isMachine && !props.notAnimated && css`
    animation: .3s ${slideRight};
  `}

  ${props => !props.isMachine && css`
    align-self: flex-end;
  `}
`;
export const Message = styled.div`
  background-color: ${props => props.bgColor};
  border-radius: 0 10px 10px 10px;
  padding: .6rem 1rem;
  white-space: pre-line;
  color: ${props => props.color};
  ${props => !props.isMachine && css`
    border-radius: 10px 10px 0 10px;
  `}

  ${props => props.isError && css`
    background-color: ${props => props.bgColorError};
    color: ${props => props.colorErrorText};
  `}
`;
export const AudioMessageContainer = styled.div`
  background-color: ${props => props.bgColor};
  border-radius: 0 10px 10px 10px;
  padding: .6rem 1rem;

   color: ${props => props.color};
  ${props => !props.isMachine && css`
    border-radius: 10px 10px 0 10px;
  `}

  ${props => props.isError && css`
    background-color: ${props => props.bgColorError};
    color: ${props => props.colorErrorText};
  `}
`;

export const AudioBtn = styled.button`
  padding-top: 4px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: #fff;
`

export const Code = styled.div`
  padding: .3rem;
  border-radius: 10px;
  width: auto;
  color: white;
  font-family: "Fira Code", sans-serif;
  font-size: 1.2em;
  
  code {
    font-size: 0.8em;
    font-weight: 600;
    margin-bottom: .4rem;
    white-space: pre-wrap;
  }
`;

