import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Form, Input, InputNumber, Row, Select, Table, Typography} from "antd";
import MessageApiContext from "../../../context/message-api";
import PlanCreate from "../Create";

export default function ListPlans({columns, updatePlanCallback, getPlansCallback, createPlanCallback, title}) {
    const EditableContext = React.createContext(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [plans, setPlans] = useState([]);

    const EditableCell = ({
                              title,
                              editable,
                              isNumber,
                              options,
                              element,
                              children,
                              dataIndex,
                              record,
                              handleSave,
                              ...restProps
                          }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        let childNode = children;

        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({
                    ...record,
                    ...values,
                });
            } catch (errInfo) {
                messageApi.error("Falha ao salvar plano");
                console.log("Save failed:", errInfo);
            }
        };

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} é obrigatório.`,
                        },
                    ]}
                >
                    {isNumber && !options && (
                        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save}/>
                    )}
                    {!isNumber && !options && (
                        <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
                    )}
                    {options && (
                        <Select
                            options={options}
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                        />
                    )}
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();

        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const refresh = () => {
        setLoading(true);
        getPlansCallback({page, perPage})
            .then(({data, total}) => {
                setPlans(data);
                setTotal(total);
                setLoading(false);
            })
            .catch(console.error);
    }

    useEffect(() => {
        setLoading(true);
        getPlansCallback({page, perPage})
            .then(({data, total}) => {
                setPlans(data);
                setTotal(total);
                setLoading(false);
            })
            .catch(console.error);
    }, [page, perPage, getPlansCallback]);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const messageApi = useContext(MessageApiContext);
    const parsedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                isNumber: col.isNumber,
                options: col.options,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: (row) => {
                    const newData = [...plans];
                    const index = newData.findIndex((item) => row.id === item.id);
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });

                    updatePlanCallback(row.id, row)
                        .then(() => {
                            setPlans(newData);
                            messageApi.success("Edição salva");
                        })
                        .catch(() => messageApi.error("Falha ao salvar"));
                },
            }),
        };
    });

    return (
        <>

            <Row justify={"space-between"}>
                <Typography.Title level={3}>{title}</Typography.Title>
                <div>
                    <Button type={"primary"} onClick={() => setIsModalOpen(true)}>
                        Criar
                    </Button>
                </div>
            </Row>
            <Table
                orientation={"left"}
                rowClassName={() => "editable-row"}
                itemLayout={"horizontal"}
                columns={parsedColumns}
                loading={loading}
                components={components}
                dataSource={plans}
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page, perPage) => {
                        setPage(page);
                        setPerPage(perPage);
                    },
                    total,
                    pageSize: perPage,
                    current: page,
                }}
            />
            <PlanCreate isOpen={isModalOpen}
                        refresh={refresh}
                        columns={columns}
                        setIsOpen={setIsModalOpen}
                        createPlanCallback={createPlanCallback}/>
        </>
    );
}
