import {Button, Col, List, message, Row, Skeleton, Space, Tag, Typography} from 'antd';
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {getSessionHistory} from "../../services/conversation-logs";
import {updatedSince} from "../../utils";
import { CardButton } from '../../components/node-editor/styles';
import MessageApiContext from "../../context/message-api";


export default function LogsView() {
    const {sessionId} = useParams();
    const [sessionData, setSessionData] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [loading, setLoading] = useState(true);
    const messageApi = useContext(MessageApiContext);
    const [total, setTotal] = useState(0);


    useEffect(() => {
        setLoading(true);
        getSessionHistory(sessionId, page, perPage).then(({data, total}) => {
            setSessionData(old => [...old, ...data]);
            setTotal(total)
        }).catch(e => {
            messageApi.error("Falha ao carregar histórico");
        }).finally(() => {
            setLoading(false);
        });
    }, [page, perPage])
    const handleType = useCallback((item)=> {
        const itemData = item.data
        if(item.type === 'options'){
            const options = Object.values(itemData)
            return(
                <Row gutter={[8, 8]}>
                {options.map((option, i)=> {
                    return(
                        <Col key={i}>
                         <Button style={{cursor:'inherit'}}>
                             {option.text}
                         </Button></Col>)
                })}
                </Row>
                )

        }
        return itemData

    }, [])
    const loadMoreButton = useMemo(() =>
        !loading && total > (perPage * page) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={() => setPage(old => ++old)}>Carregar mais</Button>
            </div>
        ) : null, [total, perPage, page]);

    return (
        <Space direction={'vertical'}>

            <Space direction={'vertical'} size={'small'}>
                <Typography.Title style={{marginBottom: 0}} level={3}>{sessionId}</Typography.Title>

                <Skeleton paragraph={false} loading={loading}>
                    <Typography.Paragraph>{updatedSince(new Date(sessionData[0]?.createdAt))} atrás</Typography.Paragraph>
                </Skeleton>
            </Space>

            <Typography.Title level={4}>Mensagens</Typography.Title>
            <List
                loading={loading}
                itemLayout="horizontal"
                loadMore={loadMoreButton}
                dataSource={sessionData}
                renderItem={(item) => (
                    <List.Item>
                        <Space size={'small'} direction={'vertical'}>
                            <Space>
                                <Typography.Title style={{marginBottom: 0}} level={5}>Usuário</Typography.Title>
                                {item?.state?.intents?.length > 0 && <Tag
                                    color="magenta">#{item?.state?.intents[0]?.name} - {(item?.state?.intents[0]?.confidence*100).toFixed(2)}%</Tag>}
                                {item?.state?.entities?.length > 0 && <Tag
                                    color="lime">@{item?.state?.entities[0]?.value} - {(item?.state?.entities[0]?.confidence*100).toFixed(2)}%</Tag>}
                            </Space>
                            <Typography.Paragraph style={{marginBottom: 0}}
                                                  level={5}>{item.message}</Typography.Paragraph>
                            <Typography.Title style={{marginBottom: 0}} level={5}>Verbeux</Typography.Title>
                            {
                                item?.state?.response.map(item => <Typography.Paragraph style={{marginBottom: 0}}>
                                    {handleType(item)}
                                </Typography.Paragraph>)
                            }
                        </Space>
                    </List.Item>
                )}
            />
        </Space>
    )
}
