import React, {useContext, useEffect, useState} from "react";
import {Button, List, message, Switch} from "antd";
import {Routes, Route, Link} from "react-router-dom";
import AdminLayout from "../../components/admin-layout";
import {getCompanies, updateCompany} from "../../services/companies";
import CompanyEdit from "../company-edit";
import {Header, Title} from "./styles";
import CompanyCreate from "../company-create";
import MessageApiContext from "../../context/message-api";

export default function Companies() {
    return (
        <AdminLayout>
            <Routes>
                <Route index path={'/'} element={<ListCompanies/>}/>
                <Route index path={'/:id'} element={<CompanyEdit/>}/>
                <Route index path={'/create'} element={<CompanyCreate/>}/>
            </Routes>
        </AdminLayout>
    )
}

function ListCompanies() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        setLoading(true);
        getCompanies({page, perPage}).then(({data, total}) => {
            setCompanies(data);
            setTotal(total);
            setLoading(false);
        }).catch(console.error)
    }, [page, perPage])

    return (
        <List
            orientation={'left'}
            itemLayout={'horizontal'}
            dataSource={companies}
            loading={loading}
            header={
                <Header>
                    <Title>Empresas</Title>
                    <Link to={'create'}>
                        <Button type={'primary'}>Criar</Button>
                    </Link>
                </Header>
            }
            pagination={{
                position: "bottom",
                align: "end",
                onChange: (page, perPage) => {
                    setPage(page);
                    setPerPage(perPage);
                },
                total,
                pageSize: perPage,
                current: page
            }}
            renderItem={(item) => <CompanyContainer data={item}/>}
        />
    );
}


function CompanyContainer({data}) {
    const [isLoading, setIsLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);

    const updateActive = (data, isBlocked) => {
        data.isBlocked = isBlocked
        setIsLoading(true);
        updateCompany(data.id, data).then(() => {
            setIsLoading(false);
        }).catch(e => {
            setIsLoading(false);
            console.error(e)
            messageApi.error("Falha ao atualizar compania")
        })
    }

    return (
        <List.Item
            actions={[
                <Link to={`${data?.id}`}>
                    <Button type={'text'}>Editar</Button>
                </Link>,
                <Switch loading={isLoading} onChange={(v) => updateActive(data, !v)} size={'small'}
                        checked={!data?.isBlocked}/>]}
        >
            <List.Item.Meta
                title={data?.name}
                description={data?.identity?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}
            />
        </List.Item>
    )
}
