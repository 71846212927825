import Fetch from "./index";

export const login = async (email, password) => {
    if (!email || !password)
        return Promise.reject("missing email or password");

    return Fetch("/auth/", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({email, password})
    })
}

export const refresh = async () => {
    return Fetch("/auth/", {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
        },
    })
}

export const forgotPassword= async (data)=> {
    return Fetch('/auth/forgot-password',{
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
        "Content-Type": "application/json",
    }}
    )
}
export const resetPassword= async (data)=> {
    return Fetch('/auth/forgot-password',{
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
        "Content-Type": "application/json",
    }}
    )
}

