import React, { useState, useCallback } from 'react';

export default function ExportJson({ data, filename, children}) {
    const [loading, setLoading] = useState(false);

    const handleDownload = useCallback(() => {
        if (loading) return
        setLoading(true);

        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename + ".json";

        link.click();
        URL.revokeObjectURL(url);

        setLoading(false);
    }, [data, filename, loading, setLoading]);

    return (
        <>
            { React.cloneElement( children, { onClick: handleDownload } ) }
        </>
    );
}
