import styled from "styled-components";

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => props.collapsed ? '1rem' : '2rem 1rem'};
`;
export const Logo = styled.img`
  //transition: .1s linear width;
  width: ${props => props.collapsed ? '80%' : '80%'};
`;
