import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {Menu, theme} from 'antd';
import ChatBotDataContext from '../../context/chatbot-data';
import {PlusOutlined} from "@ant-design/icons";
import ActionsContext from "../../context/actions";
import useNode from '../../hooks/useNode';
import useGroup from '../../hooks/useGroups';

const ContextMenu = ({position, setPosition, messageApi, currentContextMenu, onClose, groupNodes}) => {
    const [fullData, setFullData] = useContext(ChatBotDataContext);
    const {deleteNode} = useNode()
    const { minimize } = useGroup()
    const [focusedNode] = useContext(ActionsContext)['focusedNode'];

    const nodeInGroup = useMemo(() => {
        if (!currentContextMenu) return false;

        if (currentContextMenu?.type === 'node') {
            return currentContextMenu?.data?.group?.length > 0 && currentContextMenu?.data?.group !== '000000000000000000000000'
        }

        return false
    }, [currentContextMenu])

    const deleteGroup = useCallback((nodeData) => {
        setFullData(oldData => {
            const newData = JSON.parse(JSON.stringify(oldData));
            let position = nodeData.position
            for (let child of newData.nodeGroups[nodeData.id].nodes) {
                if(newData.nodes[child]){
                    if(newData.nodes[child].position){
                newData.nodes[child].position.x = oldData.nodes[child].position.x + position.x
                newData.nodes[child].position.y = oldData.nodes[child].position.y + position.y}}
            }
            delete newData.nodeGroups[nodeData.id];
            return newData;
            
        });
    }, []);


    const removeGroup = useCallback(() => {
        setFullData(old => {
                let newData = {...old}
                const groups = Object.values(old?.nodeGroups ?? {})
                const nodeIdToRemove = currentContextMenu.data.id;
                for (const group of groups) {
                    if (group.nodes.includes(currentContextMenu.data.id)) {
                        const groupNodes = newData.nodeGroups[group.id].nodes;
                        const nodeIndex = groupNodes.indexOf(nodeIdToRemove);

                        if (nodeIndex !== -1) {
                            groupNodes.splice(nodeIndex, 1);
                            return {
                                ...newData,
                                nodes: {
                                    ...newData.nodes,
                                    [nodeIdToRemove]: {
                                        ...newData.nodes[nodeIdToRemove],
                                        group: '',
                                    },
                                },
                                nodeGroups: {
                                    ...newData.nodeGroups,
                                    [group.id]: {
                                        ...newData.nodeGroups[group.id],
                                        nodes: groupNodes
                                    }
                                }
                            }
                        }
                    }

                    messageApi.error('Nó selecionado não faz parte de um grupo')
                }

                setPosition(null)

                return old;
            }
        )
    }, [onClose])

    const switchMenu = useCallback(() => {
        switch (currentContextMenu?.type) {
            case 'node':
                return (
                    currentContextMenu.data.nodes ?
                    <>
                         <Menu.Item danger onClick={()=> deleteGroup(currentContextMenu.data)}>{"Deletar"}</Menu.Item>
                         <Menu.Item onClick={()=> minimize(currentContextMenu.data)}> {currentContextMenu.data.minimized ? "Maximizar" :"Minimizar"}</Menu.Item>
                     </>
                    :
                    <>
                        <Menu.Item danger onClick={()=> deleteNode(currentContextMenu.data.id)}>{"Deletar"}</Menu.Item>
                        {nodeInGroup && <Menu.Item onClick={removeGroup}>{"Remover do grupo"}</Menu.Item>}
                    </>

                )
             
            case 'selection': {
                return (
                    <>
                        <Menu.Item onClick={() => groupNodes(focusedNode)}
                                   icon={<PlusOutlined/>}>{"Criar grupo"}</Menu.Item>
                    </>
                )
            }
            default:
                return;
        }
    }, [currentContextMenu?.type]);

    const {
        token: {borderRadius, boxShadow}
    } = theme.useToken();

    return (
        <Menu className={'nodrag nofocus nopan'}
              onClick={onClose}
              style={{
            position: 'absolute',
            boxShadow,
            left: `${position.x + 80}px`,
            top: `${position.y + 70}px`,
            borderRadius
        }}>
            {
                switchMenu()
            }
        </Menu>
    );
};

export default ContextMenu;
