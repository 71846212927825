import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-end;
    // background-color: ${props => props.bgColor};
  background-image: url(${props => props.bgImage});
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  min-width: 400px;
  height: 100%;
  justify-content: center;
  width: 30vw;
  padding: 0 2rem;
  flex-direction: column;
  border-style: solid;
  border-radius: ${props => props.radius*3}px 0 0 ${props => props.radius*3}px;
  background-color: ${props => props.background};
  border-width: 0;
`;

export const Title = styled.h1`
  font-size: 2em;
`;

export const Subtitle = styled.p`
  font-size: 1em;
  max-width: 80%;
`;

export const Image = styled.img`
  height: 40px;
`;

export const Label = styled.label`
  margin: .4rem 0;
`;

export const Err = styled.div`
  color: red;
  font-size: .8rem;
  text-transform: uppercase;
  height: 12px;
`;

export const Input = styled.input`
  border: 0;
  border-radius: 10px;
  background-color: #e7e7e7;
  font-size: 1em;
  padding: .6rem .8rem;
  margin-bottom: 1rem;
`;

export const Submit = styled.button`
  border: 0;
  border-radius: 10px;
  background-color: black;
  padding: .6rem 1rem;
  width: 35%;
  color: white;
  font-weight: bold;
  align-self: flex-end;
  margin-top: 2rem;
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
