import React, { useCallback, useState } from 'react'
import Papa from 'papaparse'

export default function ExportCsv({ data, filename, children, isEntitie = false}) {
    const [loading, setLoading] = useState(false);

    const arrayOfIntentionsData = (data) => {
        let intentionsArray = [];
    
        Object.values(data).forEach(intent => {
            if (intent && intent.examples) {
                intent.examples.forEach(example => {
                    intentionsArray.push({
                        id: intent.id,
                        title: intent.title,
                        example: example.text
                    });
                });
            }
        });
    
        return intentionsArray;
    };
    

    const arrayOfEntitiesData = (data) => {
        let entitiesArray = [];

        Object.values(data).forEach(entity => {
            entity.values.forEach(value => {
                value.synonyms.forEach(synonym => {
                    entitiesArray.push({
                        id: entity.id,
                        title: entity.title,
                        value: value.name,
                        synonym: synonym
                    });
                });
            });
        });

        return entitiesArray;
    };

    const exportDataToCSV = useCallback(() => {
        if (loading) return
        setLoading(true);

        const convertedData = isEntitie ? arrayOfEntitiesData(data) : arrayOfIntentionsData(data);

        const csv = Papa.unparse(convertedData);
        const csvBlob = new Blob([csv], { type: "text/csv" });
    
        const csvUrl = URL.createObjectURL(csvBlob);
    
        const link = document.createElement("a");
        link.href = csvUrl;
        link.download = filename + ".csv";
    
        link.click();
    
        URL.revokeObjectURL(csvUrl);

        setLoading(false);
    }, [loading, data, filename, isEntitie]);

    return (
        <>
            { React.cloneElement( children, { onClick: exportDataToCSV } ) }
        </>
    );
}
