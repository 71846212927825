import Fetch from "./index";

export const getChatbotPlan = async (id) => {
    if (!id)
        return Promise.reject("failed to read id");

    return Fetch("/chatbot-plan/" + id)
}

export const getChatbotPlans = async (filters) => {
    const filter = new URLSearchParams(filters).toString();

    return Fetch(`/chatbot-plan?${filter}`)
}

export const updateChatbotPlan = async (id, data) => {
    return Fetch(
        '/chatbot-plan/' + id,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const createChatbotPlan = async (data) => {
    return Fetch(
        '/chatbot-plan/',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}
