import {Container, Content} from "./styles";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Checkbox, Flex, Form, Input, message, Row, Space, Tabs, theme, Typography} from "antd";
import {ArrowRightOutlined, LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import {login} from "../../services/auth";
import BgImg from '../../assets/background-login.png';
import {createPublicUser} from "../../services/users";
import MessageApiContext from "../../context/message-api";

export default function Login() {
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const [isLogin, setLogin] = useState(0)

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token?.length > 1)
            navigate('/');
    }, [])

    function onFinish({name, email, password, confirmPassword}) {
        setIsLoading(true);
        if (isLogin == 0) {
            let loginPromise = login(email, password);
            loginPromise.then(({data}) => {
                localStorage.setItem("token", data.token);
                localStorage.setItem("user", JSON.stringify(data.user));
                setIsLoading(false)
                navigate(search.get("redirect") ?? '/')
                messageApi.success("Login realizado com sucesso!")
            }).catch(e => {
                console.error(e)
                setIsLoading(false)
                messageApi.error("Falha ao realizar login")
            });
        } else {
            if (confirmPassword != password) {
                setIsLoading(false)
                return messageApi.error('As senhas devem ser iguais.')
            }
            createPublicUser({name, password, email}).then(({data}) => {
                messageApi.success("Cadastro realizado com sucesso!")
                localStorage.setItem("token", data.token);
                localStorage.setItem("user", JSON.stringify(data.user));
                setIsLoading(false)
                navigate(search.get("redirect") ?? '/')
            }).catch(e => {
                console.log(e)
                setIsLoading(false)
                messageApi.error("Falha ao realizar cadastro")
            })
        }
    }

    const items = [
        {
            key: 0,
            label: 'Login',
            children: (
                <Form
                    name="login"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[{required: true, type: 'email', message: 'Por favor, insira um Email válido!'}]}
                    >
                        <Input size={'large'} prefix={<UserOutlined/>} placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Por favor, insira a Senha!'}]}
                    >
                        <Input.Password
                            size={'large'}
                            prefix={<LockOutlined/>}
                            placeholder="Senha"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Row justify={'space-between'} align={'center'}>

                            <Form.Item name="remember" valuePropName="checked" noStyle checked>
                                <Checkbox>Lembrar</Checkbox>
                            </Form.Item>

                            <a onClick={() => {
                                navigate('/forgot/password')
                            }}>
                                Esqueci a senha
                            </a>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row justify={'end'}>
                            <Button loading={isLoading} type="primary" htmlType="submit"
                                    style={{width: '100%'}}
                                    size={'large'}
                                    className="login-form-button">
                                Entrar
                                <ArrowRightOutlined/>
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            ),
        },
        {
            key: 1,
            label: 'Cadastro',
            children: (
                <Form
                    name="cadastro"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"
                        rules={[{required: true, message: 'Por favor, insira o seu nome!'}]}
                    >
                        <Input size={'large'} prefix={<UserOutlined/>} placeholder="Nome"/>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{required: true, type: 'email', message: 'Por favor, insira um Email válido!'}]}
                    >
                        <Input size={'large'} prefix={<MailOutlined/>} placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Por favor, insira a Senha!'}]}
                    >
                        <Input.Password
                            size={'large'}
                            prefix={<LockOutlined/>}
                            placeholder="Senha"
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        rules={[{required: true, message: 'Por favor, confirme sua Senha!'}]}
                    >
                        <Input.Password
                            size={'large'}
                            prefix={<LockOutlined/>}
                            placeholder="Confirmar senha"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Row justify={'end'}>
                            <Button loading={isLoading} type="primary" htmlType="submit"
                                    style={{width: '100%'}}
                                    size={'large'}
                                    className="login-form-button">
                                Enviar
                                <ArrowRightOutlined/>
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            ),
        },
    ];
    const {
        token: {borderRadius, colorBgContainer, colorBorderBg, colorBgLayout},
    } = theme.useToken();
    const textStyle = {
        fontWeight: 'bold',
        margin: 0,
        fontSize: window.innerWidth / 23,
        color: '#e3e3e3'
    }
    const notBoldTextStyle = {
        color: '#e3e3e3',
        margin: 0,
        fontSize: window.innerWidth / 23
    }
    return (
        <>
            <Container bgImage={BgImg} bgColor={colorBgLayout}>
                <Flex vertical align="flex-start" justify="flex-start" style={{
                    flex: 1,
                    padding: '40px 90px',
                    height: '100%',
                    justifyContent: 'flex-end',
                    boxSizing: 'border-box'
                }}>
                    <Typography.Title style={notBoldTextStyle}>A tecnologia que</Typography.Title>
                    <Typography.Title style={textStyle}>cria,</Typography.Title>
                    <Typography.Title style={textStyle}>recria,</Typography.Title>
                    <Typography.Title style={textStyle}>revoluciona!</Typography.Title>
                </Flex>
                <Content
                    radius={borderRadius}
                    borderColor={colorBorderBg}
                    background={colorBgContainer}
                >


                    <Space direction={'vertical'} size={30}>

                        <Row justify={'center'}>
                            <div>
                                <Typography.Title style={{marginBottom: 0, textAlign: 'center'}}
                                                  level={2}> {isLogin ? 'Cadastrar' : 'Entrar'}</Typography.Title>
                                <Typography.Text>{isLogin ? 'Cadastrar-se no Verbeux' : 'Iniciar sessão no Verbeux'}</Typography.Text>
                            </div>
                        </Row>
                        <Tabs centered defaultActiveKey="0" items={items} onChange={(key) => {
                            setLogin(key)
                        }}/>

                    </Space>
                </Content>
            </Container>
        </>
    )
}
