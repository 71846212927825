import React, {useCallback, useContext, useMemo} from "react";
import ChatBotDataContext from "../../context/chatbot-data";
import ActionsContext from "../../context/actions";
import {Button, Tooltip} from "antd";
import {LockOutlined, UnlockOutlined} from "@ant-design/icons";

export function Locker({id, param = "all"}) {
    const [fullData, setFullData] = useContext(ChatBotDataContext);
    const nodeData = useMemo(() => {
        if (!id || !fullData || !fullData?.nodes || !fullData.nodes[id])
            return {}

        return fullData.nodes[id]
    }, [fullData?.nodes, id]);
    const [iframeLockEditor] = useContext(ActionsContext)['iframeLockEditor'];
    const [isIframe] = useContext(ActionsContext)['iframe'];

    const isFullLocked = () => {
        return nodeData?.lock?.title
            && nodeData?.lock?.condition
            && nodeData?.lock?.response
            && nodeData?.lock?.contextActions
            && nodeData?.lock?.move
            && nodeData?.lock?.delete
    };

    const isLocked = () => {
        const lock = nodeData?.lock;
        if (lock)
            return lock[param];

        return false
    }

    const changeFullLock = useCallback(() => {
        if (!isFullLocked())
            return setFullData(old => {
                old.nodes[nodeData.id].lock = {
                    title: true,
                    condition: true,
                    response: true,
                    contextActions: true,
                    move: true,
                    delete: true
                };
                return {...old, nodes: old.nodes};
            })

        return setFullData(old => {
            old.nodes[nodeData.id].lock = {
                title: false,
                condition: false,
                response: false,
                contextActions: false,
                move: false,
                delete: false
            };
            return {...old, nodes: old.nodes};
        })
    }, [isFullLocked, setFullData, nodeData])

    const changeLock = (param) => {
        if (param === "all")
            return changeFullLock()

        setFullData(old => {
            if (!old.nodes[nodeData.id].lock)
                old.nodes[nodeData.id].lock = {[param]: true};
            else
                old.nodes[nodeData.id].lock[param] = !isLocked();

            return {...old, nodes: old.nodes};
        })
    }

    const locked = param === "all" ? isFullLocked() : isLocked();

    if (!iframeLockEditor && !isIframe)
        return <></>;

    return <Tooltip title={locked ? isIframe ? "Bloqueado" : "Desbloquear" : isIframe ? "Livre" : "Bloquear"}>
        <Button size={'small'} type={isIframe ? 'text' : locked ? 'text' : 'dashed'} color={'red'} danger={locked}
                disabled={isIframe} onClick={() => !isIframe && changeLock(param)} icon={locked ?
            <LockOutlined/> :
            <UnlockOutlined/>
        }/>
    </Tooltip>
}
