import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ChatBotDataContext from "../../context/chatbot-data";
import React from "react";
import {AndOperator, EqualOperator, NotEqualOperator, OrOperator} from "../../constants";
import {isSimilar} from "../../utils";
import {AutoComplete, Badge, Button, Card, Col, Divider, Row, Select, Space, theme} from "antd";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {conditionToString} from "../../utils/editor";

const optionsCmpOperators = [EqualOperator, NotEqualOperator].map(item => ({
    label: item,
    value: item
}));

const optionsOperators = [AndOperator, OrOperator].map(item => ({
    label: item,
    value: item
}));

export default function ConditionMultiSelector({node, setNData}) {
    const [data, setData] = useContext(ChatBotDataContext);
    const intents = useMemo(() => Object.values(data.intents ?? {}), [data.intents])
    const entities = useMemo(() => Object.values(data.entitiesValued ?? {}), [data.entitiesValued])
    const nodes = useMemo(() => Object.values(data.nodes ?? {}), [data.nodes])
    const [options, setOptions] = useState([]);
    const [conditionBlocks, setConditionBlocks] = useState([]);
    const [operators, setOperators] = useState([]);
    const {
        token: {colorErrorBg},
    } = theme.useToken();

    const getContextActions = useMemo(() => {
        let keys = []
        nodes?.forEach(node => {
            keys = [...keys, ...(node?.contextActions?.map(context => context?.key) ?? [])]
        })

        keys = [...new Set([...keys])]

        return keys.map(item => ({
            label: "$" + item,
            value: "$" + item
        }));
    }, [nodes])

    useEffect(() => {
        const regexPattern = [OrOperator, AndOperator].map(op => `\\s?${op}-?\\d*\\s?`).join('|');
        const codeBlocks = node?.condition?.split(new RegExp(regexPattern)).filter(item => item);
        if (!codeBlocks)
            return [];

        const operators = node?.condition?.split(" ")?.filter(item => [OrOperator, AndOperator].includes(item.split("-")[0]));
        setConditionBlocks(codeBlocks);
        setOperators(operators.slice(0, codeBlocks.length - 1));
    }, [node?.id])

    const setConditionIndex = useCallback((index, value) => {
        let auxArray = [...operators]
        auxArray[index] = value

        setOperators(auxArray)

    })
    useEffect(() => {
        setOptions([
            ...intents?.map(item => ({
                label: "#" + item.title.split(" ").join("_"),
                value: item.id,
            })),
            ...entities?.map(item => ({
                label: "@" + item.title.split(" ").join("_"),
                value: item.id,
            })),
            ...getContextActions,
            ...data?.system.map(item => ({label: item, value: item}))
        ]);
    }, [intents, entities, getContextActions])

    const addCondition = useCallback((operator) => {
        setConditionBlocks(old => [...old, ''])
        if (operator)
            setOperators(old => [...old, operator])
    }, [])

    const setCondition = useCallback((index) => {
        return (condition) => {
            setConditionBlocks(old => {
                    old[index] = condition
                    return [...old];
                }
            )
        }
    }, []);

    useEffect(() => {
        const tm = setTimeout(() => {
            let result = ''
            for (let i in conditionBlocks.filter(item => item)) {
                result += conditionBlocks[i]
                if (operators?.length > i && operators[i].length > 0 && conditionBlocks[Number(i) + 1])
                    result += ` ${operators[i]} `;
            }
            result = result.split(" ").filter(item => item && item !== ' ').join(" ");
            setNData(old => ({
                ...old,
                condition: result,
                conditionString: conditionToString(data, result?.length > 0 ? result?.split(" ") : [])
            }))
            setData((old) => {
                if (!old.nodes || !node?.id || !old.nodes[node.id])
                    return old;

                old.nodes[node.id].condition = result
                return {...old};
            })
        }, 300)

        return () => clearTimeout(tm);
    }, [conditionBlocks, operators, node?.id])

    const removeBlock = useCallback((index) => {
        setOperators(old => {
            old.splice(index - 1, 1);
            return [...old];
        })

        setConditionBlocks(old => {
            old.splice(index, 1);
            return [...old];
        })
    }, []);

    return (
        <Row gutter={[8, 8]}>
            {
                conditionBlocks.map((item, i) => <React.Fragment key={i}>
                    <Col span={24}>
                        <Badge.Ribbon color={colorErrorBg}
                                      text={<Button onClick={() => removeBlock(i)} danger type={'text'} size={'small'}
                                                    icon={<CloseOutlined/>}/>}>
                            <Card size={'small'}>
                                <Row gutter={[8, 8]}>
                                    <ConditionBlock setCondition={setCondition(i)}
                                                    intents={data.intents}
                                                    entities={data.entities}
                                                    operatorComponent={operators[i - 1] && <Select
                                                        className="nodrag nopan"
                                                        value={operators[i - 1] && operators[i - 1]?.split('-')?.length > 1 ? operators[i - 1]?.split('-')[0] : ''}
                                                        options={optionsOperators}
                                                        // bordered={false}
                                                        onSelect={value => setConditionIndex(i - 1, value + "-")}
                                                        allowClear
                                                        placeholder={'Operator'}/>}
                                                    removeSelf={() => removeBlock(i)}
                                                    block={item}
                                                    options={options}
                                    />
                                </Row>
                            </Card>
                        </Badge.Ribbon>
                    </Col>
                </React.Fragment>)
            }
            {conditionBlocks?.length > 0 ? <>
                <Col span={12}>
                    <Button onClick={() => addCondition(AndOperator + '-')} type={'dashed'}
                            style={{width: '100%'}}>E</Button>
                </Col>
                <Col span={12}>
                    <Button onClick={() => addCondition(OrOperator + '-')} type={'dashed'}
                            style={{width: '100%'}}>OU</Button>
                </Col>
            </> : <Col span={24}>
                <Button onClick={() => addCondition('')} type={'dashed'} style={{width: '100%'}}>
                    Adicionar Condição</Button>
            </Col>}
        </Row>
    )
}

function ConditionBlock({options, block, setCondition, operatorComponent, intents, entities}) {
    const conditionFields = useMemo(() => block?.split(' ') ?? [], [block])
    const isMulti = useMemo(() => conditionFields?.length > 1 && conditionFields[1]?.length > 0, [conditionFields])
    const [removeHovered, setRemoveHovered] = useState(false);
    const [search, setSearch] = useState('');

    const opts = useMemo(() => {
        const value = '$' + search.replace("$", "").replaceAll(' ', '_');
        return [...options, {value: value, label: value}];
    }, [options, search])

    const setField = useCallback((index, value) => {
        conditionFields[index] = value;
        setCondition(conditionFields.join(' '));
    }, [conditionFields, setCondition]);

    const autoCompleteValue = useMemo(() => {
        if (conditionFields.length < 3)
            return '';

        let splitConds = conditionFields[2].split(" ");
        if (splitConds?.length < 1)
            return '';

        for (const k in splitConds) {
            const cond = splitConds[k];
            if (intents && intents[cond]) {
                splitConds[k] = "#" + intents[cond].title;
                continue
            }
            if (entities && entities[cond]) {
                splitConds[k] = "@" + entities[cond].title;
            }
        }

        return splitConds.join(" ");
    }, [intents, entities, conditionFields])

    const setIsMulti = useCallback((isMulti) => {
        if (isMulti)
            return setField(1, EqualOperator + '-');

        let newConds = [conditionFields[0]];
        setCondition(newConds.join(' '));
    }, [setField, conditionFields])

    const operator = useMemo(() => {
        if (conditionFields.length > 1 && conditionFields[1]?.split('-')?.length > 1)
            return conditionFields[1].split('-')[0]

        return '';
    }, [conditionFields])

    const {
        token: {colorErrorBg, paddingSM, borderRadiusSM},
    } = theme.useToken();

    return (
        <>
            <Col style={{alignSelf: 'flex-end'}} span={isMulti ? 8 : 18}>
                <Space style={{margin: 0, padding: 0, width: '100%'}} direction={'vertical'}>
                    {operatorComponent}
                    <Select placeholder={'Valor'} optionFilterProp="label" showSearch
                            className="nodrag nopan"
                            style={{marginBottom: isMulti ? paddingSM : 0}}
                            popupMatchSelectWidth={false}
                            value={conditionFields[0]}
                            onSelect={v => setField(0, v)}
                            searchValue={search}
                            onSearch={v => setSearch(v.replaceAll(' ', '_'))}
                            options={opts}/>
                </Space>
            </Col>
            {!isMulti && <Col span={4}>
                <Button type={'dashed'} style={{width: '100%'}} onClick={() => setIsMulti(true)}
                        icon={<PlusOutlined/>}/>
            </Col>}
            {isMulti && <Col span={16}>
                <div style={{
                    background: removeHovered && colorErrorBg,
                    padding: paddingSM,
                    transition: 'all var(--ant-motion-duration-mid) var(--ant-motion-ease-in-out)',
                    borderRadius: borderRadiusSM
                }}>
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Divider orientation={'right'} style={{marginBottom: 4, marginTop: 0}}>
                                <Button type={'text'} style={{fontSize: 10, margin: 0, padding: '0 8px'}}
                                        onClick={() => setIsMulti(false)}
                                        onMouseEnter={() => setRemoveHovered(true)}
                                        onMouseLeave={() => setRemoveHovered(false)}
                                        size={'small'}
                                        danger>
                                    Remover
                                </Button>
                            </Divider>
                        </Col>
                        <Col span={9}>
                            <Select placeholder={'='} suffixIcon={null} optionFilterProp="label"
                                    className="nodrag nopan"
                                    allowClear
                                    value={operator}
                                    onSelect={(value) => setField(1, value + '-')}
                                    onClear={() => setIsMulti(false)}
                                    options={optionsCmpOperators}/>
                        </Col>
                        <Col span={15}>
                            <AutoComplete filterOption={(searchInput, item) => isSimilar(item.label, searchInput)}
                                          value={autoCompleteValue}
                                          className="nodrag nopan"
                                          placeholder={'Valor'}
                                          onChange={v => setField(2, v)}
                                          optionFilterProp="label" showSearch options={options}/>
                        </Col>
                    </Row>
                </div>
            </Col>
            }
        </>
    )
}
