import {KeyIFrameLocalStorage} from "../components/context-action-input";

const defaultUrl = process.env.REACT_APP_SERVICE

export default async function Fetch(route, init = {}, url = defaultUrl) {
    if (isIframe()) return await FetchWithApiKey(route, init, url);

    const token = localStorage.getItem("token");

    init.headers = {...init.headers, "Authorization": `Bearer ${token}`};

    const request = fetch(url + route, init)
    // const timeout = setTimeout(() => request.abort(), 20000);
    let fail;
    const res = await request.catch(e => {
        fail = e;
        // clearTimeout(timeout);
    });

    if (fail) return Promise.reject(fail);

    // clearTimeout(timeout);

    if (res.status > 299) {
        let data = await res.json();
        // if (data?.message === 'jwt token invalid') {
        //     window.location = '/login';
        //     localStorage.removeItem('token')
        //     localStorage.removeItem('user')
        // }

        return Promise.reject({data, status: res.status})
    }

    return await res.json()
}

function isIframe() {
    return window.location.href.includes("/iframe/");
}

export async function FetchWithApiKey(route, init = {}, url = defaultUrl) {
    const token = localStorage.getItem(KeyIFrameLocalStorage)
    init.headers = {...init.headers, "Api-Key": `${token}`};

    const request = fetch(url + route, init);
    // const timeout = setTimeout(() => request.abort(), 10000);
    const res = await request;
    // clearTimeout(timeout);

    if (res.status === 401) {
        window.location = '/iframe/invalid';
        localStorage.removeItem(KeyIFrameLocalStorage)
    }

    return await res.json();
}

