import Fetch from "./index";

export const getRitaInstance = async ({assistantID}) => {
    if (!assistantID)
        return Promise.reject("failed to read id");

    return Fetch("/rita-instance/?assistantID=" + assistantID)
}

export const createRitaInstance = async (body) => {
    const data = new FormData();
    for (const key in body) {
        data.set(key, body[key]);
    }

    return Fetch(
        '/rita-instance/',
        {
            method: 'POST',
            body: data,
        },
    )
}

export const deleteRitaInstance = async (id) => {
    return Fetch(
        '/rita-instance/' + id,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
}

export const updateRitaInstance = async (id, body) => {
    delete body['createdAt']
    delete body['updatedAt']
    delete body['deletedAt']
    delete body['id']

    const data = new FormData();
    for (const key in body) {
        data.set(key, body[key]);
    }

    return Fetch(
        '/rita-instance/' + id,
        {
            method: 'PUT',
            body: data,
        },
    )
}
