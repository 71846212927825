import React from 'react';
import { Layout, Card, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

export default function InvalidApiKey() {
    return (
        <Layout style={{ minHeight: '100vh', background: '#f0f2f5' }}>
            <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ width: 400, textAlign: 'center' }}>
                    <Title level={2}>API Key Inválida</Title>
                    <p>Por favor, solicite um novo link com uma Api Key válida.</p>
                </Card>
            </Content>
        </Layout>
    );
}
