import {Button, Form, Input, InputNumber} from 'antd';
import {Switch} from "antd/lib";
import {SaveOutlined} from "@ant-design/icons";
import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getCompany, updateCompany} from "../../services/companies";
import {InputSearch} from "../../components/input-search";
import {getChatbotPlans} from "../../services/chatbot-plan";
import MessageApiContext from "../../context/message-api";
import {getAssistantPlans} from "../../services/assistant-plan";

export default function CompanyEdit() {
    const {id} = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const messageApi = useContext(MessageApiContext);

    useEffect(() => {
        if (!id)
            return;

        setLoading(true);
        getCompany(id).then(({data}) => {
            form.setFieldsValue(data)
            setLoading(false)
        }).catch((e) => {
            console.error(e)
            messageApi.error("Falha ao carregar informações do empresa")
        })
    }, [id, form])

    const save = useCallback((values) => {
        setSaving(true)
        updateCompany(id, values).then(() => {
            setSaving(false)
            navigate('..');
            messageApi.success('Empresa atualizada!')
        }).catch(e => {
            console.error(e)
            setSaving(false);
            messageApi.error('Falha ao atualizar')
        })
    }, [id])

    return (
        <>
            <Form
                name="basic"
                onFinish={save}
                style={{
                    maxWidth: 600,
                }}
                layout={'vertical'}
                disabled={loading}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Digite um nome para a empresa',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="CNPJ"
                    name="identity"
                    rules={[
                        {
                            message: 'CNPJ não pode estar vazio',
                            len: 14
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Dia de pagamento"
                    name="payDay"
                    rules={[
                        {
                            required: true,
                            message: 'Dia de pagamento não pode estar vazio',
                        }
                    ]}
                >
                    <InputNumber min={1} max={28} step={1}/>
                </Form.Item>

                <Form.Item
                    label="Plano de Chatbot"
                    name="chatbotPlanID"
                    rules={[
                        {
                            required: true,
                            message: 'Selecione um plano de chatbot',
                        },
                    ]}
                >
                    <InputSearch defaultValue={1} getCallback={getChatbotPlans}/>
                </Form.Item>
                <Form.Item
                    label="Plano de Assistente"
                    name="assistantPlanID"
                    rules={[
                        {
                            required: true,
                            message: 'Selecione um plano de assistente',
                        },
                    ]}
                >
                    <InputSearch defaultValue={1} getCallback={getAssistantPlans}/>
                </Form.Item>
                <Form.Item
                    label="Bloqueado"
                    name="isBlocked"
                    valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>


                <Form.Item>
                    <Button loading={saving} icon={<SaveOutlined/>} type="primary" htmlType="submit">
                        Salvar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
