import {
    Button,
    Card, Col,
    ColorPicker,
    Form,
    Input,
    Modal,
    Row,
    theme,
    Tooltip,
    Typography,
    Upload,
    Checkbox
} from "antd";
import {
    CloseOutlined,
    DownloadOutlined,
    Html5Outlined,
    LinkOutlined, LoadingOutlined, PlusOutlined, SettingOutlined,
} from "@ant-design/icons";
import React, {useCallback, useEffect, useState} from "react";
import {
    createRitaInstance,
    deleteRitaInstance,
    getRitaInstance,
    updateRitaInstance
} from "../../services/ritaInstances";

const ritaURL = process.env.REACT_APP_RITA
const bubbleScriptURL = process.env.REACT_APP_RITA_BUBBLE_SCRIPT

export function RitaIntegration({icon, label, description, assistantID, messageApi, generative}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const {
        token: {colorPrimary},
    } = theme.useToken();

    useEffect(() => {
        setLoading(true)
        getRitaInstance({assistantID}).then(({data}) => {
            if (data.length === 0)
                return

            setData(data[0])
        }).catch((error) => {
            console.log(error)
            messageApi.error("Erro ao buscar integração com site.");
        }).finally(() => {
            setLoading(false)
        });
    }, [assistantID, messageApi]);

    const install = useCallback(() => {
        setButtonLoading(true)
        createRitaInstance({
            assistantID: parseInt(assistantID),
            active: true,
            allowDocumentDisplay: true,
            allowTriggerDisplay: true
        }).then(({data}) => {
            setData(data);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao instalar integração com site.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [assistantID, messageApi]);

    const uninstall = useCallback(() => {
        setButtonLoading(true);
        deleteRitaInstance(data.id).then(() => {
            setData(undefined);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao remover integração com site.");
        }).finally(() => {
            setButtonLoading(false)
        });
    }, [data, messageApi]);

    const update = useCallback(async () => {
        setButtonLoading(true);
        updateRitaInstance(data.id, {...data}).then(({data: updatedData}) => {
            setData({...updatedData});
            setConfigOpen(false);
            messageApi.success("Atualizado com sucesso!");
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao atualizar.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [data, messageApi]);

    const copyToClipboard = async (text, setTooltipOpen) => {
        await navigator.clipboard.writeText(text)
            .then(() => {
                setTooltipOpen(true);
                setTimeout(() => setTooltipOpen(false), 2000);
            })
            .catch((err) => console.error('Falha ao copiar: ', err));
    };

    const textToCopy = (id, url, color) => {
        return `<script src="${bubbleScriptURL}" data-rita-id="${id}" rita-url="${url}" ${color ? `bubble-color='${color}'` : ''}></script>`;
    }

    const uploadButton = (
        <Button type={'text'} style={{border: 0, background: 'none'}}>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <Typography.Title level={5} style={{margin: 0, padding: 0}}>Logo</Typography.Title>
        </Button>
    );

    return (
        <>
            <Modal title="Configurar Chat Externo"
                   open={configOpen}
                   cancelText={'Cancelar'}
                   confirmLoading={buttonLoading}
                   okText={'Salvar'}
                   onOk={update}
                   onCancel={() => setConfigOpen(false)}>
                <br/>
                <Row gutter={[12, 12]}>
                    <Col span={6}>
                        <Upload
                            name="avatar"
                            listType="picture-circle"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            beforeUpload={() => false}
                            onChange={(e) => {
                                setData(old => ({...old, img: e.file}));
                            }}
                        >
                            {data?.avatar || data?.img ? (
                                <img
                                    src={data?.img ? (window.URL.createObjectURL(data?.img)) : data?.avatar + "?last=" + data.updatedAt}
                                    loading={"eager"}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        borderRadius: '100%'
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Col>
                    <Col span={18}>
                        <Form layout={'vertical'}>
                            <Form.Item label={'Nome'}>
                                <Input  value={data?.name}
                                        maxLength={20}
                                        onChange={({target: {value}}) => setData(old => ({...old, name: value}))}/>
                            </Form.Item>

                            <Form.Item label={'Mensagem Inicial'}>
                                <Input.TextArea value={data?.initialMessage}
                                                placeholder="Olá, sou um assistente virtual e estou pronto para lhe auxiliar"
                                                onChange={({target: {value}}) => setData(old => ({
                                                    ...old,
                                                    initialMessage: value
                                                }))}/>
                            </Form.Item>

                            <Form.Item label={'Cor'}>
                                <ColorPicker value={data?.primaryColor}
                                             onChange={(color) => setData(old => ({...old, primaryColor: color.toHex()}))}
                                             defaultValue={colorPrimary} showText/>
                            </Form.Item>

                            <Form.Item>
                                <Checkbox
                                    checked={data?.allowDocumentDisplay}
                                    onChange={({target: {checked}}) => setData(old => ({
                                        ...old,
                                        allowDocumentDisplay: checked
                                    }))}
                                >
                                    Mostrar Documentos
                                </Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Checkbox
                                    checked={data?.allowTriggerDisplay}
                                    onChange={({target: {checked}}) => setData(old => ({
                                        ...old,
                                        allowTriggerDisplay: checked
                                    }))}
                                >
                                    Mostrar Triggers
                                </Checkbox>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Card
                loading={loading}
                title={<Typography.Title level={5} ellipsis={{tooltip: label}}>{icon} {label}</Typography.Title>}
                extra={<Button type={data ? 'default' : 'primary'}
                               loading={loading || buttonLoading}
                               danger={!!data}
                               onClick={() => data ? uninstall() : install()}
                               icon={data ? <CloseOutlined/> : <DownloadOutlined/>}
                >{loading ? undefined : data ? 'Remover' : 'Instalar'}</Button>}
                actions={data ? [
                    <SettingOutlined onClick={() => setConfigOpen(true)}/>,
                    <Tooltip title="Copiado!" open={tooltipVisible}>
                        <Html5Outlined
                            onClick={() => copyToClipboard(textToCopy(data.uuid, ritaURL, data.primaryColor), setTooltipVisible)}
                        />
                    </Tooltip>,
                    <a href={generative ? `${ritaURL}/generative/${data.uuid}` : `${ritaURL}/generative/${data.uuid}`}
                       target={'_blank'}>
                        <LinkOutlined/>
                    </a>
                ] : undefined}>
                {description}
            </Card>
        </>
    )
}
