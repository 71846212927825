import {useParams} from "react-router-dom";
import {useEffect, useState, useRef, useCallback, useContext} from "react";
import {getSnapshotVersion, updateSnapshotVersion,} from "../../services/snapshot_version";
import {getSystemConditions} from "../../services/system";
import {filterData} from "../../utils";
import ChatBotDataContext from "../../context/chatbot-data";
import {ReactFlowProvider} from "reactflow";
import ActionsContext from "../../context/actions";
import {message} from "antd";
import FlowContext from "../../context/flow";
import ComponentDataContext from "../../context/component-data";
import { getComponent, updateComponent } from "../../services/components";
import { NodesProvider } from "../../context/nodesProvider";
import { EdgesProvider } from "../../context/edgesProvider";
import MessageApiContext from "../../context/message-api";

export default function Provider({children, isIfram}) {
    const {snapshot, id} = useParams();
    const [data, setData] = useState([]);
    const [componentData, setComponentData] = useState({});
    const [lastComponentDataUpdate, setLastComponentDataUpdate] = useState([]);
    const [groups, setGroups] = useState([])
    const [flow, setFlow] = useState([]);
    const [focusedNode, setFocusedNode] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isTraining, setIsTraining] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [lastDataUpdate, setLastDataUpdate] = useState([]);
    const [iframeEditorLock, setIframeEditorLock] = useState(false);
    const [isIframe, setIsIframe] = useState(isIfram);
    const [isNotSaved, setIsNotSaved] = useState(false);
    const [isNotSavedEntitiesIntents, setIsNotSavedEntitiesIntents] = useState(false);
    const [featureOpened, setFeatureOpened] = useState('');
    const messageApi = useContext(MessageApiContext);
    const groupButtonRef = useRef(null);
    const nodeRef = useRef(null)
    useEffect(() => {
        setIsIframe(isIfram)
    }, [isIfram]);

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (!data || data?.length < 1)
                return;
            setIsNotSaved(JSON.stringify(lastComponentDataUpdate) !== JSON.stringify(componentData))

            const intentEntitiesLastUpdate = {entities: lastComponentDataUpdate.entities, intents: lastComponentDataUpdate.intents}
            const intentEntitiesNow = {entities: componentData.entities, intents: componentData.intents}
            setIsNotSavedEntitiesIntents(JSON.stringify(intentEntitiesLastUpdate) !== JSON.stringify(intentEntitiesNow))
        }, 100)

        return () => {
            clearTimeout(timeout)
        }
    }, [componentData, lastComponentDataUpdate])

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (!data || data?.length < 1)
                return;
            setIsNotSaved(JSON.stringify(lastDataUpdate) !== JSON.stringify(data))

            const intentEntitiesLastUpdate = {entities: lastDataUpdate.entities, intents: lastDataUpdate.intents}
            const intentEntitiesNow = {entities: data.entities, intents: data.intents}
            setIsNotSavedEntitiesIntents(JSON.stringify(intentEntitiesLastUpdate) !== JSON.stringify(intentEntitiesNow))
        }, 100)

        return () => {
            clearTimeout(timeout)
        }
    }, [data, lastDataUpdate])

    function preventClose(ev) {
        if (JSON.stringify(lastDataUpdate) !== JSON.stringify(filterData(data))) {
            ev.preventDefault();
            return ev.returnValue = 'Voce tem alterações não salvas, tem certeza que deseja fechar?';
        }
    }

    useEffect(() => {
        window.addEventListener("beforeunload", preventClose);
        return () => window.removeEventListener('beforeunload', preventClose);
    }, [lastDataUpdate, data, componentData])


    useEffect(() => {
        if (!snapshot && !id)
            return;
        (async () => {
            let {data} = id && !snapshot ? await getComponent(id).catch(console.error) : await getSnapshotVersion(snapshot).catch(console.error);
            let {data: system} = await getSystemConditions().catch(console.error);
            setData({...data, system});
            setLastDataUpdate(JSON.parse(JSON.stringify({...data, system})));
            setLoading(false);
        })().catch(() => messageApi.error("Falha ao carregar versão"))
    }, [snapshot, id])



    const updateCallback = useCallback(async () => {
        if (data.edges) {
            for (const source in data.edges) {
            for (const edge in data.edges[source]) {
                if (data.edges[source][edge].originalTarget) {
                data.edges[source][edge].target = data.edges[source][edge].originalTarget;
                }
                if (data.edges[source][edge].originalSource) {
                data.edges[source][edge].source = data.edges[source][edge].originalSource;
                }
            }
            }
        }

        let newData = id && !snapshot ? data : filterData(data)
        setIsUpdating(true);
        let updated;

        if (!snapshot) {
            updated = await updateComponent(newData.id, newData)
                .finally(() => setIsUpdating(false));
        } else {
            updated = await updateSnapshotVersion(newData.id, newData)
                .finally(() => setIsUpdating(false));
        }
        
        let {data: system} = await getSystemConditions().catch(() => messageApi.error("Falha ao carregar variaveis de systema, recarregue a página!"));
        if (updated && updated?.data) {
            setLastDataUpdate({...updated.data, system});
            setData({...updated.data, system})
            return;
        }

        setLastDataUpdate({...newData, system});
        setData({...newData, system})
    }, [data])

    const update = useCallback(async () => {
        updateCallback()
            .then(() => messageApi.success('Versão salva'))
            .catch(() => messageApi.error("Falha ao salvar, tente novamente"))
    }, [data]);

    return (
        <ChatBotDataContext.Provider value={[data, setData]}>
            <EdgesProvider>
            <NodesProvider>

            <ComponentDataContext.Provider value={{componentData, setComponentData}}>

            <ReactFlowProvider>
                <ActionsContext.Provider value={{
                    train: [isTraining, setIsTraining],
                    update,

                    isUpdating,
                    iframeLockEditor: [iframeEditorLock, setIframeEditorLock],
                    iframe: [isIframe, setIsIframe],
                    notSaved: [isNotSaved, setIsNotSaved],
                    focusedNode: [focusedNode, setFocusedNode],
                    notSavedEntitiesIntents: [isNotSavedEntitiesIntents, setIsNotSavedEntitiesIntents],
                    featureOpened: [featureOpened, setFeatureOpened],
                    loading: [loading, setLoading],
                    groups: [groups, setGroups],
                    groupButtonRef: groupButtonRef,
                    nodeRef: nodeRef,
                }}>
                    <FlowContext.Provider value={{flow, setFlow}}>
                        {children}
                    </FlowContext.Provider>
                </ActionsContext.Provider>
            </ReactFlowProvider>
            </ComponentDataContext.Provider>
            </NodesProvider>
            </EdgesProvider>
        </ChatBotDataContext.Provider>
    )
}

export function useComponentData() {
    const context = useContext(ComponentDataContext)
    const {componentData, setComponentData} = context;
    return {componentData, setComponentData}
}
