import {Button, Col, Form, Input, Popconfirm, Row, Select, Space, Table, theme, Typography} from "antd";
import {
    ArrowLeftOutlined,
    BulbOutlined, DeleteOutlined,
    MessageOutlined, MinusCircleOutlined, PlusOutlined,
    QuestionCircleOutlined, SaveOutlined
} from "@ant-design/icons";
import {Container, CustomCard} from "./styles";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {JumpAndProcess, JumpTo, MissingAnythingElse, Response, SetContext, StartConversation} from "../../constants";
import ChatBotDataContext from "../../context/chatbot-data";
import {newObjectID} from "../../utils";

const triggerTranslations = {
    [MissingAnythingElse]: "Falta de Anything Else",
    [StartConversation]: "Início da conversa"
}

export default function Triggers() {
    const [triggerData, setTriggerData] = useState({});
    const [step, setStep] = useState(1);
    const [data, setData] = useContext(ChatBotDataContext);
    const triggers = useMemo(() => data?.triggers, [data?.triggers])

    useEffect(() => {
        if (!triggers || triggers?.length <= 0)
            setStep(2);
    }, [triggers, setStep])

    const selectTrigger = useCallback((triggerType) => {
        setTriggerData((old) => ({...old, type: triggerType}));
        setStep(3);
    }, [setTriggerData, setStep]);

    const back = useCallback(() => {
        setStep(old => --old);
    }, [setTriggerData, setStep]);

    const edit = useCallback((record) => {
        setStep(3);
        setTriggerData(record);
    }, [setTriggerData, setStep]);

    const add = useCallback(() => {
        setStep(2);
        setTriggerData({});
    }, [setTriggerData, setStep]);

    const remove = useCallback((id) => {
        setData(old => {
            const triggers = old.triggers.filter((item) => item.id !== id);
            return {...old, triggers};
        })
    }, [data, setData])

    return (<>
            {step === 1 && <ListTriggers triggers={triggers} edit={edit} add={add} remove={remove}/>}
            {step === 2 && <SelectTrigger triggerData={triggerData} setTrigger={selectTrigger} back={back}
                                          hasTriggers={triggers && triggers?.length > 0}/>}
            {step > 2 && <SelectActions setStep={setStep} triggerData={triggerData} back={back}/>}
        </>
    );
}

function ListTriggers({add, edit, triggers, remove}) {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setTotal((triggers ?? {})?.length)
    }, [triggers])

    return (
        <Container left>
            <Row justify={'space-between'}>
                <Typography.Title level={4}> <BulbOutlined/> Gatilhos</Typography.Title>
                <Button onClick={add} type={'primary'} icon={<PlusOutlined/>}>Novo</Button>
            </Row>
            <Table rowClassName={'cursor-pointer'}
                   pagination={{
                       position: "bottom",
                       align: "end",
                       onChange: (page, perPage) => {
                           setPage(page);
                           setPerPage(perPage);
                       },
                       total,
                       pageSize: perPage,
                       current: page
                   }}
                   dataSource={triggers?.slice((page - 1) * perPage, perPage * perPage)}
                   onRow={(record) => ({
                       onClick: ({target}) => {
                           if (target.classList.length > 0)
                               edit(record);
                       }
                   })}>
                <Table.Column
                    title={'Tipo'}
                    dataIndex={'type'}
                    key={'type'}
                    render={(item) => triggerTranslations[item]}
                />
                <Table.Column
                    title={'Ações'}
                    dataIndex={'actions'}
                    key={'actions'}
                    render={items => Object.keys(items ?? {}).length}
                />
                <Table.Column
                    dataIndex={'id'}
                    key={'id'}
                    render={(id) => <Popconfirm onConfirm={() => remove(id)}
                                        title={'Tem certeza?'}
                                        description="Essa ação vai deletar o gatilho por inteiro!">
                        <Button type={'text'} danger icon={<DeleteOutlined/>}/>
                    </Popconfirm>}
                />
            </Table>
        </Container>
    )
}

function SelectActions({back, triggerData, setStep}) {
    const [form] = Form.useForm();
    const [, setData] = useContext(ChatBotDataContext);

    const actions = Form.useWatch('actions', form);
    useEffect(() => {
        form.setFieldsValue(triggerData)
    }, [triggerData, form])

    const save = useCallback((values) => {
        setData(old => {
            let triggers = old?.triggers || [];
            if (!values?.id) {
                values.id = newObjectID();
                setStep(1);
                return {...old, triggers: [...triggers, values]}
            }

            for (const i in triggers) {
                const id = triggers[i].id
                if (id === values?.id) {
                    triggers[i] = values;
                    break
                }
            }

            setStep(1);
            return {...old, triggers}
        })

    }, [setStep, setData])

    return (
        <Container right>
            <Row justify={'space-between'}>
                <Typography.Title level={4}> <BulbOutlined/> Gatilhos</Typography.Title>
                <Button onClick={back} icon={<ArrowLeftOutlined/>}>Voltar</Button>
            </Row>
            <Space direction={'vertical'}>
                <Typography.Title level={4}>
                    Ações
                </Typography.Title>
                <Typography.Paragraph>
                    Escolha uma ação a ser tomada quando o gatilho for acionado.
                </Typography.Paragraph>
            </Space>
            <Form
                name="basic"
                style={{
                    maxWidth: '100%',
                }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={save}
            >
                <Form.Item name={'id'} style={{display: 'none'}}>
                    <Input/>
                </Form.Item>
                <Form.Item required name={'type'} label={'Gatilho'}>
                    <Select placeholder={'Selecione o gatilho'}>
                        <Select.Option
                            value={StartConversation}>{triggerTranslations[StartConversation]}</Select.Option>
                        <Select.Option
                            value={MissingAnythingElse}>{triggerTranslations[MissingAnythingElse]}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.List name="actions"
                           rules={[
                               {
                                   validator: async (_, actions) => {
                                       if (!actions || actions.length < 1) {
                                           return Promise.reject(new Error('É necessário 1 ação no mínimo'));
                                       }
                                   },
                               },
                           ]}
                >
                    {(fields, {add, remove}, {errors}) => (<>
                        {fields?.map((field, index) => (<Form.Item
                            key={field.key}
                            label={index + 1 + ' - Ação'}
                            required
                        >
                            <Row justify={'space-between'} align={'center'}>
                                <Col span={22}>
                                    <Space style={{width: '100%'}} direction={'vertical'}>
                                        <Form.Item {...field}
                                                   name={[field.name, 'type']}
                                                   validateTrigger={['onChange', 'onBlur']}
                                                   noStyle>
                                            <Select style={{width: 'auto'}} popupMatchSelectWidth={false}>
                                                <Select.Option value={Response}>Responder</Select.Option>
                                                <Select.Option value={JumpTo}>Pular</Select.Option>
                                                <Select.Option value={SetContext}>Definir Contexto</Select.Option>
                                                <Select.Option value={JumpAndProcess}>Pular e Processar</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <ActionData
                                            responseData={actions?.length >= index && actions[index]}
                                            style={{width: '100%'}}
                                            actions={actions}
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            noStyle
                                        >
                                        </ActionData>
                                    </Space>
                                </Col>
                                <Col span={1}>
                                    {fields.length > 1 ? (<MinusCircleOutlined
                                        style={{marginTop: '.5rem'}}
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />) : null}
                                </Col>
                            </Row>
                        </Form.Item>))}
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                icon={<PlusOutlined/>}
                                type="dashed"
                                onClick={() => add({type: Response, data: ""})}>Adicionar ação</Button>
                        </Form.Item>
                        <Form.Item style={{margin: 0}}>
                            <Form.ErrorList errors={errors}/>
                        </Form.Item>
                    </>)}
                </Form.List>
                <Row justify={'end'}>
                    <Form.Item>
                        <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Container>
    )
}

function SelectTrigger({setTrigger, back, triggerData, hasTriggers}) {
    const {
        token: {colorPrimary},
    } = theme.useToken();

    return (
        <Container right>
            <Row justify={'space-between'}>
                <Typography.Title level={4}> <BulbOutlined/> Gatilhos</Typography.Title>
                {hasTriggers && <Button onClick={back} icon={<ArrowLeftOutlined/>}>Voltar</Button>}
            </Row>
            <Typography.Paragraph>
                Com essa funcionalidade você pode executar ações que serão acionadas a qualquer momento no fluxo.
            </Typography.Paragraph>
            <br/>
            <Space direction={'vertical'} style={{width: '100%'}}>
                <CustomCard hoverColor={colorPrimary} title={triggerTranslations[StartConversation]}
                            extra={<MessageOutlined/>}
                            selected={triggerData?.type === StartConversation}
                            onClick={() => setTrigger(StartConversation)}
                            style={{width: '100%'}}>
                    Ao iniciar a conversa
                </CustomCard>
                <CustomCard hoverColor={colorPrimary} title={triggerTranslations[MissingAnythingElse]}
                            extra={<QuestionCircleOutlined/>}
                            selected={triggerData?.type === MissingAnythingElse}
                            onClick={() => setTrigger(MissingAnythingElse)}
                            style={{width: '100%'}}>
                    Quando não entender nenhum nó
                </CustomCard>
            </Space>
        </Container>
    )
}

function ActionData({responseData, ...props}) {
    const [data] = useContext(ChatBotDataContext);

    if (responseData?.type === Response) {
        return (
            <Form.Item {...props}
                       rules={[
                           {
                               required: true,
                               whitespace: true,
                               message: "Adicione uma mensagem ou delete esse campo",
                           },
                       ]}
                       name={[props.name, 'data']}>
                <Input.TextArea placeholder={'Digite uma mensagem'} autoSize/>
            </Form.Item>
        )
    }

    if (responseData?.type === SetContext) {
        return (
            <Space direction={'horizontal'}>
                <Form.Item {...props}
                           rules={[
                               {
                                   required: true,
                                   whitespace: true,
                                   message: "Adicione a chave ou delete esse campo",
                               },
                           ]}
                           name={[props.name, 'data', 'key']}>
                    <Input placeholder={'Chave'}/>
                </Form.Item>

                <Form.Item {...props}
                           rules={[
                               {
                                   required: true,
                                   whitespace: true,
                                   message: "Adicione o valor ou delete esse campo",
                               },
                           ]}
                           name={[props.name, 'data', 'value']}>
                    <Input placeholder={'Valor (sem $)'}/>
                </Form.Item>
            </Space>
        )
    }

    return (
        <>
            <Form.Item {...props}
                       rules={[
                           {
                               required: true,
                               whitespace: true,
                               message: "Adicione uma mensagem ou delete esse campo",
                           },
                       ]}
                       name={[props.name, 'data']}>
                <Select placeholder={'Selecionar nó'}>
                    <Select.Option value={'000000000000000000000000'}>Nó Raiz</Select.Option>
                    {Object.values(data?.nodes ?? {}).map(item =>
                        <Select.Option value={item?.id}>{item?.title}</Select.Option>
                    )}
                </Select>
            </Form.Item>
        </>
    )
}
