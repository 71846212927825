import {Container} from "./styles";
import {Button, Result, Spin, theme, Typography} from "antd";
import {ReloadOutlined} from "@ant-design/icons";


export default function Loading({title, isFail, tryAgain, height}) {
    const {
        token: {colorText, colorBgContainer},
    } = theme.useToken();


    return (
        <Container height={height} color={colorBgContainer}>
            {!isFail && <Typography.Text style={{fontSize: '1.4em', marginBottom: '1rem', color: colorText}}
                              level={5}>{title}</Typography.Text>}
            {
                isFail ?
                    <Result
                        status="500"
                        title="500"
                        subTitle="Desculpe, algo ocorreu mal :("
                        extra={<Button icon={<ReloadOutlined/>} onClick={tryAgain} type="primary">Tentar novamente</Button>}
                    /> :
                    <Spin size={'large'} style={{color: colorText, fontSize: '24px'}}/>

            }
        </Container>
    );
}
