import React, { createContext, useContext, useState } from 'react';
import { useEdgesState } from 'reactflow';

// Create the context
const EdgesContext = createContext();

// Create a provider component
export const EdgesProvider = ({ children }) => {
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  return (
    <EdgesContext.Provider value={{edges, setEdges, onEdgesChange}}>
      {children}
    </EdgesContext.Provider>
  );
};

export const useEdgesContext = () => {
  return useContext(EdgesContext);
};