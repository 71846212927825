import { ArrowRightOutlined, CheckCircleOutlined, CheckOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Image, Input, Result, Row, Space, Steps, message, theme } from 'antd';
import React, {useContext, useState} from 'react';
import logo from '../../assets/logo-background.svg'
import { forgotPassword, resetPassword } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import MessageApiContext from "../../context/message-api";
// import { Container } from './styles';

function ForgotPassword() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [step, setStep] = useState(0)
    const messageApi = useContext(MessageApiContext);
    const [email, setEmail] = useState('')
    const {
        token: {borderRadius, colorBgContainer, colorBorderBg, colorBgLayout, colorBgElevated},
    } = theme.useToken();

    const sendEmail = ({email})=> {
        setIsLoading(true)
        forgotPassword({email})
        .then(()=> {
            messageApi.success('Email enviado com sucesso!')
            setStep(step+1)
            setIsLoading(false)
            setEmail(email)
        })
        .catch(e => {
            console.log(e)
            messageApi.error('Não foi possível enviar email')
            setIsLoading(false)
        })

    }
    const changePass = ({token, newPassword, confirmPassword})=> {
        setIsLoading(true)
        if(newPassword != confirmPassword) {
            setIsLoading(false)
            return messageApi.error('As senhas devem ser iguais')}
        resetPassword({email, token, newPassword})
        .then((r)=> {
            messageApi.success('Senha alterada com sucesso!')
            setStep(step+1)
            setIsLoading(false)
        })
        .catch(e => {
            console.log(e)
            messageApi.error('Não foi possível alterar a senha, verifique se seu token é válido!')
            setIsLoading(false)
        })

    }
    const form = <Form
    name="login"
    onFinish={sendEmail}
    style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%', minWidth: '280px'}}
>
    <Flex justify='center' align='center' style={{flex:1, width: '100%'}}>
    <Form.Item
    style={{ width: '100%'}}
        name="email"
        rules={[{required: true, type: 'email', message: 'Por favor, insira um Email válido!'}]}
    >
        <Input size={'large'} prefix={<UserOutlined/>} placeholder="Email"/>
    </Form.Item>
    </Flex>
    <Form.Item>
        <Row justify={'end'}>
            <Button loading={isLoading} type="primary" htmlType="submit"
                    style={{width: '100%'}}
                    size={'large'}
                    className="login-form-button">
                Enviar
                <ArrowRightOutlined/>
            </Button>
        </Row>
    </Form.Item>
</Form>

const passwordForm = <Form
    name="changePassword"
    onFinish={changePass}
    style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%', minWidth: '350px'}}
>
    <Flex vertical justify='center' align='center' style={{flex:1, width: '100%'}}>
    <Form.Item
     style={{ width: '100%'}}
        name="token"
        rules={[{required: true, message: 'Token inválido!'}]}
    >
        <Input size={'large'} prefix={<UserOutlined/>} placeholder="Token"/>
    </Form.Item>
    <Form.Item
     style={{ width: '100%'}}
        name="newPassword"
        rules={[{required: true, message: 'Por favor, insira a Senha!'}]}
    >
        <Input.Password
            size={'large'}
            prefix={<LockOutlined />}
            placeholder="Senha"
        />
    </Form.Item>
    <Form.Item
     style={{ width: '100%'}}
        name="confirmPassword"
        rules={[{required: true, message: 'Por favor, insira a Senha!'}]}
    >
        <Input.Password
            size={'large'}
            prefix={<LockOutlined />}
            placeholder="Confirme a senha"
        />
    </Form.Item>
    </Flex>
    <Form.Item>
        <Row justify={'end'}>
            <Button loading={isLoading} type="primary" htmlType="submit"
                    style={{width: '100%'}}
                    size={'large'}
                    className="login-form-button">
                Enviar
                <ArrowRightOutlined/>
            </Button>
        </Row>
    </Form.Item>
</Form>
const finished = <Flex
align='center'
justify='center'
style={{flex: 1, width: '100%'}}>
     <Result
    status="success"
    title="Senha alterada com sucesso!"
    extra={[
      <Button type="primary" onClick={()=> {navigate('/login')}}>
        Voltar ao Login
      </Button>
    ]}
  />
</Flex>
    const getStepContent = () => {
        switch(step){
            case 0:
            return form;
            case 1:
            return passwordForm
            case 2:
            return finished
        }
    }
    const stepItems = [
        {
          title: 'Email',
        },
        {
          title: 'Mudar Senha',
        },
        {
          title: 'Finalizado',
        },
      ]
  return (<>


    <Flex vertical gap={50} justify='center' align='center' style={{width: '100vw', height: '100vh', boxSizing: 'border-box', backgroundColor: colorBgContainer, padding: '2rem 25%'}}>
        <Image src={logo} />
        <Flex vertical  align='center' style={{backgroundColor: colorBgElevated, width: '100%', minWidth: '300px', minHeight: '80%', boxSizing: 'border-box', borderRadius: borderRadius, border: `1px solid ${colorBorderBg}`, padding: '50px 0px'}}>
        <Steps
        progressDot
    current={step}
    items={stepItems}
    style={{width: '80%'}}
  />
                  {getStepContent()}
        </Flex>
    </Flex>
    </>
  );
}

export default ForgotPassword;
